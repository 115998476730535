@import "../../../../vars";

.send-idea-form {
  .cr {
    &-checkbox {
      &__input {
        flex-shrink: 0;
      }
    }
  }

  &__hint {
    @include hint-text;
    margin: 8px 12px 0;
    word-break: break-word;
  }

  &__paragraph {
    margin-top: 5px;
    font: normal normal normal 16px/28px var(--font-family-primary);
    letter-spacing: 0.44px;
    color: var(--base-text);
  }

  .cr-file-selector {
    &__head {
      &-button {
        @include tablet() {
          font: normal normal bold 11px/14px var(--font-family-primary);
          letter-spacing: 0.15px;
        }
      }
    }
  }
}

.send-idea-modal {
  .submit-form__title  {
    font: normal normal bold 30px/37px var(--font-family-primary);
    letter-spacing: 0.12px;
  }
}
