.leisure-landing-root {
  --full-width: 15360px;
  --full-height: 968px;
  --bg-scale: 1;
  --transition-duration-landing: 0s;
  --transition-duration-train: 0s;

  --bright-dusk: #ededfa;
  --loden-purple: #493771;
  --sugar-plum: #944F6F;
  --adventure-seas: #3361AD;
  --peacock-silk: #71A894;
  --pink-shade: #CF7986;

  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
