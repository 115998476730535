@import "../../../../../../vars";

.new-year-2023-puzzle {
  width: fit-content;
  touch-action: none;
  padding: 0 39px;
  &__wrapper {
    position: relative;
    width: 344px;
    height: 338px;
    touch-action: none;

    @include medium-tablet() {
      width: 293px;
      height: 288px;
    }
  }

  &__timer {
    @include medium-tablet() {
      position: absolute;
      left: 20px;
      top: 0;
    }
  }

  &__puzzleGrid {
    display: grid;
    height: 100%;
    margin-top: -1px;
    margin-left: -1px;
    &-item {
      border: 1px solid #cecece;
      margin-top: 1px;
      margin-left: 1px;
    }
  }
  &__piece {
    position: absolute;
    border: 1px solid #cecece;
    cursor: pointer;
    z-index: 1;
    user-select: none;

    &--solved {
      border-color: #000;
      z-index: 0;
    }
    &--dragging {
      z-index: 5;
      box-shadow: 1px 1px 5px 0px #00000091;
    }
  }
}
