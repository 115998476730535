@import "../../../../../../vars";

.top-comments {
  margin-top: 44px;
  .cr-tooltip .__react_component_tooltip {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }

  .swiper-slide {
    height: max-content;
  }

  .slider-arrows {
    @include wide-tablet {
      margin-top: 4px;
    }
    @include phone {
      margin-top: 0;
    }
  }

  .common-slider {
    height: auto !important;
  }

  .swiper-container {
    height: auto !important;
  }

  .swiper-wrapper {
    height: auto !important;
  }
}
