@import "../../../vars";

.wrapper {
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - #{$containerXPadding} * 2);
  background-color: #f5f6f7;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  box-shadow: 0px 8px 20px #0000001a;
  max-width: $containerMaxWidth - $containerXPadding * 2;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  border-radius: 24px;

  @include tablet() {
    align-items: flex-start;
    gap: 24px;
    bottom: 83px;
    padding: 24px;
  }

  @include phone() {
    bottom: 20px;
    padding: 20px;
    flex-direction: column;
    gap: 20px;
    width: calc(100% - #{$containerXPadding-phone} * 2);
  }
}

.text {
  letter-spacing: 0.13px;
}

.button {
  border-radius: 16px;

  @include tablet() {
    padding: 10px 15px;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    border-radius: 8px;
    letter-spacing: 0;
  }

  @include phone() {
    width: 100%;
    font-size: 14px;
    line-height: 17px;
  }
}
