.leisure-landing-character {
  $root: &;

  transition: all var(--transition-duration-landing) linear;

  #{$root}__object {
    cursor: pointer;
  }

  &_left {
    #{$root}__object {
      transform: rotateY(180deg);
    }
  }
}
