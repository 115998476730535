.news-card {
  box-shadow: 0px 2px 10px #0000001a;
  --tags-card-color: var(--base-purple);
}

.play-icon {
  height: 72px;
  width: 72px;
  display: block;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../../../../../src/assets/video-play-icon.svg") 0 0 / contain
    no-repeat;
}

.play-button {
  display: none !important;
}

.video-container {
  width: 100%;
  height: 100%;
  position: relative;

  @media (hover: hover) {
    &:hover {
      .video-poster {
        display: none;
      }
    }
  }

  //   &:focus-within {
  //     .video-poster {
  //       display: none;
  //     }
  //   }
}

.video-container > :first-child {
  height: 100% !important;
  padding-top: 0 !important;
  background-color: transparent !important;
}

.video-control {
  display: none !important;
}

.video-poster {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &--touched {
    display: none;
  }
}
