@import "../../../../vars";

.photo-card {
  width: 100%;
  padding-top: 100%;
  cursor: pointer;
  border-radius: var(--round-corners);
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 0px 4px #0000001f;
  position: relative;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 100%;

    > img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;

    @include tablet {
      padding: 12px;
    }

    &-heading {
      font: normal normal bold 20px/28px var(--font-family-primary);
      color: #8a8a8a;

      @include tablet {
        font: normal normal bold 18px/24px var(--font-family-primary);
        letter-spacing: 0.15px;
      }

      @include phone {
        font: normal normal bold 16px/24px var(--font-family-primary);
        letter-spacing: 0.13px;
      }
    }

    &-description {
      margin-top: 8px;
      word-break: break-word;
      font: normal normal normal 20px/24px var(--font-family-primary);
      color: var(--base-text);

      @include tablet {
        font: normal normal normal 18px/24px var(--font-family-primary);
        letter-spacing: 0.15px;
      }

      @include phone {
        font: normal normal normal 16px/24px var(--font-family-primary);
        letter-spacing: 0.13px;
      }
    }
  }
}

.photo-card-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  max-width: 510px;
  width: 100%;
  box-shadow: 0 4px 40px 10px rgba(51, 51, 51, 0.3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: white;
  padding: 30px;

  &__close {
    position: absolute;
    cursor: pointer;
    top: 3px;
    right: 3px;
    color: #999;

    &:hover {
      color: #bbb;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 25px;
    color: var(--base-text);
  }
}
