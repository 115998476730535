@import "../../../../../vars";
@import "../../mixins.scss";

.discussion-landing-experts {
  $root: &;

  &__slider {
    position: relative;
    padding: 16px 40px;
    background: #eefbff;
    border-radius: 20px;
    @include wide-tablet {
      padding: 16px;
    }

    &__wrapper {
      min-height: 168px;
      position: relative;
      margin-top: 23px;
      @include wide-tablet {
        min-height: 120px;
        margin-top: 20px;
      }
      @include phone {
        min-height: 146px;
        margin-top: 16px;
      }
    }

    &__inner {
      position: relative;
    }
  }

  &__tip {
    position: absolute;
    background-color: #fff;
    padding: 24px;
    width: 440px;
    z-index: 1;
    box-shadow: 0px 8px 20px #0000001a;
    border-radius: 4px;
    bottom: calc(100% + 8px);
    @include wide-tablet {
      width: 396px;
    }
  }

  &,
  &__info {
    .swiper-slide {
      height: auto;
      > div {
        height: 100%;
      }
    }

    .user-avatar {
      border: 4px solid #ff9425;
      background: none;
      &::before,
      &::after {
        content: none;
      }

      &__blocked {
        background-color: #b3b3b3;
        .promo-icon {
          display: none;
        }
      }

      &,
      &__container {
        max-width: 136px;
        max-height: 136px;
        width: 100%;
        height: 100%;
        @include phone {
          max-width: 114px;
          max-height: 114px;
        }
      }
      &__container {
        cursor: pointer;
        margin: 0 auto;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    &__description {
      margin-bottom: 8px;
      font: normal normal normal 16px/19px Montserrat;
      color: var(--base-text);
    }

    .user-avatar__container {
      margin-bottom: 20px;
    }

    .cr-button {
      margin-top: 20px;
      font: normal normal bold 14px/19px PT Sans;
      letter-spacing: 1.35px;
      color: #65aae0;
      text-transform: uppercase;
      padding: 10px 0;
    }
  }

  @include phone {
    &__modal {
      padding-top: 0 !important;
      #{$root}__info {
        padding: 20px 0;
      }
    }
  }

  @include phone-except {
    @include sliderArrowsSide;
  }
}
