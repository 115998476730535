.leisure-landing-screen-stub {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: #8286b4 url(../../../../../assets/leisure-landing/bg-stub.png) 0 bottom/cover no-repeat;
  overflow: hidden;

  &__modal {
    width: 420px;
    @media (orientation: portrait) {
      width: 365px;
    }

    max-width: calc(100% - 64px);
    text-align: center;

    &__btn {
      margin-top: 30px;
    }
  }
}
