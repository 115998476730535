.info-modal {
  width: 100%;
  height: 100%;
  max-width: none;

  >div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__text {
    font: normal normal normal 18px/26px PT Sans;
    text-align: center;
    max-width: 600px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
}

.info-modal-overlay {
  padding: 0;
  background: none;

  .info-modal {
    background: rgba(255, 255, 255, 0.98);
  }
}