.wrapper {
  padding-right: 4px;
  * {
    font: var(--text-body4);
    letter-spacing: 0px;
    color: var(--base-text-secondary);
  }
}

.label {
  margin-bottom: 4px;
  display: block;
}

.email {
  color: var(--base-text) !important;
  font: var(--text-body3);
  text-decoration: none !important;
  transition: color 0.1s ease-in-out;
  margin-bottom: 16px;
  display: inline-block;

  &:hover {
    color: var(--base-blue) !important;
  }
}

.social {
  display: flex;
  align-items: center;
  gap: 12px;
}
