.new-year-2023-header-burger {
  $root: &;
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
  outline: none;
  background-color: transparent;
  border: none;
  z-index: 6;

  &__burgerLine,
  &__burgerLine::after,
  &__burgerLine::before {
    width: 20px;
    height: 2px;
    background-color: var(--header-button-bg);
    border-radius: 10px;
  }

  &__burgerLine {
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      transition: transform 0.5s;
    }

    &:before {
      transform: translateY(-6px);
    }

    &:after {
      transform: translateY(6px);
    }
  }

  &--active {
    #{$root} {
      &__burgerLine {
        background-color: var(--enokitake-mushroom);
        height: 0;
        &:before,
        &:after {
          background-color: var(--enokitake-mushroom);
        }
        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
