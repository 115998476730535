@import "../../../../../../vars";

.crowd-birthday-10-image-modal {
  padding: 32px 40px;

  @include wide-tablet {
    padding: 28px 29px;
    border-radius: 14px;
    max-width: none;
  }

  @include medium-tablet {
    padding: 16px;
  }

  .modal__close-btn {
    top: 16px;
    right: 16px;

    @include wide-tablet {
      top: 8px;
      right: 8px;

      .ic {
        font-size: 14px;
      }
    }

    @include medium-tablet {
      top: 5px;
      right: 5px;
    }
  }

  &__img {
    width: 680px;
    height: 680px;
    border-radius: 16px;
    object-fit: cover;
    object-position: center;

    @include wide-tablet {
      width: 474px;
      height: 464px;
      border-radius: 6px;
    }

    @include medium-tablet {
      width: 268px;
      height: 262px;
    }
  }
}
