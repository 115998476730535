@import "../../../../../../vars";

.instruction {
  padding-bottom: 0;

  @include wide-tablet() {
    padding: 16px 13px 0 13px;
    max-width: 429px;
  }

  @include phone() {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0 12px;
  }

  &__scroll {
    .ScrollbarsCustom-TrackY {
      @include wide-tablet() {
        right: -6px;
      }
    }
  }

  .first {
    .instruction__scroll {
      height: 235px;

      @include wide-tablet() {
        height: 140px;
      }
    }
  }

  .second {
    .instruction__scroll {
      height: 201px;

      @include wide-tablet() {
        height: 120px;
      }
    }
  }

  .third {
    .instruction__scroll {
      height: 253px;

      @include wide-tablet() {
        height: 149px;
      }
    }
  }

  .modal__mobile-header {
    display: none !important;
  }

  &__overlay {
    top: 72px;
    overflow: hidden;

    @include wide-tablet() {
      padding-left: 0;
      padding-right: 0;
    }

    @include medium-tablet {
      top: 43px;
      padding: 0 !important;
    }
  }

  &--right {
    margin: 16px 0 0 auto;

    @include wide-tablet() {
      margin-top: 8px;
    }

    @include medium-tablet() {
      margin-right: 8px;
    }
  }

  &--left {
    margin: 16px auto 0 0;

    @include wide-tablet() {
      margin-top: 8px;
    }

    @include medium-tablet() {
      margin-left: 8px;
    }
  }

  &--center {
    margin: 16px auto;

    @include phone() {
      margin-top: 8px;
    }
  }
}

.instruction-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: calc(100dvh - 200px);

  @include wide-tablet() {
    max-height: calc(100dvh - 120px);
    gap: 14px;
  }

  &__content-wrapper {
    @include phone() {
      padding: 12px 0;
    }
  }

  &__content {
    margin-bottom: 24px;

    @include wide-tablet() {
      padding: 0 10px;
      margin-bottom: 14px;
    }
  }

  &__header {
    text-align: left;
    font: 700 26px / 34px "PT Sans", sans-serif;

    @include wide-tablet() {
      font-size: 15px;
      line-height: 19px;
      padding: 0 10px;
    }

    .counter {
      font: 400 20px / 26px "PT Sans", sans-serif;
      margin-left: 8px;
      color: rgba(64, 64, 64, 0.74);

      @include wide-tablet() {
        font-size: 11px;
        line-height: 15spx;
      }
    }
  }

  .spheres__list {
    margin-bottom: 14px;
    .title {
      font: 700 14px / 18px "PT Sans", sans-serif;
      margin-bottom: 6px;
      margin-left: 6.5px;

      @include phone() {
        margin-bottom: 2px;
      }
    }

    .active {
      border: 0.7px solid rgba(0, 114, 203, 1) !important;
      background-color: #d6e9f9;
    }

    .sphere {
      font: 400 10px / 13px "PT Sans", sans-serif;
      width: 379px;
      // border: 1px solid red;
      padding: 6px 9px 6px 6.5px;
      border: 0.7px solid rgba(255, 255, 255, 0);
      border-radius: 2px;

      @include phone() {
        padding: 6px 9px 5px 6px;
      }

      &:hover {
        border: 0.7px solid rgba(0, 114, 203, 1);
        background-color: #d6e9f9;
      }

      &::after {
        content: "";
        position: absolute;
        left: 6.5px;
        width: 363px;
        height: 1px;
        background: rgba(72, 77, 101, 0.3);
        margin-top: 19px;
      }

      &:last-child::after {
        content: none;
      }
    }
  }

  &__text {
    font: 400 20px / 26px "PT Sans", sans-serif;

    @include wide-tablet() {
      font-size: 11px;
      line-height: 14px;
    }

    @include phone() {
      padding: 0 12px;
      line-height: 12px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-bottom: 24px;

    @include wide-tablet() {
      gap: 14px;
      margin-bottom: 18px;
    }

    .cr-button {
      &__text {
        @include wide-tablet() {
          font: 700 11px / 14px "PT Sans", sans-serif;
        }
      }
    }

    .crowd-birthday-10-button {
      height: 48px;
      max-width: 190px;

      @include wide-tablet() {
        padding: 1px 4px;
        width: 107px;
        height: 27px;
      }
    }

    .skip {
      padding: 11px 37px;
    }
  }

  &__images-container {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;

    @include wide-tablet() {
      gap: 9px;
      margin-bottom: 14px;
    }

    .before {
      background-image: url("../../../../../../assets/birthday10/images/instruction/before.png");
    }

    .after {
      background-image: url("../../../../../../assets/birthday10/images/instruction/after.png");
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font: 400 14px / 18px "PT Sans", sans-serif;
    text-align: left;
    border-top: 1px solid rgba(72, 77, 101, 0.3);
    padding: 10px 3px 16px 3px;

    @include wide-tablet() {
      margin: 0 10px;
      padding-top: 6px;
      padding-bottom: 7px;
      font-size: 8px;
      line-height: 10px;
    }

    .footer-link {
      &:hover {
        cursor: pointer;
      }

      a:hover {
        text-decoration: none;
      }

      &__rules,
      &__help {
        text-decoration: underline;
        color: #404040;
        &:hover {
          text-decoration: none;
        }
      }

      &__promo {
        display: flex;
        color: rgba(64, 64, 64, 1);
        text-decoration: none;
        justify-content: flex-end;

        span {
          padding: 5px 0;

          @include wide-tablet() {
            padding: 3px 0;
          }
        }

        &:hover {
          text-decoration: none;
        }

        &__arrow {
          width: 28px;
          height: 28px;
          background-color: #cbe1f5;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("../../../../../../assets/birthday10/icons/arrow.svg");
          border-radius: 15px;
          margin-left: 8px;
          position: relative;

          @include wide-tablet() {
            width: 16px;
            height: 16px;
            margin-left: 4.5px;
            background-size: 4.5px;
          }
        }
      }

      .copyright {
        color: rgba(64, 64, 64, 0.6);
        cursor: auto;
      }
    }
  }

  .instruction-modal__image {
    background-color: rgba(64, 64, 64, 1);
    width: 332px;
    height: 199px;
    border-radius: 16px;
    background-size: cover;
    position: relative;

    @include wide-tablet() {
      width: 187px;
      height: 112px;
      border-radius: 9px;
    }

    &::after {
      font: 400 20px / 21px "PT Sans", sans-serif;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.46);
      color: #fff;
      text-align: center;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      box-sizing: border-box;
      height: 27px;

      @include wide-tablet() {
        font-size: 11px;
        line-height: 15px;
        height: 15px;
      }
    }

    &.before::after {
      content: "до";
    }

    &.after::after {
      content: "после";
    }
  }
}
