.resend-email-modal {
  width: 650px;
  max-width: 100%;
  font-size: 16px;
  padding: 30px;

  &__title {
    margin-top: 0;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  &__text {
    padding-top: 10px;
    line-height: 23px;
  }

  &__buttons {
    text-align: right;
    margin-top: 30px;

    button:first-child {
      margin-right: 15px;
    }
  }
}

.resend-email-overlay {
  z-index: 1001;
}
