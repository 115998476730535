@import "../../../../vars";

.cr-button {
  padding: 16px 35px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  font-family: var(--font-family-primary);
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.15px;
  outline: none;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  white-space: nowrap;
  position: relative;
  border-radius: var(--round-corners);

  @include tablet() {
    font-family: var(--font-family-primary);
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.53px;
    padding: 11px 24px;
  }

  @include phone() {
    width: 100%;
  }

  &_long {
    padding: 12px 54px;
  }

  &_mid {
    padding: 12px 36px;
  }

  &-filled,
  &-filled-banner-button {
    background-color: var(--base-blue);
    border: 1px solid transparent;
    color: white;

    &:focus {
      background-color: var(--base-blue);
    }

    &:active {
      background-color: #0077b3;
    }

    &:hover {
      background-color: #3c8de2;
    }
  }

  &-filled-banner-button {
    font-family: var(--font-family-primary);
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    padding: 16px 24px;

    @include adaptive(960) {
      font-family: var(--font-family-primary);
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 1.35px;
      padding: 10px;
    }
  }

  &-outlined {
    border: 1px solid var(--base-blue);
    background-color: white;
    color: var(--base-blue);

    &:hover {
      background-color: rgba(0, 174, 239, 0.1);
    }

    &:active {
      background-color: var(--base-blue);
      color: white;
    }
  }

  &-outlined-grey {
    border-color: #666;
    background-color: transparent;
    color: #666;
    border-width: 1px;

    &:hover {
      background-color: #cceffc;
    }
  }

  &-disabled {
    pointer-events: none;
    border-color: transparent;
    color: #b2b2b2;
    background-color: #ebebeb;

    &:hover,
    &:active,
    &:focus {
      border-color: transparent;
      color: #b2b2b2;
      background-color: #ebebeb;
    }
  }

  &-loading {
    pointer-events: none;
    opacity: 0.6;
  }

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__icon {
    position: absolute;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;
    pointer-events: none;

    &-loader {
      position: relative;
      height: 30px;
      width: 30px;
      display: inline-block;
      animation: around 5.4s infinite;

      &::before,
      &::after {
        content: "";
        background: white;
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 100%;
        border-width: 2px;
        border-color: var(--base-blue) var(--base-blue) transparent transparent;
        border-style: solid;
        border-radius: 20px;
        box-sizing: border-box;
        top: 0;
        left: 0;
        animation: around 0.7s ease-in-out infinite;
      }

      &::after {
        animation: around 0.7s ease-in-out 0.1s infinite;
        background: transparent;
      }
    }
  }

  span {
    vertical-align: middle;
  }
}

@keyframes around {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
