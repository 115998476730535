@import "../../../../vars";

.trio {
  width: 100%;
  height: 100%;
  perspective: 1000px;
  position: relative;

  .item {
    transition: all 1s ease 0s;
    transform-style: preserve-3d;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--corners-l);
    overflow: hidden;
    transform-origin: 0 0;

    @include tablet {
      border-radius: var(--corners-m);
    }

    &-left {
      --scale: 0.405;
      --trabslate-x: -43.5%;
      --translate-y: 52.4%;
      transform: translateX(calc(var(--trabslate-x) * var(--scale)))
        translateY(var(--translate-y)) scale(var(--scale));
      z-index: 3;
      opacity: 1;
      cursor: pointer;

      @include tablet {
        --scale: 0.4135;
        --trabslate-x: -40%;
        --translate-y: 50%;
      }
    }

    &-center {
      transform: scale(1);
      z-index: 2;
      opacity: 1;
    }

    &-right {
      --scale: 0.605;
      --trabslate-x: 73.3%;
      --translate-y: 11.2%;
      transform: translateX(calc(100% - var(--trabslate-x) * var(--scale)))
        translateY(var(--translate-y)) scale(var(--scale));
      z-index: 1;
      opacity: 1;
      cursor: pointer;

      @include tablet {
        --scale: 0.62;
        --trabslate-x: 81%;
        --translate-y: 16.6%;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

    div {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.arrows {
  width: fit-content;
  right: -40px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 32px;
  z-index: 50;
  gap: 32px;

  @include tablet {
    gap: 16px;
    bottom: 16px;
    right: -32px;
  }

  &-btn {
    cursor: pointer;

    i {
      font-size: 24px;
    }

    &--left i {
      color: #ffffff;
    }
  }
}
