@import "../../../../../vars";

.statistic {
  width: 184px;

  @include tablet {
    width: 162px;
  }

  @include adaptive(760) {
    width: 140px;
  }
  @include adaptive(670) {
    width: 120px;
  }
  @include adaptive(610) {
    width: 100px;
  }

  @include phone {
    width: calc(50% - 6px);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  text-decoration: none !important;

  &:hover {
    .icon {
      color: var(--base-blue) !important;
    }

    .name {
      color: var(--base-blue) !important;
    }
  }
}

.icon {
  font-size: 32px;
  margin-bottom: 12px;
  color: var(--base-text);
  transition: color 0.1s ease-in-out;

  @include short-desktop {
    margin-bottom: 8px;
  }

  @include phone {
    font-size: 24px;
    margin-bottom: 4px;
  }
}

.count {
  font: var(--text-h2);
  line-height: 48px;
  margin-bottom: 7px;
  color: var(--base-blue);

  @include short-desktop {
    font: normal normal 600 32px/43px var(--font-family-primary);
    line-height: 38px;
    margin-bottom: 4px;
  }

  @include phone {
    margin-bottom: 0;
    font: var(--text-h4);
  }
}

.name {
  color: var(--base-text-secondary);
  transition: color 0.1s ease-in-out;
  text-align: center !important;

  @include short-desktop {
    font: var(--text-body4);
  }
}
