$expert: #ff9425;
$frontman: #796cd9;
$facilitator: #1473e6;

@mixin setUserAvatarSize($size) {
  width: $size + rem;
  min-width: $size + rem;
  height: $size + rem;
}

@mixin setUserAvatarType($type, $color) {
  border: 2px solid $color;
  &::before {
    content: $type;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    bottom: -0.125rem;
    right: -0.125rem;
    background: $color;
    border-radius: 50%;
    color: white;
  }
}

.user-avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font: normal normal bold 16px/24px PT Sans;
  width: 100%;
  background-size: cover;
  background-color: var(--color-secondary-l6);

  img {
    display: flex;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  }

  &__container {
    overflow: hidden;
  }

  &__online {
    &::after {
      content: "";
      position: absolute;
      top: -0.125rem;
      right: 0;
      width: 0.875rem;
      height: 0.875rem;
      background: #00cc88;
      border: 2px solid white;
      border-radius: 50%;
    }
  }

  &__xs {
    @include setUserAvatarSize(2.5);
  }
  &__s {
    @include setUserAvatarSize(3);
  }
  &__m {
    @include setUserAvatarSize(4);
  }
  &__l {
    @include setUserAvatarSize(6);
  }
  &__xl {
    @include setUserAvatarSize(8);
  }
  &__xxl {
    @include setUserAvatarSize(12);
  }

  &__expert {
    @include setUserAvatarType("Э", $expert);
  }

  &__frontman {
    @include setUserAvatarType("Л", $frontman);
  }

  &__facilitator {
    @include setUserAvatarType("К", $facilitator);
  }

  &__blocked {
    background: var(--base-red);
  }
}
