@import "../../../../../vars";

.leisure-landing-informer {
  width: fit-content;

  & #{&}__modal {
    transition: opacity 0.5s ease-in-out;
    width: 300px;
    text-align: center;
    transform: translate(0, 0);
    --indent: 10px;

    &-hidden {
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
    }

    &-left {
      right: calc(100% + var(--indent));
      left: auto;
      top: 50%;
      transform: translate(0, -50%);
    }
    &-right {
      left: calc(100% + var(--indent));
      right: auto;
      top: 50%;
      transform: translate(0, -50%);
    }
    &-top {
      bottom: calc(100% + var(--indent));
      top: auto;
      transform: translate(-50%, 0);
    }
    &-bottom {
      top: calc(100% + var(--indent));
      bottom: auto;
      transform: translate(-50%, 0);
    }
    &-left-top {
      right: calc(100% + var(--indent));
      left: auto;
      bottom: calc(var(--indent) * -1);
      top: auto;
    }
    &-left-bottom {
      right: calc(100% + var(--indent));
      left: auto;
      top: calc(var(--indent) * -1);
      bottom: auto;
    }
    &-right-top {
      left: calc(100% + var(--indent));
      right: auto;
      bottom: calc(var(--indent) * -1);
      top: auto;
    }
    &-right-bottom {
      left: calc(100% + var(--indent));
      right: auto;
      top: calc(var(--indent) * -1);
      bottom: auto;
    }

    &__img {
      margin: -20px -20px 20px -20px;
      img {
        width: 100%;
        border-radius: 14px;
      }
    }
    &__title {
      text-align: center;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      a {
        text-decoration: none;
      }
    }
  }

  &__button {
    border: none;
    outline: none;
    background: none;
  }
}
