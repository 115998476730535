@import "../../../vars";

.project-page--announcement,
.project-page--current {
  .project__header {
    height: fit-content;
    min-height: 500px;
    padding-top: 46px;
    box-sizing: content-box;
    position: relative;
    margin-bottom: 36px;
    @include tablet() {
      min-height: fit-content;
    }

    @include phone() {
      margin-bottom: 20px;
    }

    .project__logo {
      height: 500px;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 600px;

      @include tablet() {
        height: fit-content;
      }

      &--mobile {
        display: none;
        width: calc(100% - 40px);
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        align-self: flex-end;
        margin-top: -8px;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 140px;

        @include adaptive(500) {
          display: block;
        }
      }
    }

    .project__brackets-area {
      height: 286px;

      @include tablet() {
        height: 114px;
      }

      @include phone() {
        font: 700 21px/20px var(--font-family-primary);
        letter-spacing: 0.15px;
        height: 81px;
      }
    }
  }
}

.project-page--current {
  .project__header {
    .join-area {
      @include tablet() {
        margin-top: auto !important;
        height: 100%;
      }

      @include phone() {
        margin-top: 0 !important;
        margin-bottom: 0px !important;
      }
    }
  }
}

.project-page--announcement {
  .project__header {
    .join-area {
      @include tablet() {
        margin-top: auto !important;
        margin-bottom: auto !important;
      }
    }
  }
}

.project__page-description {
  font: 18px/26px var(--font-family-primary);
  letter-spacing: 0.32px;
}

.project {
  width: 100%;
  height: 100%;
  background-color: #ffffff;

  &__nav-container,
  &__page-description,
  &__group-wrapper {
    width: 100%;
    max-width: 1272px;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;

    @include phone() {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  &__header {
    height: fit-content;
    min-height: 500px;
    background: linear-gradient(0deg, #f5f5f5 50%, #ffffff 50%, #ffffff 100%);
    padding-top: 46px;
    box-sizing: content-box;
    position: relative;

    &::before,
    &::after {
      content: "";
      background: linear-gradient(0deg, #f5f5f5 50%, #ffffff 50%, #ffffff 100%);
      height: 100%;
      position: absolute;
      width: 50vw;
      bottom: 0;
    }
    &::before {
      margin-left: -50vw;
      left: 0;
    }
    &::after {
      left: 100%;
    }
  }

  &__wrapper {
    max-width: 1224px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
  }

  &__logo {
    height: 500px;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 600px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: var(--round-corners);
    }
    &--mobile {
      display: none;
    }
  }

  &__dates {
    text-transform: uppercase;
    white-space: nowrap;
    display: inline-block;
    width: min-content;

    &--current {
      color: var(--base-text);
      font: Bold 34px/41px var(--font-family-primary);
      letter-spacing: 0.25px;
      margin-bottom: 24px;
    }

    &--announcement {
      color: #65aae0;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 30%;
    width: 100%;
    margin-left: 30px;
  }

  &__brackets-area {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 42px;
    justify-content: center;
    top: 0;
    //max-width: 365px;
    width: min-content;
    height: 286px;
    border: 8px solid var(--base-text);
    border-bottom: none;
    border-top: none;
    font: Bold 44px/53px var(--font-family-primary);
    letter-spacing: 0.32px;
    text-align: left;
    z-index: 10;

    &:after,
    &:before {
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      border: 8px solid var(--base-text);
      border-left: none;
      border-right: none;
      width: 18px;
    }

    &:after {
      right: -1px;
    }

    &:before {
      left: -1px;
    }
  }

  &__label {
    color: #65aae0;
    white-space: nowrap;
    display: inline-block;
    width: min-content;
    padding-right: 40px;
  }

  &__sub-title {
    width: 450px;
    text-align: center;
    font: 18px/24px var(--font-family-primary);
    letter-spacing: 0.32px;
    color: var(--base-text);
    margin-top: 23px;
  }

  .join-area {
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--current {
      margin-top: 24px;
    }

    &__subscriber-status {
      font: Bold 24px/29px var(--font-family-primary);
      letter-spacing: 0.18px;
      color: var(--base-text);
      text-transform: uppercase;
      margin-top: 15px;
    }

    &__join-button {
      font: Bold 20px/24px var(--font-family-primary);
      letter-spacing: 0.67px;
      height: 48px;
      padding: 12px 36px;
      margin-right: 0;
    }
  }

  &__page-description {
    max-width: 1224px;
    width: 100%;
    margin: 0 auto;
    padding: 36px 0 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font: 18px/26px var(--font-family-primary);
    letter-spacing: 0.32px;
    color: var(--base-text);
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin: 42px 0;
  }
}

@media only screen and (max-width: 1224px) {
  .project {
    &__page-description {
      font: 16px/24px PT Sans;
      letter-spacing: 0.15px;
    }
  }
}

@include wide-tablet() {
  .project {
    &__header {
      min-height: 348px;
      background: linear-gradient(0deg, #f5f5f5 48%, #ffffff 48%, #ffffff 100%);
    }

    &__logo:not(.project__logo--mobile) {
      flex-grow: 0;
      height: 348px;
    }

    &__brackets-area {
      height: 190px;
      font: Bold 28px/33px var(--font-family-primary);
      letter-spacing: 0.21px;
      border-width: 6px;
      padding: 16px 34px 32px 34px;

      &::before,
      &::after {
        border-width: 6px;
      }
    }

    .join-area {
      &:not(.join-area--current) {
        margin-top: 0;
      }

      &__subscriber-status {
        font: Bold 20px/24px var(--font-family-primary);
        letter-spacing: 0.15px;
      }
    }

    &__dates {
      &--current {
        font: Bold 24px/29px var(--font-family-primary);
        letter-spacing: 0.18px;
        margin-bottom: 12px;
      }
    }

    &__page-description {
      font: 16px/24px PT Sans;
      letter-spacing: 0.15px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .project {
    &__logo {
      display: none;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 0;
      margin-right: 0;
      height: 100%;
      width: 100%;
    }

    &__brackets-area {
      font: Bold 16px/17px var(--font-family-primary);
      letter-spacing: 0.12px;
      align-self: flex-end;
      border-width: 4px;
      padding: 8px 24px 16px 24px;
      flex-shrink: 0;
      height: 96px;
      right: 24px;

      &::before,
      &::after {
        border-width: 4px;
        width: 8px;
      }
    }

    &__logo--mobile {
      display: block;
      width: calc(100% - 20px);
      height: fit-content;
      align-self: flex-end;
      margin-top: -8px;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 140px;
    }

    &__header {
      margin-left: -24px;
      margin-right: -24px;
      padding-top: 24px;
      &::before,
      &::after {
        content: none;
      }
    }

    .join-area {
      margin: auto 0;
      flex-shrink: 0;
      flex-basis: 92px;

      &__subscriber-status {
        font: Bold 16px/19px var(--font-family-primary);
        letter-spacing: 0.12px;
      }
    }

    &__dates {
      &--current {
        font: Bold 16px/19px var(--font-family-primary);
        letter-spacing: 0.12px;
      }
    }

    &__page-description {
      padding-top: 18px;
    }
  }
}
