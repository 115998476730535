@import "../../../../../vars";

.info-button__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: 4px;
  border-radius: 30px;
  background: #076EBF29;
  color: var(--base-blue);
  font-size: 11px;

  &:hover {
    background-color: var(--base-blue);
    color: #FFF;
  }

  @include medium-tablet() {
    width: 9.67px;
    height: 9.67px;
    font-size: 7.5px;
  }
}

.birthday__tooltip {
  background-color: #1a1a2e !important;
  border-radius: 8px !important;
  margin-top: 7px !important;
  padding: 8px 16px !important;
  text-align: left;
  max-width: 224px !important;
  opacity: 1 !important;

  div {
    font: 500 15px/20px 'Golos Text', sans-serif;
  }

  &::before, &::after {
    border-bottom-color: #1a1a2e !important;
  }
}

