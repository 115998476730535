@import "../../../../vars";
@import "~video-react/styles/scss/video-react";

.tags-card {
  display: flex;
  flex-direction: column;
  background: white 0 0 no-repeat padding-box;
  box-shadow: 0px 0px 2px #00000029;
  cursor: pointer;
  z-index: 10;
  border-radius: var(--round-corners);
  overflow: hidden;

  &__link-wrapper {
    display: block;
    height: 100%;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &__video,
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 221px;

    @include adaptive(1024) {
      height: 126px;
    }
    @include phone() {
      height: 157px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    video {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &__text-wrapper {
    padding: 0 16px 16px;
    min-width: 0;

    @include tablet() {
      padding: 0 12px 12px;
    }

    .tags-card__details {
      display: flex;
      margin: 16px 0 12px;
      justify-content: space-between;
      flex-wrap: nowrap;

      .tags-card__tag {
        margin: 0 5px 5px 0;
        padding: 2px 10px;
        border: 1px solid var(--tags-card-color);
        white-space: nowrap;
        font: normal normal bold 14px/19px var(--font-family-primary);
        letter-spacing: 1.35px;
        text-transform: uppercase;
        color: var(--tags-card-color);
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: var(--round-corners-sm);
        max-width: max-content;

        &.activity__tag {
          &:hover,
          &.active {
            color: white;
            background: var(--tags-card-color);
          }
        }
      }

      .tags-card__date {
        font: normal normal normal 14px/20px var(--font-family-primary);
        letter-spacing: 0.25px;
        color: #808080;
        margin-left: auto;
      }
    }

    .tags-card__description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font: normal normal normal 18px/20px var(--font-family-primary);
      letter-spacing: 0.32px;
      color: var(--base-text);
      word-break: break-word;

      @include tablet() {
        font: normal normal normal 16px/20px var(--font-family-primary);
        letter-spacing: 0.29px;
      }

      @include phone() {
        font: normal normal normal 14px/18px var(--font-family-primary);
        letter-spacing: 0.25px;
      }
    }
  }

  .share-icon {
    margin-top: 15px;
    margin-left: auto;
  }
}
