@import "../../../../vars";

.auth-child-forbidden-modal {
  @include tablet {
    max-width: 520px !important;
  }

  .logo {
    pointer-events: none;
  }

  &__title {
    padding: 63px 0 16px;
    text-align: center;
    font: normal normal bold 92px/119px PT Sans;
    letter-spacing: 0.68px;
    color: var(--base-pink);
    opacity: 1;

    @include tablet {
      padding-top: 51px;
      font-size: 64px;
      line-height: 84px;
      letter-spacing: 0.47px;
    }

    @include phone {
      padding-top: 21px;
      font-size: 52px;
      line-height: 67px;
      letter-spacing: 0.38px;
    }
  }

  &__text {
    text-align: center;
    font: normal normal normal 18px/28px PT Sans;
    color: var(--base-text);
    margin-bottom: 32px;

    @include tablet {
      line-height: 24px;
      margin-bottom: 24px;
      padding: 0 16px;
    }

    @include phone {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;
      padding: 0;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }
}

.auth-child-forbidden-overlay {
  z-index: 1001;
}
