@import "../../../../vars";

$height: 50px;

.banner-line {
  position: absolute;
  width: 100%;
  max-height: $height;
  height: 100%;
  left: 0;
  cursor: pointer;
  z-index: 2;
  margin-top: -$height;
}

.slide {
  img {
    width: 100%;
    margin-bottom: -2px;
    height: $height;
    object-fit: cover;
  }
}

.link {
  font-size: 0;
}
