@font-face {
  font-family: "Nexa Script";
  src: url("nexascript_thin.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Nexa Script";
  src: url("nexascript_light.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nexa Script";
  src: url("nexascript_regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Nexa Script";
  src: url("nexascript_bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Nexa Script";
  src: url("nexascript_heavy.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
