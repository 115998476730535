@import "../../../../../../vars";

.crowd-birthday-10 {
  &-modal#{&}-ideas-group-all-found {
    padding-bottom: 28px;
    @include wide-tablet {
      padding-bottom: 16px;
    }

    .crowd-birthday-10-button {
      width: 190px;
      @include wide-tablet {
        width: auto;
      }
    }
  }
}
