@import "../../../../../vars";

.discussion-modal-modal {
  .cr-button {
    font: normal normal bold 16px/19px Montserrat;
    letter-spacing: 0px;
    border-radius: 20px;
    text-transform: capitalize;
  }

  .submit-form__title  {
    @include phone() {
      margin-top: 28px;
    }
  }
}
