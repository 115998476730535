@import "../../../../../../vars";

.discussion-event-success-modal {
  padding: 48px;

  @include phone() {
    padding: 16px 20px;
  }

  &__title {
    font: normal normal bold 28px/34px Montserrat;
    letter-spacing: 0px;
    color: #282828;

    @include tablet() {
      font: normal normal bold 20px/24px Montserrat;
      letter-spacing: 0px;
      color: #282828;
    }

    @include phone() {
      margin-top: 28px;
    }
  }

  &__body {
    font: normal normal 500 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #282828;
    margin-top: 24px;

    @include tablet() {
      font: normal normal 500 16px/19px Montserrat;
      letter-spacing: 0px;
      color: #282828;
    }

    @include tablet() {
      margin-top: 16px;
    }
  }
}
