@import "../../../vars";

#modal-overlay {
  background: rgba(0, 0, 0, 0.5);

  > div {
    width: 100%;
    max-width: 648px;
  }
}

.modal-base {
  border-radius: var(--round-corners);
}

button[class^="styles_closeButton"] {
  display: none;
}

.modal__close-btn {
  position: absolute;
  top: 24px;
  right: 24px;

  @include adaptive(500) {
    top: 18px;
    right: 20px;
  }
}

#close-modal-icon {
  cursor: pointer;
  opacity: 0.7;

  svg {
    background: #ffffffbf;
    border-radius: 50%;
  }

  &:hover {
    opacity: 1;
  }
}

@media only screen and (max-width: 500px) {
  #modal-overlay {
    padding: 0;

    > div {
      width: 100%;
      min-height: 100%;
      margin: 0;
      padding: 30px 20px 0 20px;
      box-sizing: border-box;
    }
  }

  .modal-base {
    border-radius: 0;
  }
}
