@import "../../../../../vars";

.generation-modal {
  padding: 48px;
  position: relative;
  max-width: 800px !important;
  @include adaptive(500) {
    padding: 0 20px 20px !important;
  }

  &__title {
    font: normal normal bold 36px/42px Bebas Neue;
    color: var(--base-text);
    text-transform: uppercase;
    margin-bottom: 32px;

    @include tablet {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 24px;
    }

    @include phone {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 20px;
    }

    @include adaptive(500) {
      margin-top: 20px;
    }
  }

  .promo-icon-fullscreen-wrap {
    position: absolute;
    right: 72px;
    top: 24px;

    @include phone {
      display: none;
    }

    button {
      outline: none;
      background: transparent;
      border: none;
      color: var(--base-text);
      cursor: pointer;
    }
  }
}
