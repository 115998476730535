@import "../../../vars";

.new-year-root {
  --nato-blue: #162d47;
  --enokitake-mushroom: #ffeedd;
  --deep-merlot: #461923;
  --plum-velvet: #642b38;
  --dark-blue: #20355e;
  --light-gray: #eef1f3;

  --scale: 1;

  @mixin theme($name) {
    [data-theme="#{$name}"] {
      @content;
    }
  }

  @include theme(welcome) {
    --footer-text: var(--enokitake-mushroom);
    --footer-bg: #461923;
    --footer-top-line: #642b38;
    --footer-sub-line: #38131b;
    --header-button-bg: var(--enokitake-mushroom);
    --header-button-text: var(--nato-blue);
  }

  @include theme(crowd) {
    --footer-text: var(--enokitake-mushroom);
    --footer-bg: #461923;
    --footer-top-line: #642b38;
    --footer-sub-line: #38131b;
    --header-button-bg: var(--nato-blue);
    --header-button-text: var(--enokitake-mushroom);
  }

  @include theme(ag) {
    --footer-text: var(--enokitake-mushroom);
    --footer-bg: #8a4137;
    --footer-top-line: #a84d41;
    --footer-sub-line: #70342c;
    --header-button-bg: var(--nato-blue);
    --header-button-text: var(--enokitake-mushroom);
  }

  @include theme(ed) {
    --footer-text: var(--enokitake-mushroom);
    --footer-bg: #826a74;
    --footer-top-line: #948089;
    --footer-sub-line: #735b65;
    --header-button-bg: var(--nato-blue);
    --header-button-text: var(--enokitake-mushroom);
  }

  @include theme(gz) {
    --footer-text: var(--enokitake-mushroom);
    --footer-bg: #3c3f56;
    --footer-top-line: #464960;
    --footer-sub-line: #313448;
    --header-button-bg: var(--enokitake-mushroom);
    --header-button-text: var(--nato-blue);
  }

  @include theme(ng) {
    --footer-text: var(--enokitake-mushroom);
    --footer-bg: #aa704a;
    --footer-top-line: #c48459;
    --footer-sub-line: #99613d;
    --header-button-bg: var(--enokitake-mushroom);
    --header-button-text: var(--nato-blue);
  }

  @include theme(mp) {
    --footer-text: var(--enokitake-mushroom);
    --footer-bg: #632924;
    --footer-top-line: #863932;
    --footer-sub-line: #51211d;
    --header-button-bg: var(--nato-blue);
    --header-button-text: var(--enokitake-mushroom);
  }

  a {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    @include disableSelect;
    &:hover {
      text-decoration: none;
    }
  }
}
