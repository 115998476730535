@import "../../../../vars";

.slider-arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;

  @include tablet() {
    padding: 24px 0;
  }

  @include phone() {
    padding: 16px 0;
  }

  .ic {
    font-size: 24px;
    margin: 0 12px;
    cursor: pointer;
  }

  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .page-dot {
    cursor: pointer;
    width: 6px;
    height: 6px;
    background: var(--base-gray2);
    border-radius: 50%;
    margin: 0 4px;

    &:hover,
    &_active {
      background: var(--base-dark-blue);
    }
  }
}
