@import "../../../../../vars";

.crowd-birthday-10-map {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @include disableSelect;

  &__inner {
    transform-origin: 0 0;
    cursor: default;
  }

  &__bg {
    pointer-events: none;
    position: absolute;
    display: flex;
    flex-wrap: wrap;

    img {
      margin-right: -3px;
      margin-bottom: -3px;
    }
  }

  &__block {
    position: absolute;
    transform-origin: 0% 0%;
  }

  &__zoom {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 41px;
    border-radius: 23px;
    overflow: hidden;

    @include wide-tablet {
      right: 20px;
      border-radius: 15px;
    }

    @include medium-tablet {
      right: 9px;
      border-radius: 8px;
    }

    &__plus,
    &__minus {
      width: 72px;
      height: 64px;
      background-color: #d0e2f7;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #404040;

      @include wide-tablet {
        width: 48px;
        height: 43px;
        font-size: 16px;
      }

      @include medium-tablet {
        width: 32px;
        height: 29px;
        font-size: 11px;
      }

      &:hover {
        background-color: #b6d6f2;
        color: #0072cb;
      }
    }

    &__plus {
      border-bottom: 1px solid #484d654d;
    }
  }
}
