.leisure-landing-button {
  outline: none;
  padding: 14px 30px 15px;
  color: white;
  text-align: center;
  font: normal normal 700 14px / normal Montserrat;
  border-radius: 27px;
  border: 4px solid #d3cfdb;
  background: radial-gradient(50% 50% at 50% 50%, #7662a1 0%, #493771 100%);
}
