.link {
  text-decoration: none;
  color: var(--header-top-section-font-color);
  
  &:hover,
  &:focus,
  &:active,
  &:visited {
    text-decoration: none;
  }
}
