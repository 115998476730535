@import "../../../../../../../vars";

.news-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 10px #0000001a;
  border-radius: 20px;
  overflow: hidden;

  &__header {
    flex-shrink: 0;
    position: relative;
    height: 202px;
    @include wide-tablet {
      height: 180px;
    }
    @include phone {
      height: 144px;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__footer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
  }

  &__scroll {
    height: 72px;
    margin-bottom: 16px;

    @include phone {
      height: 88px;
    }
  }

  &__text {
    font: normal normal bold 20px / 24px Montserrat;
    letter-spacing: 0px;
    color: #282828;

    @include phone {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
