@import "../../../../vars";

$header-card-font: normal normal normal 18px/16px var(--font-family-primary);
$header-card-font-t: normal normal normal 14px/16px var(--font-family-primary);

.ideadetails-card {
  background-color: white;
  padding: 20px 20px 24px;
  display: flex;
  flex-direction: column;
  border-radius: var(--round-corners);
  border: 1px solid #dedee2;
  height: 100%;
  width: 100%;

  @include medium-tablet() {
    padding: 12px;
    width: 100%;
  }

  @include phone() {
    width: 100%;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    font: $header-card-font;
    letter-spacing: 0.6px;
    color: var(--base-text);
  }

  &__top {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font: normal normal bold 14px/19px var(--font-family-primary);
    letter-spacing: 1.34px;
    margin-bottom: 16px;
    gap: 5px;
  }

  .status-completed {
    color: #12cf90;
  }

  .status-uncompleted {
    color: var(--base-blue);
  }

  &__name {
    margin: 16px 0 8px 0;
    font: normal normal normal 18px/23px var(--font-family-primary);
    color: #282828;

    @include medium-tablet() {
      font: normal normal normal 16px/20px var(--font-family-primary);
    }
  }

  &__place {
    font: normal normal normal 14px/19px var(--font-family-primary);
    letter-spacing: 0px;
    color: #8a8a8a;
    padding-right: 11px;
  }

  &__title {
    font: normal normal bold 20px/26px var(--font-family-primary);
    margin-top: 16px;

    @include tablet() {
      font: normal normal bold 18px/23px var(--font-family-primary);
    }
  }

  &__date {
    margin-bottom: 8px;
  }

  &__organization {
    font: normal normal normal 14px/19px var(--font-family-primary);
    color: #8a8a8a;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    height: 100%;
  }

  &__has-image {
    height: 200px;
    width: 100%;
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__no-image {
    overflow: hidden;
    height: 200px;
    border-radius: 4px;

    img {
      display: block;
      margin: auto;
      object-fit: cover;
      scale: 115%;
      width: 100%;
    }
  }

  &__description {
    font: normal normal normal 16px/20px var(--font-family-primary);
    word-break: break-word;
    color: #282828;
    margin-top: 8px;
    box-sizing: border-box;
    height: 100%;
    padding-right: 14px;

    @include phone {
      font: normal normal normal 16px/20px var(--font-family-primary) !important;
      word-break: break-word;
      color: #282828;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    height: fit-content;
  }

  .status {
    &-completed {
      color: #12cf90;
    }
  }
}

.frontside {
  @include medium-tablet() {
    .ideadetails-card {
      &__body {
        flex-direction: column-reverse;
      }

      &__text-container {
        margin-top: 12px;
      }

      &__title {
        padding-right: 13px;
      }
    }
  }

  @include phone() {
    .ideadetails-card {
      &__body {
        flex-direction: column-reverse !important;
      }
    }
  }
}

.backside {
  .ideadetails-card__body {
    display: flex;
    flex-direction: column;
  }

  .ideadetails-card__description {
    top: 0;
    left: 0;
    overflow-y: auto;
    .ScrollbarsCustom-TrackY {
      width: 6px !important;
      background-color: rgba(28, 28, 28, 0.1) !important;
      height: 100% !important;
      margin-top: -10px;
    }

    .ScrollbarsCustom-ThumbY {
      background-color: rgba(28, 28, 28, 0.3) !important;
    }

    .ScrollbarsCustom-Content {
      padding-right: 8px !important;
    }
  }

  .ideadetails-card__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .share-icon {
    background-color: var(--base-blue);
    color: #ffffff;
  }
}
