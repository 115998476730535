@import "../../../../vars";

.cr-select {
  margin-top: 24px;

  &__input {
    font: normal normal normal 18px/23px var(--font-family-primary);
    letter-spacing: 0.13px;
    color: var(--base-text);
    border: 1px solid #dedede;
    outline: none;
    padding: 8px 28px 8px 14px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    cursor: pointer;
    position: relative;
    border-radius: var(--round-corners);

    &::before {
      content: "";
      position: absolute;
      right: 10px;
      top: calc(50% - 3px);
      width: 0;
      height: 0;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      border-top: 5px solid black;
    }

    &_open {
      &::before {
        transform: rotate(180deg);
      }
    }

    &_disabled {
      background-color: #d3d3d3;
      pointer-events: none;
      user-select: none;
      opacity: 0.6;
      cursor: default;
    }

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 23px;
    }

    &-invalid {
      border-color: $error;
      box-shadow: 0 0 0 1px $error;
    }

    &:focus {
      border: 1px solid #00aeef;
    }

    &-list {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      width: 100%;
      background-color: white;
      border: 1px solid #dedede;
      display: flex;
      flex-direction: column;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.058);
      overflow: auto;

      &-item {
        padding: 10px;
        cursor: pointer;

        &:hover {
          background-color: #cceffc;
        }
      }
    }
  }

  &__label {
    font: normal normal normal 16px/28px var(--font-family-primary);
    letter-spacing: 0.44px;
    margin-bottom: 6px;

    &-required {
      color: #009dde;
      margin-left: 5px;
    }
  }
}
