@import "../../../../../vars";

.ny-modal-game {
  width: fit-content;
  margin-top: 0;
  .ny-modal__close-btn {
    top: 17px;
    right: 16px;
  }
  .ny-modal__content {
    width: 100%;
    height: 100%;
  }
  @include medium-tablet() {
    width: 100%;
    height: 100%;
    max-width: none;
    border-radius: 0;
    background-color: var(--nato-blue);
    padding: 16px 0;
    z-index: 11;
  }
}
