@import "../../../vars";

.sphere-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .spherecard {
    width: calc(50% - 12px);
    margin-top: 24px;
    border-radius: var(--round-corners);
    overflow: hidden;
  }

  @include tablet() {
    .spherecard {
      width: 100%;
    }
  }
}
