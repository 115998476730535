.crowd-birthday-10-pin-question {
  $root: &;
  $h: 687px;

  position: relative;
  width: 278px;
  height: $h;
  transform: translate(-50%, -100%);

  &__bubble {
    width: 262px;
    height: 262px;
    cursor: pointer;
    transform: translateX(8px);
    &__icon {
      position: relative;
      width: 262px;
      height: 262px;
      border-radius: 50%;
      background: url(../../../../../../assets/birthday10/map/pin-question/bubble-incompleted.svg) center no-repeat;
      @supports not (-webkit-touch-callout: none) {
        transform: translate3d(0, 0, 0);
      }
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        mask: url(../../../../../../assets/birthday10/map/pin-question/icon-incompleted.svg) center no-repeat;
        background-color: #fff;
      }
      &:after {
        content: "";
        position: absolute;
        border: 8px solid #fff;
        border-radius: 50%;
        box-shadow: 0 0 60px 20px #fff;
        opacity: 0;
        width: calc(100% + 14px);
        height: calc(100% + 14px);
        top: -7px;
        left: -7px;
        @supports not (-webkit-touch-callout: none) {
          transition: opacity 0.08s;
        }
      }
    }

    &:hover,
    &:focus {
      #{$root}__bubble__icon {
        @supports not (-webkit-touch-callout: none) {
          animation: bubble-animation 1s;
        }
        &:before {
          background-color: #404040;
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &_completed {
    #{$root}__bubble {
      &__icon {
        background-image: url(../../../../../../assets/birthday10/map/pin-question/bubble-completed.svg);
        &::before {
          mask-image: url(../../../../../../assets/birthday10/map/pin-question/icon-completed.svg);
        }
      }
    }
  }

  @keyframes bubble-animation {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(30px);
    }
    50% {
      transform: translateY(15px);
    }
    75% {
      transform: translateY(30px);
    }
    100% {
      transform: translateY(0);
    }
  }
}
