.cr-file-preview {
    position: relative;
    padding-right: 20px;
    padding-bottom: 20px;
    margin-right: 15px;

    &__img {
        max-width: 100px;
    }

    &__close {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
    }

    &__size {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
        color: #ffffff;
        font-size: 12px;
        padding: 2px;
    }

}