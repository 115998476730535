@import "../../../../../vars";
.screen-loader {
  @include keyframe(wave) {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-25%);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../../../../../assets/newyear2023/screen-loader-bg.svg) center / cover no-repeat var(--nato-blue);
  z-index: 100;
  @include medium-tablet {
    background-size: 200%;
  }

  &:before {
    content: "";
    position: absolute;
    width: 566px;
    height: 239px;
    background: url(../../../../../assets/newyear2023/screen-loader-year.svg) 0 0 / contain no-repeat;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 42px));
    @include medium-tablet {
      transform: translate(-50%, -50%);
    }
    @include adaptive(600) {
      transform: translate(-50%, -50%) scale(0.75);
    }
  }

  &__bar {
    width: 76px;
    height: 112px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 82px), calc(-50% - 84px));
    @include medium-tablet {
      transform: translate(calc(-50% - 82px), calc(-50% - 42px));
    }
    @include adaptive(600) {
      transform: translate(calc(-50% - 61px), calc(-50% - 31px)) scale(0.75) perspective(0);
    }

    &__star,
    &__tree {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: #152d47;
      > span {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 0;
        transition: height 0.3s;
      }
    }

    &__star {
      width: 16px;
      height: 14px;
      top: 0;
      mask: url(../../../../../assets/newyear2023/screen-loader-star.svg) 0 0 no-repeat;
      z-index: 1;
      > span {
        background-color: #ffcb55;
        transition-delay: 0.3s;
      }
    }
    &__tree {
      width: 100%;
      height: 101px;
      bottom: 0;
      mask: url(../../../../../assets/newyear2023/screen-loader-tree.svg) 0 0 no-repeat;
      > span {
        display: flex;
        flex-direction: column;
        &:before {
          content: "";
          width: 200px;
          height: 27px;
          margin-top: -15px;
          margin-bottom: -2px;
          background-image: url("data:image/svg+xml,%3Csvg width='100' height='27' id='svg' viewBox='0 0 1440 390' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M 0,400 C 0,400 0,200 0,200 C 192.80000000000007,169.73333333333335 385.60000000000014,139.46666666666667 552,142 C 718.3999999999999,144.53333333333333 858.3999999999999,179.86666666666667 1002,195 C 1145.6000000000001,210.13333333333333 1292.8000000000002,205.06666666666666 1440,200 C 1440,200 1440,400 1440,400 Z' stroke='none' stroke-width='0' fill='%2388D16E' fill-opacity='1' class='transition-all duration-300 ease-in-out delay-150 path-0'%3E%3C/path%3E%3C/svg%3E");
          background-size: 100px 28px;
          background-position: left top;
          background-repeat: repeat no-repeat;
          animation: wave 1.5s linear infinite;
        }
        &:after {
          content: "";
          width: 100%;
          flex: 1;
          background-color: #88d16e;
        }
      }
    }
  }
}
