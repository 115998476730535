@import "../../../../../vars";

.node-selector {
  &__title {
    font: normal normal bold 28px/34px Montserrat;
    color: var(--base-text);
    margin-bottom: 8px;

    @include tablet {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 12px;
    }

    @include adaptive(500) {
      margin-top: 20px;
    }
  }

  &__modal {
    max-width: 696px !important;
    padding: 48px;

    @include adaptive(500) {
      padding: 0 20px 20px !important;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;

    li {
      padding: 16px 0;
      font: normal normal bold 20px/24px Montserrat;
      color: var(--base-blue);
      cursor: pointer;
      + li {
        border-top: 1px solid #2828281a;
      }

      @include tablet {
        padding: 12px 0;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}
