@import "../../../../vars";

.wrapper {
  text-align: justify;
}

.hidden {
  visibility: hidden;
}

.text {
  letter-spacing: 0.13px;
}

.map {
  margin-top: 32px;
  position: relative;
  border-radius: 60px;
  overflow: hidden;

  @include wide-tablet() {
    margin-top: 24px;
    border-radius: 30px;
  }

  @include phone {
    margin-top: 20px;
    border-radius: 16px;
  }

  :global(.olm-map) {
    height: 672px;
    transition: filter 0.3s ease-in-out;
    filter: grayscale(100%) sepia(70%) hue-rotate(180deg) saturate(200%);

    @include wide-tablet() {
      height: 576px;
    }

    @include phone {
      height: 452px;
    }
  }

  &.mapActive {
    :global(.olm-map) {
      filter: none;
    }
  }
}

.footer {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;

  @include phone {
    display: block;
  }
}
