@import "../../../../../vars.scss";

.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  @include wide-tablet {
    width: 24px;

    .text-container {
      display: none;
    }
  }

  .text {
    color: var(--header-top-section-font-color);
  }

  .icon {
    width: 24px;
    height: 28px;
    background: url("../../../../../assets//mayor_projects_icon.svg") 0 0 /
      contain no-repeat;
    fill: var(--header-top-section-font-color);
  }
}
