@import "../../../../../../vars";

.new-year-2023-gameWrapper {
  display: flex;
  justify-content: center;
  margin: auto;
  @include medium-tablet() {
    align-items: center;
    height: 100%;
  }

  &__loader {
    width: 400px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__textWrapper {
    width: 100%;
    text-align: center;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-size: 30px;
    font-weight: 900;
    line-height: 36px;
  }

  &__hidden {
    display: none;
  }

  &__subTitle {
    margin: 11px auto 32px;
  }

  &__endText {
    max-width: 320px;
    margin: 17px auto 32px;
  }

  &__winText {
    margin: 17px auto 0;
  }

  &__icon {
    background-color: var(--enokitake-mushroom);
    margin: 0 auto 16px;
  }

  &__victory {
    mask: url("../../../../../../assets/newyear2023/icons/victory.svg") 0 0 / contain no-repeat;
    width: 64px;
    height: 72px;
    margin: 0 auto 16px;
    @include medium-tablet() {
      margin: 0 auto 32px;
    }
  }

  &__loose {
    mask: url("../../../../../../assets/newyear2023/icons/loose.svg") 0 0 / contain no-repeat;
    width: 72px;
    height: 62px;
  }
}
