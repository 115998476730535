.profile-pic {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  margin: 0 auto;
  border-radius: var(--round-corners);
  overflow: hidden;

  &__img {
    width: 100%;
    height: 100%;

    &-rounded {
      border-radius: 16px;
    }
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 1;
    transition: all 0.2s;
    text-align: center;

    &:hover {
      background-color: rgba(235, 235, 235, 0.9);
      transform: scale(1);

      .profile-pic__overlay-content {
        opacity: 1;
        transform: scale(1.5);
      }
    }

    &-rounded {
      border-radius: 100%;
    }

    &-content {
      transition: all 0.2s;
      opacity: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-img {
      width: 100%;
      height: 40px;

      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAiCAMAAAAau2s5AAAAkFBMVEUAru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru9iTbBoAAAAL3RSTlMABgcJDA0OEBgZGyorLFJtbm9/gI6PkJSVmLW2u7zHy8/Q0dTW19ja8/T4+fr8/Rdds7cAAAEJSURBVHgBrdTrboIwAMXxUzZRESpeRKdblTmQy7Dv/3Y7kaRhZG2axf+H5pD8ElMv4ndrbVrDEZ2XpPOSdE4pUlVp0waPNtpUqVSARRc9iG4s2SUCxNj9KfMAy5GzyCUUz9McjuYnEoWWJ51TkjTQDIMW26JpvrYLDNJsBMX+rh/d315cULxr04dwwIMetLfDuOPDTYahvHF0sRXuuMsp2LTk3FlhwS37KTkLK2y5w36+cra+sPJ96avzMkV/mYIzs8Kk40MpJxNZcnSJ5xt+8P4In/GlYHFWftfXLAHc0PRv6PNTmJFUUDyPM5eLjiQKqfYqRZD7uM/A/KU4yyMwsTrXLlWfVwL4AQjgczXyoz9iAAAAAElFTkSuQmCC);
      background-repeat: no-repeat;
      background-position: center;
      transition: all 0.2s;
      transform: scale(0.7);
    }

    &-text {
      transition: all 0.2s;
      color: #00aeef;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      font-family: var(--font-family-primary);
      transform: translateY(-3px);
    }
  }
}
