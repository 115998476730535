.discussion-card {
  $root: &;
  padding: 24px 18px;
  border-radius: 20px;
  font: normal normal bold 20px/24px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  height: 140px;
  cursor: pointer;

  &__head {
    display: flex;
    align-items: center;
  }

  &__status {
    font: normal normal 500 14px/18px Montserrat;
    letter-spacing: 0px;
    margin-left: 4px;
  }

  &__title {
    margin-top: 24px;
  }

  &__started {
    background-color: #ff9425;
    background-image: url("../../../mocks/assets/discussion-waiting.svg");
    background-position: 105% -31%;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  &__new {
    background-color: #ffb867;
    cursor: default;
  }
  &__finished {
    background-color: #ffffff;
    border: 2px solid #FFB867CC;
    cursor: pointer;

    #{$root}__title {
      color: #ffb867cc;
    }

    #{$root}__status, .ic {
      color: #8a8a8a;
    }
  }
}
