
@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Regular.woff2');
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Bold.woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-ExtraBold.woff2');
    font-weight: 800;
    font-style: normal;
}