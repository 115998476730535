@import "./common.scss";
@import "../../../vars";

:root {
  --birthday9-yellow: #ffd881;
  --birthday9-orange: #f4bb74;
  --birthday9-gray: #999fae;
  --birthday9-blue: #a9d5f8;
  --birthday9-light-blue: #e2f2ff;
  --birthday9-july: #8ed7e5;
  --birthday9-violet: #c7b7fb;
  --birthday9-black: #252526;
  --birthday9-vanilla: #f3b9b8;
  --birthday9-red: #e86f88;
}

.crowd-birthday {
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  &__main {
    width: 100% !important;
    height: 100% !important;
    flex-direction: column;
    align-items: center;
    padding-top: $header-desktop-height;
    padding-bottom: 120px;
    flex: 1;

    @include wide-tablet() {
      padding-top: $header-tablet-height;
    }

    @include medium-tablet() {
      padding-bottom: 100px;
    }
  }

  .birthday-block-lessened {
    margin: 0 auto;
    max-width: 1488px;
    width: 100%;
    padding: 0 24px;
  }

  .birthday-title {
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 77px;
    text-align: center;
    text-transform: uppercase;
    color: var(--birthday9-black);
    @include phone {
      font-size: 42px;
      line-height: 50px;
    }
  }
}
