@import "../../../../vars";
.send-modal {
  max-width: 440px;
  height: auto;
  @include tablet() {
    max-width: 520px;
  }
  @include phone() {
    max-width: unset;
    width: 100%;
    height: 100%;
    padding-top: 0;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    @include phone() {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: unset;
      height: unset;
    }
  }

  &__text {
    font: normal normal normal 18px/26px PT Sans;
    text-align: center;
    max-width: 600px;

    @include phone() {
      margin-top: 20px;
    }
  }

  &__buttons {
    align-self: flex-end;
    margin-top: 24px;
    @include phone() {
      width: 100%;
    }
  }

  .modal__close-btn {
    display: none;
    @include phone() {
      display: block;
    }
  }
  .modal {
    &-title {
      font: normal normal bold 30px/37px Bebas Neue;
      text-align: left;
      margin-bottom: 16px;
      @include tablet() {
        font: normal normal bold 20px/24px Bebas Neue;
      }
      @include phone() {
        font: normal normal bold 24px/29px Bebas Neue;
      }
    }
    &-text {
      font: normal normal normal 20px/28px PT Sans;
      text-align: left;
      @include tablet() {
        font: normal normal normal 18px/24px PT Sans;
      }
      @include phone() {
        font: normal normal normal 16px/24px PT Sans;
      }
    }
  }
  .modal__mobile-profile {
    @include phone() {
      display: none;
    }
  }
}

.send-modal-overlay {
  padding: 0;
  background: rgba(0, 0, 0, 0.5);

  .send-modal {
    background: rgba(255, 255, 255, 0.98);
  }
}
