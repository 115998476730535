@import "../../../../../vars";

.birthday-header {
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  background-blend-mode: color-dodge;
  background: radial-gradient(61.41% 378.71% at 50% 50%, #FFFFFF 0%, #EFF6FF 71.71%, #D9E8F9 100%);
  height: 72px;
  width:  calc(100% - 80px) !important;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  position: fixed;
  margin: 0 40px;

  @include wide-tablet() {
    width: calc(100% - 48px) !important;
    margin: 0 24px;
  }

  @include medium-tablet() {
    height: 43px;
    width: calc(100% - 16px) !important;
    margin: 0 8px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }


  &__left {
    display: flex;
    flex-direction: row;
    gap: 37px;

    @include wide-tablet {
      gap: 20px
    }

    @include medium-tablet {
      gap: 10px;
    }
  }

  &__burger {
    &:hover {
      cursor: pointer;
    }

    @include medium-tablet() {
      height: 15px;
      width: 15px;
    }

    @include adaptive(452) {
      margin-right: -6px;
    }
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    margin-right: 40px;

    @include wide-tablet() {
      margin-right: 24px;
    }

    @include medium-tablet() {
      gap: 16px;
      margin-right: 8px;
    }

    .stats {
      @include adaptive(1220) {
        display: none;
      }

      @include medium-tablet() {
        display: flex;
      }

      @include adaptive(690) {
        display: none;
      }
    }

    .cr-select {
      margin-top: 0;
      width: 166px;
      height: 32px;
      position: relative;

      @include medium-tablet() {
        width: 102px;
        height: 20px;
        top: 0;
      }

      &__input {
        padding: 6px 14px 7px 8px;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 32px;

        @include medium-tablet() {
          padding: 1px 5px 2px 8px;
          gap: 6px;
          width: 102px;
          height: 20px;
        }

        .promo-icon-chevron-down-rounded {
          color: #3A566C;
          transition: transform 0.2s ease;
          
          @include medium-tablet() {
            font-size: 15px;
          }
        }

        .promo-icon-chevron-down-rounded.open {
          transform: rotate(180deg);
          color: #0072CB;
        }

        &:focus {
          background-color: rgba(214, 233, 249, 1);
        }

        &::before {
          right: unset;
          left: 20px;
          display: none;

          @include medium-tablet() {
            left: 10px;
          }
        }

        &-list {
          margin-top: 8px;
          border-radius: 7px;
          width: 200px;
          right: 0;
          left: -19px;
          padding: 8px;

          @include medium-tablet() {
            padding: 6px 6px 2px 6px;
          }

          &-item {
            padding: 8px;
            border-radius: 4px;
            border: 1px solid white;
            position: relative;

            @include medium-tablet() {
              padding: 4.7px 4.4px;
            }
        
            &:hover {
              border: 1px solid rgba(0, 114, 203, 1);
              background-color: #D6E9F9;
            }
        
            &::after {
              content: '';
              position: absolute;
              left: 5px;
              width: 118px;
              height: 0.5px;
              background: rgba(72, 77, 101, 0.3);
              margin-bottom: 8px;
              top: -1px
            }
          
            &:first-child::after {
              content: none;
            }
          }
        }
      }
      
      &__label {
        margin-bottom: 0;
      }


      &__input {
        span {
          font: 400 15px / 19px "PT Sans", sans-serif;
          height: unset;
          color: rgba(64, 64, 64, 0.6);
      
          @include medium-tablet() {
            font: 400 9px/11.65px 'PT Sans', sans-serif;
            position: relative;
            top: 1px;
          }
        }

        &-list {

          @include medium-tablet() {
            width: 141px;
            margin-top: 6px;
          }

          &-item {
            span {
              color: rgba(64, 64, 64, 1);

              @include medium-tablet() {
                font: 400 10px/13px 'PT Sans', sans-serif;
              }
            }
          }
        }
      }
    }

    .crowd-birthday-10-button {
      width: 137px;
      padding: 0 8px;
      height: 32px;

      @include wide-tablet() {
        width: 137px;
        padding: 0 5px;
      }

      @include medium-tablet() {
        width: 83px;
        height: 19px;
      }

      .cr-button { 
        padding: 2px 32px;
        height: 30px;

        @include wide-tablet() {
          padding: 2px 18px;
        }

        @include medium-tablet() {
          height: 17px;
        }

        &__text {
          @include wide-tablet() {
            font-size: 20px;
            line-height: 26px;
          }

          @include medium-tablet() {
            font-size: 12px;
            line-height: 15.5px;
          }
        }
      }
    }
  }

  &__divider {
    width: 1px;
    height: 100%;
    background-color: rgba(64, 64, 64, 0.3);
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 237px;
    margin-left: 3px;
    margin-bottom: 3px;
    background: radial-gradient(86.27% 159.38% at 54.01% 100%, #65AAE0 0%, #026ABC 100%);
    border-radius: 3px;
    border-bottom-left-radius: 20px;
    position: relative;
    z-index: 10;

    @include medium-tablet() {
      width: 141px;
      height: 41px;
      border-bottom-left-radius: 12px;
      margin-left: 1.5px;
    }

    .birthday10-logo {
      width: 89px;
      height: 43px;

      @include medium-tablet() {
        width: 62px;
        height: 30px;
      }
    }
    
  
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 3px;
      border-bottom-left-radius: 20px;
      padding: 1px;
      background: linear-gradient(rgba(70, 138, 190, 1), rgba(2, 107, 189, 1));
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;

      @include medium-tablet() {
        border-bottom-left-radius: 12px;
        padding: 0.6px;
      }
    }
  }

  .progress__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;
  }
}