@import "../../../../../../vars";

.activity-card {
  border-radius: 20px;
  box-shadow: 0px 2px 10px #0000001a;
  display: flex;
  flex-direction: column;

  @include tablet() {
    height: 521px;
  }

  @include phone() {
    height: 490px;
  }

  &__image {
    width: 100%;
    height: 202px;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    @include tablet() {
      height: 180px;
    }

    @include phone() {
      height: 144px;
    }
  }

  &__content {
    height: -webkit-fill-available;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font: normal normal bold 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #282828;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 72px;

    @include phone() {
      font: normal normal bold 18px/22px Montserrat;
      height: 66px;
    }
  }

  &__splitter {
    width: 100%;
    height: 1px;
    background-color: #8a8a8a;
    margin: 8px 0px;
  }

  &__place {
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #282828;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 38px;
  }

  &__date {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #8a8a8a;
    margin: 4px 0px;
  }

  &__scroll {
    height: 76px;
    margin-bottom: 16px;

    @include tablet() {
      height: 95px;
    }

    @include phone() {
      height: 114px;
    }
  }

  &__description {
    font: normal normal normal 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #282828;
  }

  .cr-button {
    margin-top: auto;
    width: 100%;
  }

  &__registered {
    .cr-button {
      border: none;
      pointer-events: none;
      background-color: #eefbff;
    }
  }

  &__finished,
  &__registration_finished {
    .cr-button {
      pointer-events: none;
      color: #dedede;
      border: 1px solid #dedede;
      background-color: white;
    }
  }
}
