@import '../../../vars';

.license-wrapper {
  li, p{font-family:sans-serif; font-size:16px;margin: 1em 0;line-height: 1.3em;}
  h1{
    text-align:center; font-size:34px;
    @include phone() {
      font-size: 24px;
    }
  }
  h3{font-size:24px;}
  h2{font-size:30px;}
  div{text-align:center;font-family:sans-serif; font-size:16px;}
}