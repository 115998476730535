.like-counter {
  font: normal normal bold 18px/24px PT Sans;
  letter-spacing: 0px;
  color: #65aae0;
  display: flex;
  align-items: center;

  &__like {
    margin-right: 14px;
  }

  &__liked {
  }

  &__no-vote {
    margin-right: 0;
  }

  .ic {
    color: #65aae0;
  }

  &__count {
    cursor: pointer;
  }
}
