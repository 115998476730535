@import "../../../../_vars";

.title {
  font: var(--text-body3);
  font-weight: 600;
  color: var(--base-text);
  letter-spacing: -0.14px;
  text-transform: uppercase;
  transition: color 0.1s ease-in-out;
  margin-bottom: 16px;

  @include wide-tablet {
    margin-bottom: 0;
  }
}

.titleLink {
  color: var(--base-text) !important;
  transition: color 0.1s ease-in-out;
  &:hover {
    color: var(--base-blue) !important;
    text-decoration: none !important;
  }
}

.children {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include wide-tablet {
    margin-top: 12px;
    gap: 8px;
  }
}

.child {
  font: var(--text-body4);
  letter-spacing: -0.14px;
  color: var(--base-text-secondary) !important;
  transition: color 0.1s ease-in-out;
  text-decoration: none !important;
  &:hover {
    color: var(--base-blue) !important;
  }
}
