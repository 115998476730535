.crowd-birthday-10-screen-stub {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url(../../../../../../assets/birthday10/screen-stub.png) 0 0 / cover no-repeat #64a4d9;
  z-index: 1003;

  &-modal {
    border-radius: 14px;
    width: 308px;
    padding: 30px !important;
    border-radius: 24px !important;
    margin-top: auto !important;
    .modal__close-btn {
      display: none;
    }

    .crowd-birthday-10-modal__content {
      padding-top: 216px;
      background: url(../../../../../../assets/newyear2023/screen-stub-icon.svg) center 0 no-repeat;
    }
  }
}

#crowd-birthday-10-screen-stub-modal {
  z-index: 1004;
  padding-left: 6px;
  padding-right: 6px;
  background: none;
}
