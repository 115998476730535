.cr-pagination {
  display: flex;
  align-items: center;

  .ic {
    display: block;
    margin: 0 4px;
    line-height: 24px;
    text-align: center;
  }

  & #{&}__arrow {
    &_disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &__num {
    cursor: pointer;
    font: normal normal normal 18px/24px var(--font-family-primary);
    color: var(--base-text);
    margin: 0 4px;

    @supports (-webkit-touch-callout: none) {
      // specific for IOS
      margin-top: -2px;
    }

    &_active {
      font-weight: bold;
      color: var(--base-blue);
    }
  }
}
