@import "../../../../../vars";

.new-year-2023-header {
  $root: &;
  padding: 20px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include disableSelect;
  @include adaptive(1366px) {
    padding: 20px 40px;
  }

  @include wide-tablet() {
    padding: 20px 20px;
  }

  @include medium-tablet() {
    padding: 14px 20px;
  }

  &__side {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__fullScreen {
    display: flex;
    color: var(--header-button-bg);
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    align-items: center;
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
    z-index: 10;
    &--active {
      color: var(--enokitake-mushroom);
      #{$root}__fullScreenIcon {
        background-color: var(--enokitake-mushroom);
      }
    }
  }

  &__fullScreenIcon {
    width: 20px;
    height: 20px;
    background-color: var(--header-button-bg);
  }

  &__fullScreenOpen {
    mask: url("../../../../../assets/newyear2023/icons/full-screen.svg") 0 0 / contain no-repeat;
  }

  &__fullScreenClose {
    mask: url("../../../../../assets/newyear2023/icons/fullScreenClose.svg") 0 0 / contain no-repeat;
  }

  &__navWrapper {
    margin-left: auto;
    .new-year-2023-button__login,
    .new-year-2023-header-burger {
      margin-left: 10px;
      @include wide-tablet() {
        margin-left: 20px;
      }

      @include medium-tablet() {
        margin-left: 30px;
      }
    }
  }

  &__counter:first-child {
    margin-right: 40px;
    @include wide-tablet() {
      margin-right: 30px;
    }

    @include medium-tablet() {
      margin-right: 10px;
    }
  }

  &__counter {
    display: flex;
    align-items: center;
    font: normal normal 700 20px / normal Montserrat;
    color: var(--header-button-bg);
    @include medium-tablet() {
      font-size: 14px;
    }
  }

  &__countNumbers {
    margin-left: 10px;
    @include medium-tablet() {
      margin-left: 5px;
    }
  }

  &__liftIcon {
    width: 32px;
    height: 32px;
    mask: url("../../../../../assets/newyear2023/icons/lift.svg") 0 0 / contain no-repeat;
    margin-right: 10px;
    background: var(--header-button-text);
    @include wide-tablet() {
      width: 20px;
      height: 20px;
    }
    &:hover {
      background: var(--header-button-bg);
    }
  }

  &__garland {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: background-color 0.2s;
    width: 24px;
    height: 32px;
    mask: url("../../../../../assets/newyear2023/icons/garland.svg") 0 0 / contain no-repeat;
    background: var(--header-button-bg);
    @include wide-tablet() {
      width: 16px;
      height: 21px;
    }
    @include medium-tablet() {
      width: 15px;
      height: 20px;
    }
  }

  &__game {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: background-color 0.2s;
    width: 28px;
    height: 28px;
    mask: url("../../../../../assets/newyear2023/icons/puzzle.svg") 0 0 / contain no-repeat;
    background: var(--header-button-bg);
    @include wide-tablet() {
      width: 20px;
      height: 20px;
    }
  }

  @include medium-tablet() {
    .new-year-2023-circleButton__completed {
      #{$root}__garland,
      #{$root}__game {
        mask: none !important;
        background: none !important;
        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
        &:before {
          background: var(--header-button-bg);
        }
        &:after {
          background: var(--header-button-text);
        }
      }
      #{$root}__garland {
        &:before {
          mask: url("../../../../../assets/newyear2023/icons/garland-fill.svg") 0 0 / contain no-repeat;
        }
        &:after {
          mask: url("../../../../../assets/newyear2023/icons/garland.svg") 0 0 / contain no-repeat;
        }
      }
      #{$root}__game {
        &:before {
          mask: url("../../../../../assets/newyear2023/icons/puzzle-fill.svg") 0 0 / contain no-repeat;
        }
        &:after {
          mask: url("../../../../../assets/newyear2023/icons/puzzle.svg") 0 0 / contain no-repeat;
        }
      }
    }
  }

  &__loginInner {
    width: 24px;
    height: 24px;
    background: var(--header-button-text);
    @include wide-tablet() {
      width: 20px;
      height: 20px;
    }
    @include medium-tablet() {
      background: var(--header-button-bg);
    }
  }

  &__loginIcon {
    mask: url("../../../../../assets/newyear2023/icons/login.svg") 0 0 / contain no-repeat;
  }

  &__logoutIcon {
    mask: url("../../../../../assets/newyear2023/icons/exit.svg") 0 0 / contain no-repeat;
  }

  &__navigation {
    position: absolute;
    z-index: 5;
    right: -60px;
    top: -20px;
    width: 100%;
    min-width: 195px;
    box-sizing: content-box;
    height: 100vh;
    padding: 134px 60px 134px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: transform 0.5s;
    transform: translateX(100%);

    .new-year-2023-button:not(:last-child) {
      margin-bottom: 30px;
    }

    .new-year-2023-button {
      @media (hover: hover) {
        &:hover {
          background-color: var(--enokitake-mushroom);
          color: var(--nato-blue);
        }
      }
    }

    &:after {
      content: "";
      background-color: var(--nato-blue);
      width: 100%;
      height: 100%;
      opacity: 0.9;
      position: absolute;
      top: 0;
      left: 0;
    }
    &--active {
      transform: translateX(0%);
    }
    &--notActive {
      &:after {
        background-color: var(--navigation);
      }
    }

    @include medium-tablet() {
      align-content: flex-start;
      top: -25px;
      padding: 85px 60px 73px 20px;
      flex-direction: row;
      flex-wrap: wrap;

      .new-year-2023-button:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    @media screen and (min-width: 961px) and (max-height: 799px) {
      padding: 94px 60px 134px 40px;

      .new-year-2023-button:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    @media screen and (max-width: 960px) and (max-height: 340px) {
      padding: 66px 60px 73px 20px;
    }
    @media screen and (max-width: 960px) and (max-height: 300px) {
      padding: 60px 60px 73px 20px;

      .new-year-2023-button:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}

.new-year-2023-button {
  &.disabled {
    opacity: 1;
  }

  &__blueIcon {
    background: linear-gradient(180deg, #ffeedd 0%, #f8ddc2 100%);
  }

  &__lightblueIcon {
    background: linear-gradient(180deg, #3bbbf3 0%, #0ea4e6 100%);
  }

  &__green {
    background: linear-gradient(180deg, #03a895 0%, #007a6c 100%);
  }

  &__orange {
    background: linear-gradient(180deg, #ffa71d 0%, #ff6b1d 100%);
  }

  &__yellow {
    background: linear-gradient(180deg, #ffd846 0%, #ffca4b 100%);
  }

  &__turquoise {
    background: linear-gradient(180deg, #1bc9e8 0%, #00a2be 100%);
  }

  &__secondBlue {
    background: linear-gradient(180deg, #0d77c3 0%, #1556ba 100%);
  }
}
