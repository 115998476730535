@import "../../../../_vars";

.data-loader-wr {
  min-height: 40px;
  min-width: 40px;
  width: 100%;
  // height: 100%;
  display: flex;
  align-items: center;
  // margin-top: 200px;

  .data-loader {
    width: 100px;
    height: 100px;
    position: relative;
    margin: 0 auto;
    vertical-align: middle;

    &:after {
      display: block;
      position: relative;
      width: 90px;
      height: 90px;
      @include animation("rotate 0.6s linear infinite");
      @include border-radius(100%);
      border: 2px solid #cfcfcf;
      border-right-color: #4c7999;
      content: "";
    }
  }
}

@include keyframe(rotate) {
  0% {
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
