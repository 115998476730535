@import "../../../../../vars";

.external-popup {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 220px;
  color: var(--base-text);
  background-color: #ffffff;
  box-shadow: 0px 5px 10px rgba(40, 40, 40, 0.149);
  z-index: 200;
  padding: 24px;
  border-radius: var(--round-corners);


  @include phone() {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    z-index: 200;
    height: 100%;
  }

  .logo {
    display: none;
    position: absolute;
    top: 0;
    left: 0;

    @include phone() {
      display: block;
      margin-left: 24px;
      margin-top: 8px;
    }
  }

  &__arrow {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    z-index: 201;

    @include adaptive(500) {
      display: none;
    }

    &::after {
      content: "";
      position: relative;
      width: 0;
      height: 0;
      display: block;
      border: 8px solid transparent;
      border-top-color: #ffffff;
      border-bottom: 0;
    }
  }

  &__head {
    display: flex;
    justify-content: flex-end;
  }

  &__logo {
    height: 60px;
    margin-right: auto;

    @include phone() {
      margin-top: 60px;
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
  }

  &__body {
    margin-top: 12px;
    font: normal normal normal 18px/23px Golos;
    letter-spacing: 0.13px;

    @include phone() {
      font: normal normal normal 16px/24px Golos;
      letter-spacing: 0.13px;
      margin-top: 20px;
    }
  }

  &__footer {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;

    .cr-button {
      letter-spacing: 0.67px;
      padding: 11px 35px;
      font: normal normal bold 20px/24px Bebas Neue;
      letter-spacing: 0.67px;

      @include phone() {
        padding: 10px 35px;
        font: normal normal bold 14px/19px PT Sans;
        letter-spacing: 1.35px;
      }
    }

    @include phone() {
      margin-top: 20px;
    }
  }

  &--project {
    &-gz {
      .external-popup {
        &__logo {
          height: 36px;
          width: 116px;
        }
        &__footer {
          margin-top: 32px;
          @include phone() {
            margin-top: 16px;
          }
        }
      }
    }
  }

  &.gz .external-links__popup-head > img {
    width: 115px;
    height: 36px;
    margin: 6px -8px 10px 6px;
  }
}
