@import "../../../../vars";

.crop-avatar {
  margin-left: auto;

  &-modal {
    max-width: 648px;
    @include phone() {
      padding-top: 0 !important;
    }
  }

  &__title {
    font: normal normal normal 18px/30px Arial;
    color: $font-color;
    margin-bottom: 20px;
  }

  &__content {
    text-align: center;
  }

  &__buttons {
    display: flex;
    margin-top: 20px;

    .cr-button:nth-child(2) {
      margin-left: auto;
      margin-right: 10px;
    }

    @include phone() {
      flex-direction: column;
      row-gap: 10px;
    }
  }

  &__error {
    margin-bottom: 10px;
  }

  &-disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}