.leisure-landing-events-slider {
  padding: 10px 30px 10px 10px;
  width: 508px;
  height: 220px;

  &__content {
    position: relative;
  }

  &__slide {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto 1fr;

    &__img {
      grid-row: 1/4;
      margin-right: 20px;
      width: 200px;
      height: 200px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
      }
    }

    &__title {
      color: var(--loden-purple);
      font: normal normal 700 20px / normal Montserrat;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    &__text {
      color: var(--loden-purple);
      font: normal normal 500 16px / normal Montserrat;
      align-self: flex-start;
      text-decoration: none !important;
    }

    &__link {
      font: normal normal 400 12px / normal Montserrat;
      margin-top: 10px;
      text-decoration: underline;
      height: fit-content;
      width: fit-content;
    }
  }

  &__nav {
    position: absolute;
    left: 220px;
    top: 140px;
    z-index: 1;
    display: flex;

    &-left,
    &-right {
      position: relative;
      width: 38px;
      height: 38px;
      cursor: pointer;
      background: url(../../../../../assets/leisure-landing/arrow-round-nav-icon.svg) center/contain no-repeat;
    }
    &-left {
      margin-right: 10px;
      transform: rotate(180deg);
    }
  }
}
