@import "../../../../vars";

.idea-card {
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 10px #0000001a;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 433px;
  border-radius: var(--round-corners);
  overflow: hidden;

  @include tablet() {
    height: 355px;
  }

  &__header {
    // background-image: url('https://crowd.mos.ru/uploads/get/f61adcb7-5fc6-4aec-b79a-0b4a810e8ddb');
    min-height: 288px;
    height: 100%;
    background-size: cover;
    background-position: center;
    padding: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
    position: relative;

    @include tablet() {
      min-height: 224px;
    }

    > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;

      &-author {
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 4px;
        color: #ffffff;
        padding: 6px;
        height: 32px;
        font: 14px/18px var(--font-family-primary);
        letter-spacing: 0.25px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 50%;
      }

      &-social {
        display: flex;
        position: relative;

        &-like {
          background-color: rgba(0, 0, 0, 0.8);
          border-radius: 4px;
          color: #ffffff;
          padding: 6px;
          height: 32px;
          font: 14px/18px var(--font-family-primary);
          letter-spacing: 0.25px;
          margin-right: 16px;
          cursor: pointer;

          display: flex;
          flex-wrap: nowrap;
          padding: 0 6px;
          align-items: center;

          &-rating {
            margin-left: 5px;
          }
        }
      }
    }
  }

  &__footer {
    background-color: white;
    padding: 16px;
    overflow: hidden;
    height: 100%;

    &-tag {
      width: fit-content;
      padding: 2px 10px;
      border: 1px solid #65aae0;
      white-space: nowrap;
      font: normal normal bold 14px/18px var(--font-family-primary);
      letter-spacing: 1.35px;
      text-transform: uppercase;
      color: #65aae0;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: var(--round-corners-sm);

      &--wrapper {
        display: flex;
      }

      &--group {
        border: 1px solid #65aae0;
        color: #65aae0;
      }

      &:hover,
      &.active {
        color: white;
        background: #65aae0;
      }
    }

    &-name {
      margin-top: 12px;
      font: normal normal bold 18px/20px var(--font-family-primary);
      letter-spacing: 0.32px;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      color: var(--base-text);
      flex-grow: 1;
      margin-top: 12px;

      @include tablet() {
        font: normal normal bold 14px/19px var(--font-family-primary);
        letter-spacing: 0px;
      }

      @include phone() {
        font: normal normal bold 14px/18px var(--font-family-primary);
        letter-spacing: 0.25px;
      }
    }
  }

  .user-name-hidden-icon {
    font-size: 13px;
  }
}
