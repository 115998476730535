.leisure-landing-footer-progress {
  display: flex;
  align-items: center;

  &__bar {
    position: relative;

    &__bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      mask: url("../../../../../../assets/leisure-landing/scroll-icon.svg") center no-repeat;
      background-color: #fff;
    }

    svg {
      display: block;
      circle {
        transform: rotate(-90deg);
        transform-origin: center;
        transition: all 0.2s;
        stroke: #fff;
      }
    }
  }

  &__nav {
    mask: url("../../../../../../assets/leisure-landing/arrow-nav-icon.svg") 0 0 no-repeat;
    width: 10px;
    height: 18px;
    background-color: #fff;
    cursor: pointer;

    &-prev {
      margin-right: 20px;
    }

    &-next {
      transform: rotate(180deg);
      margin-left: 20px;
    }

    &_disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
