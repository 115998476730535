@import "../../../../../../vars";

.sphere-project {
  $this: &;

  display: flex;
  flex-direction: column;
  text-align: center;
  > *:not(:last-child) {
    margin-bottom: 20px;
  }

  &__img-container {
    padding: 0 60px;
    @include phone {
      padding: 0 80px;
    }
    &__inner {
      position: relative;
      width: 100%;
      padding-bottom: 71%;

      #{$this}__stone {
        position: absolute;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: contain;
        &:nth-child(1) {
          background-image: url("../../../../../../assets/birthday9/images/stones/1.svg");
          width: 4.16%;
          height: 5.29%;
          left: 0;
          top: 41.76%;
        }
        &:nth-child(2) {
          background-image: url("../../../../../../assets/birthday9/images/stones/2.svg");
          width: 7.5%;
          height: 12.35%;
          right: 8.54%;
          top: 11.17%;
        }
        &:nth-child(3) {
          background-image: url("../../../../../../assets/birthday9/images/stones/3.svg");
          width: 8.33%;
          height: 14.7%;
          left: 11.45%;
          bottom: 8.23%;
        }
        &:nth-child(4) {
          background-image: url("../../../../../../assets/birthday9/images/stones/4.svg");
          width: 22.91%;
          height: 31.17%;
          left: 9.37%;
          top: 0;
          z-index: 1;
        }
        &:nth-child(5) {
          background-image: url("../../../../../../assets/birthday9/images/stones/5.svg");
          width: 34.16%;
          height: 58.23%;
          right: 0;
          bottom: 0;
        }

        &-mask {
          position: absolute;
          width: 61.69%;
          height: 86.71%;
          left: 50%;
          transform: translateX(-50%);
          top: 0;
          mask: url("../../../../../../assets/birthday9/images/stones/mask.svg")
            0 0 / contain no-repeat;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    @include phone {
      #{$this} & {
        margin-bottom: 10px;
      }
    }
  }

  &__desc {
    font-size: 16px;
    line-height: 28px;
    @include phone {
      line-height: 24px;
    }
  }
}
