@import "../../../../../vars";

.birthday-mayor {
  &__inner {
    margin-top: 121px;
  }

  &__light {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: fit-content;
    border-radius: 24px 24px 24px 189.5px;
    background-color: var(--birthday9-light-blue);

    @include medium-tablet() {
      border-radius: 24px;
    }

    @include adaptive(840) {
      flex-direction: column;
    }
  }

  &__blue {
    border-radius: 24px 189.5px 189.5px 189.5px;
    background-color: var(--birthday9-blue);
    height: 380px;
    width: 380px;
    position: relative;
    flex-shrink: 0;

    @include medium-tablet() {
      height: 328px;
      width: 328px;
    }

    @include adaptive(840) {
      margin: 0 auto;
      height: 230px;
      width: 230px;
    }

    img {
      width: 80%;
      height: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;

      @include phone() {
        height: 177px;
        width: 177px;
      }
    }
  }

  &__info {
    margin: 40px;

    @include medium-tablet() {
        margin: 20px 40px 60px;
    }

    @include phone() {
      margin: 20px 20px 30px;
    }
  }

  &__header {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;

    @include phone() {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    @include phone() {
        flex-direction: column;
        margin-top: 20px;
    }

    img:first-child {
        @include phone() {
            display: none;
        }
    }

    img:last-child {
        @include phone() {
            margin-top: 30px;
        }
    }
  }

  &__fio {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    margin-left: auto;
    margin-right: 30px;

    @include phone() {
        margin: 0 auto;
        text-align: center;
    }

    span {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }
}
