.leisure-landing-tooltip {
  .__react_component_tooltip {
    background-color: var(--bright-dusk);
    color: var(--loden-purple);
    font: normal normal 400 14px / normal Montserrat;
    padding: 20px;
    border-radius: 16px;
    opacity: 0.9;
    max-width: 195px;

    &.place-left:after {
      border-left-color: var(--bright-dusk);
    }
    &.place-right:after {
      border-right-color: var(--bright-dusk);
    }
    &.place-top:after {
      border-top-color: var(--bright-dusk);
    }
    &.place-bottom:after {
      border-bottom-color: var(--bright-dusk);
    }
  }
}
