@import "../../../../../vars";

.translations {
    &__cards {
        margin-top: 24px;

        @include phone(){
            margin-top: 16px;
        }
    }
}

.video-viewer-modal__translations {
    .modal__mobile-profile {
        display: none;
    }
}
