@import "../../../../../vars";

.new-year-2023-footer {
  background-color: var(--footer-bg);
  padding: 41px 60px 21px 60px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  position: relative;

  font: normal normal 500 14px / normal Montserrat;

  @include adaptive(1366) {
    padding: 41px 40px 21px 40px;
  }

  @include wide-tablet {
    padding: 41px 20px 21px 20px;
  }

  @include medium-tablet {
    padding: 20px 20px 16px 20px;
    font-size: 10px;
    grid-template-columns: 1fr 1fr;
  }

  @include phone {
    padding: 18px 20px 14px 20px;
  }

  &,
  a {
    color: var(--footer-text);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 17px;
    background-color: var(--footer-top-line);
    border-bottom: 7px solid var(--footer-sub-line);

    @include medium-tablet {
      height: 8px;
      border-bottom-width: 3px;
    }
  }

  &__copy {
    opacity: 0.6;
    padding-right: 20px;
    > span {
      white-space: nowrap;
    }
  }

  &__nav {
    justify-self: center;
    display: flex;
    align-items: center;

    @include medium-tablet {
      display: none;
    }

    > * + * {
      margin-left: 14px;
      @include wide-tablet {
        margin-left: 20px;
      }
    }

    &__btn {
      width: 40px;
      height: 40px;
      position: relative;
      outline: none;
      border: none;
      background: none;
      @include disableSelect;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        mask: url("../../../../../assets/newyear2023/icons/ellipse.svg") 0 0 / contain no-repeat;
        background-color: var(--footer-text);
        border-radius: 50%;
        transition: background-color 0.2s;
      }
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 6px;
        mask: url("../../../../../assets/newyear2023/icons/arrow-down.svg") 0 0 / contain no-repeat;
        background-color: var(--footer-text);
      }
      &:last-child {
        transform: rotate(180deg);
      }
      @include hover {
        &:before {
          mask: none;
        }
        &:after {
          background-color: var(--nato-blue);
        }
      }
    }

    &__text {
      @include wide-tablet {
        display: none;
      }
    }
  }

  &__help {
    justify-self: right;
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 20px;

      @include medium-tablet {
        margin-right: 30px;
      }

      @include phone {
        margin-right: 20px;
      }
    }

    @include phone {
      &__help-link,
      &__rules-link {
        text-indent: -10000px;
        width: 20px;
        height: 20px;
        mask-position: 0 0;
        mask-size: contain;
        mask-repeat: no-repeat;
        background-color: var(--footer-text);
      }
      &__help-link {
        mask-image: url(../../../../../assets/newyear2023/icons/question.svg);
      }
      &__rules-link {
        mask-image: url(../../../../../assets/newyear2023/icons/article.svg);
      }
    }
  }
}
