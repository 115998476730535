@import "../../../../vars";

.infocard {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 24px 16px 16px;
  background-color: #ffffff;
  border-radius: var(--round-corners);
  box-shadow: 0px 2px 10px #0000001a;

  @include tablet() {
    max-height: 355px;
    padding: 16px;
  }

  @include phone() {
    max-height: 386px;
  }

  &_center {
    justify-content: space-between;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    margin: 16px;
    font-size: 36px;
    color: #65aae0;
  }

  &__title {
    font: normal normal bold 14px/19px PT Sans;
    letter-spacing: 1.35px;
    color: #65aae0;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    max-width: max-content;
    &_clickable {
      cursor: pointer;
    }

    @include tablet() {
      font: normal normal bold 13px/19px PT Sans;
    }

    span {
      margin-right: 8px;
    }
  }

  &__text {
    font: normal normal normal 18px/22px PT Sans;
    letter-spacing: 0.17px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;

    @include tablet() {
      font: normal normal normal 16px/20px PT Sans;
      letter-spacing: 0.29px;
    }

    @include phone() {
      font: normal normal normal 16px/24px PT Sans;
      letter-spacing: 0.13px;
      margin-top: 15px;
    }

    &_default {
      align-items: flex-start;
      padding: 50px 0;
      height: 100%;

      @include tablet() {
        padding: 50px 0 20px;
      }
    }

    > a {
      display: contents;
      text-decoration: underline;
    }
  }

  &__subtext {
    font: normal normal normal 18px/22px PT Sans;
    letter-spacing: 0.17px;
    color: #65aae0;
    margin-top: 16px;

    @include tablet() {
      margin-top: 8px;
      font: normal normal normal 16px/20px PT Sans;
      letter-spacing: 0.29px;
    }

    @include phone() {
      margin-top: 12px;
    }
  }

  .cr-button {
    margin-top: auto;

    @include tablet() {
      font: normal normal bold 11px/14px PT Sans;
      letter-spacing: 0.15px;
      margin-top: 16px;
    }

    @include phone() {
      font: normal normal bold 14px/19px PT Sans;
      letter-spacing: 1.35px;
      margin-top: 12px;
    }
  }
}
