.cr-dynamo-list {
  position: relative;
  margin-top: 25px;

  &__inner {
    position: relative;
  }

  &__input {
    position: relative;

    &-icon {
      position: absolute;
      right: 10px;
      top: calc(50% - 11px);

      &-loader {
        position: relative;
        height: 20px;
        width: 20px;
        display: inline-block;
        animation: around 5.4s infinite;

        &::before,
        &::after {
          content: "";
          background: white;
          position: absolute;
          display: inline-block;
          width: 100%;
          height: 100%;
          border-width: 2px;
          border-color: #00aeef #00aeef transparent transparent;
          border-style: solid;
          border-radius: 20px;
          box-sizing: border-box;
          top: 0;
          left: 0;
          animation: around 0.7s ease-in-out infinite;
        }

        &::after {
          animation: around 0.7s ease-in-out 0.1s infinite;
          background: transparent;
        }
      }
    }

    &-invalid {
      border: 2px solid #ff6666 !important;
    }
  }

  &__list {
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 10;
    display: flex;
    flex-direction: column;
    border: 1px solid #dedede;
    bottom: 42px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.058);
    overflow: auto;

    &-item {
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #cceffc;
      }
    }
  }

  &__error {
    padding: 10px 0;
    text-align: center;
    font-size: 15px;
    color: #ff6666;
  }

  &__label {
    font: normal normal normal 16px/28px PT Sans;
    letter-spacing: 0.44px;
    margin-bottom: 6px;

    &-required {
      color: #009dde;
      margin-left: 5px;
    }
  }
}

@keyframes around {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
