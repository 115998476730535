@import "../../../../../../vars";

.quize-game {
  display: flex;
  flex-direction: column;
  width: 822px;
  height: 436px;
  padding: 10px 24px 0;
  color: var(--enokitake-mushroom, #fed);
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;

  &__info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    align-items: center;
  }

  &__question-number {
    font-size: 24px;
    font-weight: 500;
  }

  &__timer {
    font-size: 44px !important;
    font-weight: 700 !important;
  }

  &__question {
    color: var(--enokitake-mushroom, #fed);
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .ScrollbarsCustom-Wrapper {
      width: 100%;
      height: 100%;
    }
    .ScrollbarsCustom-TrackY {
      position: absolute;
      height: 100%;
      width: 6px;
      right: -23px;
    }
    .ScrollbarsCustom-ThumbY {
      border-radius: 4px;
      background: var(--enokitake-mushroom);
    }

    .ScrollbarsCustom-TrackX {
      position: absolute;
      height: 6px;
      width: 100%;
      bottom: 16px;
    }
    .ScrollbarsCustom-ThumbX {
      border-radius: 4px;
      background: var(--enokitake-mushroom);
    }
  }

  &__loader {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &:only-child {
      align-items: center;
    }
  }

  &__answer {
    margin-bottom: 10px;

    button {
      width: 100%;
      background-color: var(--enokitake-mushroom, #fed);
      border-radius: 27px;
      border: none;
      padding: 17px 20px;
      color: var(--nato-blue);
      font-family: Montserrat;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    button:disabled,
    button[disabled] {
      pointer-events: none;
      color: #dedede !important;
    }
  }

  &__answer:last-child {
    margin-bottom: 0;
  }

  &__answer--right {
    button {
      background-color: var(--Green-1, #219653);
    }
  }

  &__answer--wrong {
    button {
      background-color: #ef5f79;
    }
  }

  .data-loader-wr .data-loader {
    display: flex;
    justify-content: center;
  }
}

@include medium-tablet {
  .quize-game {
    width: 100%;
    padding: 0 40px;
    height: 288px;

    &__question-number {
      font-size: 20px;
    }

    &__info {
      width: 50%;
      position: absolute;
      top: 16px;
      left: 40px;
    }

    &__timer {
      font-size: 32px !important;
      position: relative;
      left: 0;
      transform: translateX(-50%);
    }

    &__content {
      margin-top: 55px;
    }

    &__question {
      font-size: 14px;
      line-height: normal;
    }

    &__answer {
      button {
        font-size: 14px;
        padding: 12px 20px;
      }
    }
  }
}
