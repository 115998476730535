@font-face {
  font-family: Golos;
  src: local("Golos"), local("Golos"), url("GolosText-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Golos;
  src: local("Golos"), local("Golos"), url("GolosText-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Golos;
  src: local("Golos"), local("Golos"), url("GolosText-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Golos;
  src: local("Golos"), local("Golos"), url("GolosText-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Golos;
  src: local("Golos"), local("Golos"), url("GolosText-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Golos;
  src: local("Golos"), local("Golos"), url("GolosText-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
