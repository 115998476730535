@import "../../../../../vars";

.new-year-2023-button {
  $icons: ".new-year-2023-header__liftIcon, .new-year-2023-header__loginIcon, .new-year-2023-header__logoutIcon";
  font: normal normal 700 16px / normal Montserrat;
  color: var(--header-button-text);
  padding: 11px 30px;
  border-radius: 27px;
  background-color: var(--header-button-bg);
  border: none;
  display: flex;
  align-items: center;
  z-index: 10;
  transition: 0.2s;

  @media (hover: hover) {
    &:hover {
      background-color: var(--header-button-text);
      color: var(--header-button-bg);
      #{$icons} {
        transition: 0.2s;
        background: var(--header-button-bg);
      }
    }
  }
  &--active {
    background-color: var(--enokitake-mushroom);
    color: var(--nato-blue);
    #{$icons} {
      background: var(--nato-blue);
      @include medium-tablet() {
        background: var(--enokitake-mushroom);
      }
    }
    @media (hover: hover) {
      &:hover {
        background-color: var(--enokitake-mushroom);
        color: var(--nato-blue);
        #{$icons} {
          background: var(--nato-blue);
        }
      }
    }
  }

  @include wide-tablet() {
    font-size: 14px;
  }

  @include medium-tablet() {
    &:hover {
      background-color: transparent;
    }
  }

  &__login {
    padding: 15px;
    @include medium-tablet() {
      color: var(--header-button-bg);
      background-color: transparent;
      padding: 0;
    }
  }
}

.new-year-2023-gameButton {
  padding: 17px 40px;
  background-color: var(--enokitake-mushroom);
  color: var(--nato-blue);
  border-radius: 32px;
  border: none;
  font: normal normal 700 16px / normal Montserrat;
  outline: none;
  line-height: 20px;
  position: relative;

  &--loading {
    color: transparent;
    
    &::after {
      display: block;
      width: 30px;
      height: 30px;
      animation: rotate 0.6s linear infinite;
      border-radius: 100%;
      border: 2px solid #cfcfcf;
      border-right-color: #4c7999;
      content: "";
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
    }
  }
}

.new-year-2023-iconButton {
  padding: 5px 30px 5px 5px;
  justify-content: space-between;
  background-color: transparent;
  color: var(--enokitake-mushroom);
  border-radius: 32px;
  border: 2px solid var(--enokitake-mushroom);
  width: 100%;

  &__content {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 54px;
    height: 54px;
    border-radius: 100%;
    background-color: #00adee;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  &__arrow {
    background-color: var(--enokitake-mushroom);
    mask: url("../../../../../assets/newyear2023/icons/buttonArrow.svg") no-repeat;
    width: 13px;
    height: 8px;
    @include medium-tablet() {
      margin-right: 10px;
    }
  }

  @include medium-tablet() {
    justify-content: flex-start;
    border: none;
    padding: 0px;
  }
}
