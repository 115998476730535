@import "../../../../vars";

.wrapper {
  display: flex;
  gap: 24px;

  @include short-desktop {
    flex-direction: column;
    gap: 16px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  @include short-desktop {
    gap: 12px;
  }

  .text {
    text-align: justify;
  }
}

.text {
  letter-spacing: 0.13px;
  @include short-desktop {
    font: var(--text-body2);
    letter-spacing: 0.13px;
  }
}

.stats {
  display: flex;
  gap: 24px;

  @include short-desktop {
    margin: 0 auto;
  }

  @include adaptive(670) {
    width: 100%;
    justify-content: center;
  }

  @include phone {
    display: flex;
    gap: 16px 12px;
    flex-wrap: wrap;
  }
}
