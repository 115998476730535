@import "../../../../../vars";

.discussion-landing-screen-loader {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 69px;
  background-color: #fff;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 90px;

  @include wide-tablet {
    top: 58px;
  }
  @include tablet {
    gap: 78px;
  }
  @include phone {
    gap: 53px;
  }

  &__logo {
    background: url(../../../../../assets/Logo-b.svg) 0 0 / contain no-repeat;
    width: 156px;
    height: 198px;
    @include tablet {
      width: 107px;
      height: 136px;
    }
    @include phone {
      width: 66px;
      height: 84px;
    }
  }

  &__progress {
    width: 392px;
    height: 16px;
    background: transparent linear-gradient(90deg, #f2f9ff 0%, #e3f0ff 50%, #d4e9ff 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    @include tablet {
      width: 224px;
      height: 12px;
    }
    @include phone {
      width: 140px;
      height: 8px;
    }

    &-bar {
      width: 100%;
      height: 100%;
      background: transparent linear-gradient(90deg, #65aae0 0%, #1473e6 50%, #3c8de2 100%) 0% 0% no-repeat padding-box;
      border-radius: 8px;
      position: absolute;
      right: 100%;
    }
  }
}
