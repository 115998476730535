@import "../../../vars";

.publication-details {
  &__back {
    display: flex;
    align-items: center;
    font: Bold 16px/19px Bebas Neue;
    letter-spacing: 0.12px;
    color: var(--base-text);
    text-decoration: none;
    text-transform: uppercase;
    padding: 26px 0;
    cursor: pointer;

    &:hover {
      color: #22bbf4;
    }

    .ic {
      margin-right: 10px;
      transform: rotate(180deg);
      font-size: 24px;
      height: 24px;
      width: 24px;
      line-height: 24px;
      text-decoration: none !important;
    }
  }

  &__title {
    font: Bold 60px/72px Bebas Neue;
    letter-spacing: -0.5px;
    color: var(--base-text);
    text-transform: uppercase;
    margin: 0;
    word-break: break-word;
  }

  &__info {
    margin-top: 24px;
    display: flex;
    align-items: baseline;

    &-date {
      font: 16px/28px PT Sans;
      letter-spacing: 0.44px;
      color: var(--base-text);
    }

    &-tag {
      font: Bold 14px/18px PT Sans;
      letter-spacing: 1.35px;
      color: var(--base-purple);
      text-transform: uppercase;
      border: 1px solid var(--base-purple);
      padding: 2px 10px;
      margin-left: 36px;
      cursor: pointer;
      border-radius: var(--round-corners-sm);

      &:hover {
        color: white;
        background: var(--base-purple);
      }
    }
  }

  &__img {
    margin-top: 24px;
    width: 100%;
    border-radius: var(--round-corners);
  }

  &__text {
    font: 20px/26px PT Sans;
    letter-spacing: 0.15px;
    color: var(--base-text);
    margin-bottom: 50px;
    margin-top: 24px;
    text-align: justify;
    word-break: break-word;

    img {
      border-radius: var(--round-corners);
    }

    p,
    ul,
    ol {
      font: normal normal normal 20px/32px PT Sans;
      color: var(--base-text);
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    }

    a {
      text-decoration: none;
    }

    h1,
    h2,
    h3,
    h4 {
      color: var(--base-text);
    }

    h1 {
      font: normal normal bold 50px/60px Bebas Neue;
    }

    h2 {
      font: normal normal bold 28px/32px PT Sans;
    }

    h3 {
      font: normal normal bold 28px/36px PT Sans;
    }

    h4 {
      font: normal normal bold 24px/32px PT Sans;
    }
  }

  &__share {
    span {
      font: normal normal normal 18px/20px PT Sans;
      letter-spacing: 0.32px;
      color: var(--base-text);

      @include tablet {
        font-size: 16px;
      }

      @include phone {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.25px;
      }
    }

    .share-icons {
      margin-top: 12px;
      .ic {
        margin-right: 16px;
      }
    }
  }

  em,
  i {
    font-style: italic !important;
  }

  b,
  strong {
    font-weight: bold !important;
  }
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 10px;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .publication-details {
    &__back {
      font: Bold 24px/29px Bebas Neue;

      .ic {
        margin-right: 20px;
        font-size: 20px;
        height: 20px;
        width: 20px;
      }
    }

    &__title {
      font: Bold 50px/60px Bebas Neue;
    }
  }
}

@media only screen and (max-width: 500px) {
  .publication-details {
    &__title {
      font: Bold 30px/34px Bebas Neue;
      letter-spacing: -0.33px;
    }
  }
}
