@import "../../../vars";

#crowd-birthday-10 {
  background: transparent;
  padding: 0 40px 0 40px;

  @include wide-tablet {
    padding: 0 24px 0 24px;
  }

  @include phone {
    padding: 0 10px 0 10px;
  }
}

#crowd-birthday-10-screen-stub {
  z-index: 1001;

  .crowd-birthday-10-modal {
    max-width: 304px;
  }
}

.crowd-birthday-10 {
  $this: &;

  &-modal {
    width: 100%;

    margin-top: 88px;
    @include wide-tablet {
      margin-top: 80px;
    }

    @include adaptive(916) {
      margin-top: 51px;
    }

    &--left {
      margin: 88px auto auto 0px;
      @include wide-tablet {
        margin-top: 80px;
      }
      @include adaptive(916) {
        margin-top: 51px;
      }
    }

    &--right {
      margin: 88px 0px auto auto;
      @include wide-tablet {
        margin-top: 80px;
      }
      @include adaptive(916) {
        margin-top: 51px;
      }
    }

    max-width: 760px;
    background: radial-gradient(50% 62.69% at 50% 50%, #ffffff 0%, #eff6ff 71.71%, #d9e8f9 100%);
    background-blend-mode: color-dodge;
    border-radius: 24px;

    padding: 32px 40px 24px;

    color: #404040;
    font-family: PT Sans;

    @include wide-tablet {
      max-width: 430px;
      padding: 16px 22px;
      border-radius: 14px;
    }

    @include phone {
      max-width: 100%;
    }

    .modal__close-btn {
      top: 16px;
      right: 16px;
      @include wide-tablet {
        top: 8px;
        right: 8px;
      }

      .promo-icon-14 {
        font-size: 24px;
        @include wide-tablet {
          font-size: 14px;
        }
      }
    }

    a {
      color: #0072cb;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @include wide-tablet {
        gap: 14px;
      }
    }

    &__title {
      font: normal normal 700 26px/34px PT Sans;

      @include wide-tablet {
        font-size: 15px;
        line-height: 19px;
      }
    }

    &__text {
      font: normal normal 400 20px/26px PT Sans;
      text-align: start;

      @include wide-tablet {
        font-size: 11px;
        line-height: 15px;
      }
    }

    &__buttons {
      margin: 0 auto;
      display: flex;
      gap: 24px;

      @include wide-tablet {
        gap: 14px;
      }
    }
  }

  &-button {
    width: 100%;
    cursor: pointer;
    .cr-button {
      width: 100%;
      padding: 6px 55px;
      text-transform: none;
      color: #fff;
      font: normal normal 700 20px/26px PT Sans;
      background: radial-gradient(57.53% 106.25% at 46.12% 100%, #65aae0 0%, #026abc 100%);
      border-radius: 28px;
      border: none;

      @include wide-tablet {
        font-size: 11px;
        line-height: 14px;
        padding: 4px 31px;
      }
    }

    &:hover,
    &:active {
      .cr-button {
        background: radial-gradient(887.31% 243.75% at 46.55% 100%, #65aae0 0%, #026abc 100%);
      }
    }

    border-radius: 28px;
    display: inline-flex;
    padding: 4px 8px;

    @include wide-tablet {
      padding: 1px 3px;
    }

    background: rgba(101, 170, 224, 1);

    &__border {
      width: 100%;
      border-radius: 28px;
      display: inline-flex;
      padding: 1px;
      background: linear-gradient(131.33deg, #468abe 27.6%, #026bbd 82.59%);
    }

    &--outlined {
      .cr-button {
        padding: 10px 56px;

        @include wide-tablet {
          padding: 5px 20px;
        }

        background: #fff;
        color: rgba(101, 170, 224, 1);
      }
      .crowd-birthday-10-button__border {
        padding: 0;
        background: none;
      }
      padding: 1px 8px;

      @include wide-tablet {
        padding: 1px 4px;
      }

      &:hover,
      &:active {
        background: rgba(0, 114, 203, 1);
        .cr-button {
          color: rgba(0, 114, 203, 1);
          background: rgba(182, 214, 242, 1);
        }
      }

      background: linear-gradient(90deg, #5895c7 0%, #65aae0 34.21%, #65aae0 66.05%, #528cbd 100%);
    }
  }

  & #{&}-map {
    width: 100%;
    height: 100%;

    @supports (height: 100dvh) {
      height: 100dvh;
    }
  }

  &_mobile-device {
    #{$this}-pin,
    #{$this}-pin-question {
      transform-origin: center bottom;
      transform: translate(-50%, -100%) scale(0.5);
    }
  }
}

.completed-sphere-modal,
.incorrect-answer-modal,
.correct-answer-modal {
  .crowd-birthday-10-modal__buttons > * {
    width: 190px;

    @include wide-tablet {
      width: 107px;
    }
  }
}

.expert-game-win-modal {
  .crowd-birthday-10-button {
    width: 190px;
    margin: 0 auto;

    @include wide-tablet {
      width: 107px;
    }
  }
}

.crowd-birthday-10-scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ScrollbarsCustom-Wrapper {
    width: 100%;
    height: 100%;
  }

  .ScrollbarsCustom-TrackY {
    position: absolute;
    height: 100%;
    width: 10px;
    right: -16px;
    background: #d2d2de;
    border-radius: 10px;
  }

  .ScrollbarsCustom-ThumbY {
    margin: 0 auto;
    width: 100%;
    border-radius: 10px;
    border: 2px solid #d2d2de;
    background: #65aae0;
  }
}
