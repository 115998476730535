@import "../../../../../vars";

.idea-card {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  position: relative;
  z-index: 1;
  padding: 16px;
  cursor: pointer;

  @include tablet() {
    padding: 12px;
  }

  @include phone() {
    padding: 8px;
  }

  img.bg {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
  }

  &,
  img.bg {
    border-radius: var(--corners-l);

    @include tablet() {
      border-radius: var(--corners-m);
    }

    @include phone() {
      border-radius: var(--corners-s);
    }
  }

  .like {
    align-self: flex-end;
    @include phone() {
      transform: translate(-4px, 4px);
    }
  }

  .info {
    border-radius: var(--corners-m);
    width: 100%;
    padding: 16px;
    background-color: #00000055;
    transform: translateY(0);
    opacity: 1;
    transition: all 0.3s;

    @supports not (-webkit-touch-callout: none) {
      backdrop-filter: blur(5px);
    }

    @include tablet {
      border-radius: var(--corners-s);
      padding: 12px;
    }

    @include phone {
      border-radius: var(--corners-xs);
    }

    &_hidden {
      transform: translateY(16px);
      opacity: 0;
      pointer-events: none;
    }

    .author {
      cursor: pointer;
      display: flex;
      align-items: baseline;
      align-self: flex-start;
      width: max-content;

      &__name {
        color: white;
        letter-spacing: 0.1px;
      }

      &:hover {
        .author__name {
          text-decoration: underline;
        }
      }

      :global(.user-name-hidden-icon) {
        font-size: 14px;
        color: white;
      }
    }

    .idea-name {
      font-weight: 600;
      letter-spacing: 0.11px;
      color: white;
      margin-top: 8px;

      @include tablet {
        margin-top: 4px;
      }
    }

    .bottom {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      @include tablet {
        margin-top: 12px;
      }

      @include phone {
        margin-top: 8px;
      }

      .tag {
        border-radius: var(--corners-xs);
        background-color: #ffffff29;
        padding: 4px 16px 5px;

        @include phone {
          padding: 4px 12px 5px;
        }

        &:hover {
          opacity: 0.8;
        }

        &__text {
          color: white;
          font-weight: 500;
          letter-spacing: 0.1px;
        }
      }

      .share {
        width: 24px;
        height: 24px;
        > * {
          transform: scale(0.75);
          transform-origin: 0 0;
        }
      }
    }
  }
}
