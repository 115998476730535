@import "../../../vars";

#modal-overlay > div {
  @include phone() {
    padding-top: 0;

    .modal__mobile-profile {
      display: none;
    }
  }
}

.discussion-landing {
  flex: 1;
  display: flex;
  flex-direction: column;

  > *:not(:first-child) {
    margin-top: 40px;
    @include wide-tablet {
      margin-top: 36px;
    }
    @include phone {
      margin-top: 32px;
    }

    &.discussion-landing-experts,
    &.dls,
    &.discussion-landing-activities,
    &.translations,
    &.discussion-landing-reviews,
    &.discussion-landing-photo-report {
      margin-top: 32px;
      @include phone {
        margin-top: 20px;
      }
    }
  }

  &_hidden {
    display: none;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &-welcome {
    position: relative;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1224px;
  }

  &-title {
    font: normal normal bold 44px/54px Montserrat;
    text-transform: uppercase;
    color: var(--base-text);
    @include wide-tablet {
      font-size: 32px;
      line-height: 39px;
    }
    @include phone {
      font-size: 24px;
      line-height: 29px;
    }
  }

  &-subtitle {
    font: normal normal bold 36px/44px Montserrat;
    color: var(--base-text);
    @include wide-tablet {
      font-size: 28px;
      line-height: 34px;
    }
    @include phone {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &-text {
    font: normal normal 500 24px/36px Montserrat;
    color: var(--base-text);

    @include wide-tablet {
      font-size: 20px;
      line-height: 24px;
    }
    @include phone {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .slider-arrows {
    padding-bottom: 0 !important;
    padding-top: 24px !important;

    @include wide-tablet {
      padding-top: 16px !important;
    }

    @include phone() {
      padding-top: 20px !important;
    }

    .ic {
      font-size: 24px;
      margin: 0 12px;
    }
  }

  .common-slider__footer {
    min-height: 0;
  }

  &__wrapper.wrapper {
    padding-left: 24px;
    padding-right: 24px;
    @include phone {
      padding-left: 20px;
      padding-right: 20px;
    }

    footer {
      margin-top: 92px;
      margin-bottom: 12px;
      padding: 0;
      @include wide-tablet {
        margin-top: 96px;
        margin-bottom: 16px;
      }
      @include phone {
        margin-top: 60px;
        margin-bottom: 20px;
      }

      .footer__link {
        .link-divider {
          padding-left: 16px;
          margin-right: 16px;

          @include wide-tablet() {
            padding-left: 12px;
            margin-right: 12px;
          }

          @include phone() {
            padding-left: 0;
            margin-right: 0;
          }
        }
      }

      .footer__copyright_mobile {
        padding-bottom: 20px;
      }
    }
  }

  ol {
    padding-left: 35px;
    margin: 16px 0;
    list-style-type: none;
    counter-reset: item;
    &:first-child {
      margin-top: 0;
    }
    > li {
      margin: 0;
      position: relative;

      ul {
        margin: 0;
      }

      &:before {
        content: counters(item, ".") ".";
        counter-increment: item;
        color: #009dde;
        position: absolute;
        left: -16px;
        transform: translateX(-50%);
        font-weight: bold;
      }
    }
    &:only-child {
      margin-top: 0px;
    }
  }
  ul {
    padding-left: 35px;
    margin: 16px 0;
    list-style: none;
    &:first-child {
      margin-top: 0;
    }
    li {
      margin: 0;
      position: relative;
      &:after {
        content: "";
        background: #009dde;
        border-radius: 100%;
        width: 5px;
        height: 5px;
        display: block;
        position: absolute;
        top: 9px;
        left: -16px;
      }
    }
    &:only-child {
      margin-top: 0px;
    }
  }

  blockquote {
    background: url("../../../assets/discussion-landing/icon-blockquote.svg") 0
      0 no-repeat;
    margin: 25px 0;
    margin-left: 18px;

    & > * {
      margin-left: 50px;
      padding-left: 20px;
      border-left: 2px solid #8a8a8a;
    }

    &:not(:has(*)) {
      position: relative;
      padding-left: 70px;
      @include phone() {
        padding-left: 48px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50px;
        width: 1px;
        height: 100%;
        background: #8a8a8a;
        @include phone {
          left: 36px;
        }
      }
    }

    @include phone() {
      font: normal normal normal 16px/19px Montserrat;
      margin: 20px 0;
      background-size: 24px 19px;

      p {
        padding-left: 12px;
        margin-left: 36px;
      }
    }
  }

  .cr-tooltip .__react_component_tooltip {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: none;
    background: #337ab7;
    border: 1px solid transparent;
    border-radius: 3px;
    font:
      normal normal normal 13px/15px Helvetica,
      Arial,
      sans-serif;
    opacity: 0.9 !important;
    padding: 8px 21px;
    &:before,
    &:after {
      content: "";
    }
  }
}

.cr-header {
  $root: &;
  &__culture {
    .cr-header__inner-links {
      white-space: nowrap;
      @include desktop-only {
        margin-left: 56px;
      }
    }

    .cr-header__inner {
      @include desktop-only {
        min-height: 80px;
        padding: 0 8px 0 32px;
      }
    }
    .cross-promo {
      display: none;
    }
    .cr-header__inner-profile {
      margin-right: 10px;
    }

    #{$root}__home {
      display: flex;
      align-items: center;

      @include wide-tablet() {
        order: 1;
        display: flex;
        flex-direction: row-reverse;

        a {
          display: flex;
          align-items: center;
        }
      }

      @include phone() {
        align-items: center;
      }

      .cross-promo__divider {
        height: 32px;
        @include wide-tablet() {
          margin-right: 0;
        }

        @include phone() {
          display: block;
          margin-left: 18px;
          margin-right: -2px;
        }
      }
    }
  }
}

.discussion-landing-modal {
  .modal__mobile-profile {
    display: none;
  }
}

.discussion-landing-scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ScrollbarsCustom-Wrapper {
    width: 100%;
    height: 100%;
  }

  .ScrollbarsCustom-TrackY {
    position: absolute;
    height: 100%;
    width: 6px;
    right: -8px;
    background: #2828281a;
    border-radius: 3px;
  }

  .ScrollbarsCustom-ThumbY {
    border-radius: 3px;
    background: #2828284d;
  }
}
