.interactive {
  $root: &;

  pointer-events: all;
  cursor: pointer;
  width: max-content;
  position: relative;

  &__wrapper-map {
    position: absolute;
    pointer-events: all;
    z-index: 1;
  }

  &__border {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: white;
  }

  &_active#{&}_fact {
    #{$root}__border {
      background-color: #88d16e;
    }
  }

  &_active#{&}_game {
    #{$root}__border {
      background-color: #ffcb54;
    }
  }

  &_disabled {
    pointer-events: none;
  }
}
