@import "../../../../../../vars";

.birthday-header-mobile {
  display: none;
  position: relative;
  margin-left: 12px;

  @include wide-tablet() {
    display: block;
  }

  &__list {
    font-family: "Montserrat";
    width: max-content;
    min-width: 314px;
    position: absolute;
    right: 0px;
    top: 60px;
    flex-direction: column;
    background: white;
    padding: 15px;
    box-shadow: 0px 0px 20px rgba(37, 37, 38, 0.2);
    border-radius: 18px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  &__link {
    cursor: pointer;
    a {
      text-decoration: none;
      color: var(--birthday9-black);
      width: 100%;
      display: block;
      padding: 15px;

      &:hover {
        background-color: var(--birthday9-light-blue);
        border-radius: 12px;
      }
    }
  }

  &__close {
    background-color: var(--base-blue);
    color: white;
    outline: none;
    border: none;
    padding: 8px;
    border-radius: 12px;
  }

  .divider {
    margin: 15px;
    height: 1px;
    overflow: hidden;
    background: #e9ecef;
  }
}
