@import "../../../../../vars";

.cr-search-list__text {
  padding: 10px;
  cursor: pointer;
  font: normal normal bold 14px/18px PT Sans;
  letter-spacing: 1.35px;
  color: var(--base-blue);
  text-transform: uppercase;
}

.cr-search-list {
    $root: &;

    outline: none;
    position: absolute;
    padding: 24px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid #dedede;
    bottom: 42px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.058);
    top: 46px;
    right: 0;
    z-index: 99;
    height: fit-content;
    max-height: 540px;
    overflow: auto;
    border-radius: var(--round-corners);

    @include scrollbar(7px);

    @include tablet() {
      width: 150%;
    }

    @include phone() {
        right: -24px;
        width: 93vw;
    }

    &__item {
      @extend .cr-search-list__text;
      border-radius: var(--round-corners-sm);
    }

    &__group {
      $group: &;
      @extend .cr-search-list__text;
      padding: 0;
      width: 100%;

      &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 12px;
        border-radius: var(--round-corners-sm);

        &:hover {
          background-color: var(--base-ligth-blue);
        }
      }

      &-items {
        display: flex;
        flex-wrap: wrap;
        height: 0;
        overflow: hidden;
        padding: 0px 12px 5px;
      }

      .arrow-icon {
        font-size: 14px;
        width: 14px;
        height: 14px;
        transform: rotate(90deg);
        transition: transform 0.3s;
      }

      &--expanded {
        .arrow-icon {
          transform: rotate(270deg);
        }

        #{$group}-title {
          background-color: var(--base-blue);
          color: white;
        }

        #{$group}-items {
          height: 100%;
        }
      }
    }

    &__dots {
      font-weight: bold;
      width: 100%;
      font-size: 26px;
      font-family: serif;
      text-align: center;
      height: 26px;
      cursor: pointer;
    }

    &--empty {
      display: none;
    }

    &--mutiplePerRow {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
    
        #{$root}__item {
          padding: 4px 12px;
          letter-spacing: 1.35px;
          color: var(--base-blue);
          text-transform: uppercase;
          border: 1px solid var(--base-blue);
          margin-right: 12px;
          margin-top: 12px;
          white-space: nowrap;
          width: min-content;
          text-overflow: ellipsis;
          overflow: hidden;
    
          &--selected {
            background-color: var(--base-blue);
            color: white;
          }
        }
      }

}