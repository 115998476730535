@import "../../../../../../vars";

.tags-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: var(--round-corners);
  background: var(--base-light) 0 0 no-repeat padding-box;
  cursor: pointer;
  overflow: hidden;

  .video,
  .image {
    height: 104px;
    width: 184px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--corners-xs);
    overflow: hidden;
    flex-shrink: 0;
    z-index: 0;

    @include wide-tablet() {
      width: 100%;
      height: 126px;
      border-radius: var(--corners-xxs);
    }
    @include phone() {
      width: 100%;
      min-height: 156px;
      height: auto;
      aspect-ratio: 280/156;
      border-radius: var(--corners-s);
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    video {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .video > :first-child {
    height: 100% !important;
    background-color: transparent !important;
  }
}

.main-card {
  height: 100%;
  border-radius: var(--corners-l) var(--corners-l) var(--corners-s)
    var(--corners-s);
  overflow: hidden;
  grid-row-start: 1;
  grid-row-end: 5;

  @include wide-tablet() {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-column-gap: 24px;
  }

  .link-wrapper {
    gap: 0px;
  }

  .text-wrapper {
    height: 100%;
    width: 100%;
    padding: 0 20px;
    padding-top: 20px;
    gap: 12px;
    border: 1px solid #dedede;
    border-radius: 0 0 var(--corners-s) var(--corners-s);
  }

  .image,
  .video {
    height: 336px;
    width: 100%;
    border-radius: var(--corners-l) var(--corners-l) 0px 0px;
    flex-shrink: 0;
  }

  .image {
    position: relative;
  }

  @include wide-tablet() {
    height: 403px;
    border: none;
    border-radius: var(--corners-l);

    .image,
    .video {
      height: 100%;
      border-radius: 0;
    }

    .text-wrapper {
      height: auto;
      $indent: 16px;
      position: absolute;
      bottom: $indent;
      left: $indent;
      width: calc(100% - 2 *#{$indent});
      border: 1px solid #dedede;
      background-color: var(--base-light);
      padding: $indent;
      gap: 12px;
      border-radius: var(--corners-s);

      .details {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .link-wrapper {
    flex-direction: column;
  }
}

.link-wrapper {
  height: 100%;
  position: relative;
  display: flex;
  gap: 24px;

  @include wide-tablet {
    flex-direction: column;
    gap: 12px;
  }

  @include phone() {
    gap: 0;
    height: auto;
    min-height: 284px;
    padding-bottom: 128px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background-color: var(--base-light);

  @include wide-tablet() {
    padding: 0;
    gap: 8px;
  }

  @include phone() {
    position: absolute;
    z-index: 10;
    bottom: 12px;
    left: 8px;
    width: calc(100% - 16px);
    height: 147px;
    margin: 0 auto;
    border: 1px solid #dedede;
    border-radius: var(--corners-xxs);
    padding: 12px;
    gap: 8px;
  }

  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    @include wide-tablet {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    .tag-wrapper {
      display: flex;
    }

    .tag {
      padding: 4px 16px;
      border-radius: var(--corners-xs);
      outline: 1px solid var(--tags-card-color);
      outline-offset: -1px;
      white-space: nowrap;
      letter-spacing: 0.1px;
      color: var(--tags-card-color);
      cursor: pointer;
      overflow: hidden;
      max-width: max-content;
      transition: all 0.3s ease;

      &:hover {
        color: white;
        background: var(--tags-card-color-hover);
      }

      .activity__tag {
        &.active {
          color: white;
          background: var(--tags-card-color-hover);
        }
      }
    }

    .date {
      letter-spacing: 0.25px;
      color: var(--base-text-secondary);
      line-height: 20px;

      @include wide-tablet {
        letter-spacing: 0.21px;
      }
    }
  }

  .title {
    color: var(--base-dark-gray);
    letter-spacing: 0.13px;
    font-weight: 600;
    overflow: hidden;

    @include tablet() {
      letter-spacing: 0.11px;
    }
  }
}

.play-icon {
  height: 48px;
  width: 48px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../../../../../../assets/video-play-icon.svg") 0 0 / contain
    no-repeat;

  @include wide-tablet {
    height: 32px;
    width: 32px;
  }
}
