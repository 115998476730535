@import "../../../../../vars";

.new-year-2023 {
  $root: &;

  --footer-height-base: 102px;
  --footer-height-mobile: 56px;
  --footer-height-current: var(--footer-height-base);
  @include medium-tablet {
    --footer-height-current: var(--footer-height-mobile);
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  &-header {
    z-index: 4;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
  }

  &-footer {
    z-index: 2;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: var(--footer-height-current);
  }

  &__logoLinkWrapper {
    width: 143px;
    height: 126px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(var(--scale) * var(--footer-height-base))) scale(var(--scale));
    transform-origin: center bottom;
    z-index: 5;
    bottom: var(--footer-height-current);
    pointer-events: none;

    @media (max-aspect-ratio: 452/320) {
      transform: translate(-50%, calc(100vh - (100vh - var(--footer-height-current) + 100vh * 26 / 320)))
        scale(var(--scale));
    }

    @media (max-aspect-ratio: 1024/768) {
      transform: translate(-50%, 0) scale(var(--scale));
    }
  }

  &__logoLink {
    display: block;
    width: 100%;
    height: 126px;
    transform: translate(-28px, -785px);
    pointer-events: all;
  }

  &__swiper {
    $swiper: &;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: var(--footer-height-current);

    .swiper-wrapper {
      // fix safari 16 flickering
      -webkit-transform-style: preserve-3d;

      transition: transform 0.9s ease 0s !important;

      .swiper-slide {
        // fix safari 16 flickering
        -webkit-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);

        overflow: hidden;

        #{$swiper}__object {
          position: absolute;
          bottom: 0;
          left: 50%;
          @include disableSelect;
          transform: translate(-50%, calc(var(--scale) * var(--footer-height-base))) scale(var(--scale));
          transform-origin: center bottom;
          pointer-events: none;

          @media (max-aspect-ratio: 452/320) {
            transform: translate(-50%, calc(100vh - (100vh - var(--footer-height-current) + 100vh * 26 / 320)))
              scale(var(--scale));
          }

          @media (max-aspect-ratio: 1024/768) {
            transform: translate(-50%, 0) scale(var(--scale));
          }

          &__inner {
            display: flex;
            width: max-content;
          }
        }
      }
    }
  }

  .modal-welcome {
    @include medium-tablet {
      margin-top: calc(var(--footer-height-mobile) / 2);
    }
  }

  .screen-loader {
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease-in 0.5s;
  }

  &_loaded {
    .screen-loader {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
  }
}
