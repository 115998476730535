@import "../../../../../vars";

.crowd-birthday10-screen-loader {
  &--not-ready > * {
    visibility: hidden;
  }

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
  background-color: #64a4d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__content {
    flex-shrink: 0;
    margin: 76px 0 55px 0;

    @include medium-tablet {
      margin: 22px 0;
    }
  }

  &__header {
    display: flex;
    width: 100%;
    max-width: 313px;
    margin-bottom: 24px;

    @include wide-tablet {
      max-width: 314px;
    }

    @include phone {
      max-width: 324px;
    }
  }

  &__logo {
    margin-right: 20px;
  }

  &__title {
    font: normal normal 700 36px/36px Bebas Neue;
    color: #ffffff;
    margin-top: 9px;
    margin-right: -2px;
  }

  &__load-text {
    font: normal normal 700 20px/20px Bebas Neue;
    color: #ffffff;
    text-align: center;
  }

  &__progress {
    width: 100%;
    height: 18px;
    border-radius: 27px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;

    @include wide-tablet {
      height: 16px;
    }

    &:before {
      content: "";
      position: absolute;
      border-radius: 27px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid #c2d6ff;
      background: #dceeff;
    }

    &-bar {
      position: absolute;
      right: 100%;
      width: 100%;
      height: 100%;
      border-radius: 27px;
      background: linear-gradient(131.33deg, #468abe 27.6%, #026bbd 82.59%);

      &:before {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        background: radial-gradient(82.81% 153.02% at 49.44% 146.77%, #65aae0 0%, #026abc 100%);
        border-radius: 27px;
      }
    }
  }

  &__trees {
    width: 100%;
    display: flex;

    &_top {
      padding: 0 35px;
      @include wide-tablet {
        padding: unset;
      }
    }

    &_bottom {
      padding: 0 16px 0 14px;
      @include wide-tablet {
        padding: unset;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}
