@import "../../../../vars";

.cr-file-selector {
  &__head {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 24px;

    &-button {
      border: 2px solid var(--base-blue);
      font: normal normal bold 14px/18px var(--font-family-primary);
      letter-spacing: 1.35px;
      color: var(--base-blue);
      text-transform: uppercase;
      padding: 11px 24px;
      white-space: nowrap;
      cursor: pointer;
      border-radius: var(--round-corners);

      &:hover {
        background: rgba(0, 174, 239, 0.1);
        transition: all 0.2s;
      }

      &:active {
        box-shadow: none;
        background-color: #65aae0;
        border-color: transparent;
        color: #ffffff;
      }

      &.error {
        border: 2px solid $error;
      }
    }

    &-text {
      margin-left: 24px;
      font: 14px/20px var(--font-family-primary);
      letter-spacing: 0.25px;
      color: #808080;
    }

    > input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }

  &__files {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    &-preview {
      max-width: 100px;
    }
  }

  &__icon {
    position: absolute;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;
    pointer-events: none;

    &-loader {
      position: relative;
      height: 30px;
      width: 30px;
      display: inline-block;
      animation: around 5.4s infinite;

      &::before,
      &::after {
        content: "";
        background: white;
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 100%;
        border-width: 2px;
        border-color: #00aeef #00aeef transparent transparent;
        border-style: solid;
        border-radius: 20px;
        box-sizing: border-box;
        top: 0;
        left: 0;
        animation: around 0.7s ease-in-out infinite;
      }

      &::after {
        animation: around 0.7s ease-in-out 0.1s infinite;
        background: transparent;
      }
    }
  }
}
