@import "../../../vars";

.photos {
  &__instructions {
    .page-title {
      @include tablet {
        font: normal normal bold 50px/60px var(--font-family-primary);
        letter-spacing: -0.42px;
      }

      @include phone {
        font: normal normal bold 40px/40px var(--font-family-primary);
        letter-spacing: -0.33px;
      }
    }

    .page-description {
      font: normal normal normal 20px/28px var(--font-family-primary);
      letter-spacing: 0.16px;
      margin-bottom: 24px;

      @include tablet {
        font: normal normal normal 18px/24px var(--font-family-primary);
        letter-spacing: 0.15px;
      }

      @include phone {
        font: normal normal normal 16px/24px var(--font-family-primary);
        letter-spacing: 0.13px;
      }
    }

    > .cr-button {
      @include tablet() {
        font: normal normal bold 11px/14px var(--font-family-primary);
        letter-spacing: 0.15px;
      }

      @include phone() {
        font: normal normal bold 14px/19px var(--font-family-primary);
        letter-spacing: 1.35px;
      }
    }

    &-list {
      margin-top: 32px;

      @include tablet {
        margin-top: 24px;
      }

      @include phone {
        margin-top: 20px;
      }

      &__item {
        $item: &;

        padding: 84% 32px 20px;
        border: 1px solid;
        border-radius: var(--round-corners);
        background-position: center 20px;
        background-repeat: no-repeat;
        background-size: calc(100% - 64px);

        @include tablet {
          padding: 96% 12px 20px;
          background-size: calc(100% - 24px);
        }

        &__step {
          color: white;
          padding: 2px 8px 2px;
          border-radius: 19px;
          font: normal normal bold 16px/18px var(--font-family-primary);
          letter-spacing: 0.12px;
          width: max-content;

          @include phone {
            font: normal normal bold 14px/17px var(--font-family-primary);
            letter-spacing: 0.1px;
          }
        }

        &__title {
          font: normal normal bold 30px/37px var(--font-family-primary);
          letter-spacing: 0.12px;
          color: var(--base-text);
          margin-top: 20px;

          @include tablet {
            font: normal normal bold 20px/24px var(--font-family-primary);
            letter-spacing: 0.15px;
          }
        }

        &__text {
          font: normal normal normal 20px/28px var(--font-family-primary);
          margin-top: 12px;

          @include tablet {
            font: normal normal normal 18px/24px var(--font-family-primary);
            letter-spacing: 0.15px;
          }

          @include phone {
            margin-top: 8px;
            font: normal normal normal 16px/24px var(--font-family-primary);
            letter-spacing: 0.13px;
          }
        }

        &-photo {
          border-color: #54c3f666;
          background-image: url("../../../assets/photos/insructions-step-1.svg");

          #{$item}__step {
            background-color: #54c3f666;
          }
        }

        &-upload {
          border-color: #ffc38d99;
          background-image: url("../../../assets/photos/insructions-step-2.svg");

          #{$item}__step {
            background-color: #ffc38d99;
          }
        }

        &-bonus {
          border-color: #a69cef66;
          background-image: url("../../../assets/photos/insructions-step-3.svg");

          #{$item}__step {
            background-color: #a69cef66;
          }
        }
      }
    }

    .common-slider__footer {
      min-height: unset;
    }
  }

  &__ideas {
    position: relative;

    &-title {
      @extend %section-title;
      padding: 36px 0 24px;

      @include tablet {
        padding-top: 32px;
      }

      @include phone() {
        padding: 15px 0 20px;
      }
    }

    &-list {
      .swiper-container {
        padding: 4px;
        margin: -4px;
      }

      .slider-arrows .ic {
        margin: 0 4px;
      }
    }

    &-text {
      margin-top: 16px;
      background-color: #f6f5ff;
      border: 1px solid #a69cef66;
      border-radius: var(--round-corners);
      padding: 17px 104px 17px 32px;
      font: normal normal normal 20px/28px var(--font-family-primary);
      letter-spacing: 0.16px;
      color: var(--base-text);

      @include tablet {
        margin-top: 6px;
        padding: 18px 12px 17px;
        font: normal normal normal 18px/24px var(--font-family-primary);
        letter-spacing: 0.15px;
      }

      @include phone {
        margin-top: 10px;
        font: normal normal normal 16px/24px var(--font-family-primary);
        letter-spacing: 0.13px;
      }
    }
  }

  &-strong {
    font-weight: bold;
  }

  &-blue:link {
    font-weight: bold;
    color: var(--base-blue);
    cursor: pointer;
    text-decoration: none;
  }

  .swiper-slide {
    height: auto;
    min-height: 100%;
    max-height: 100%;
    > * {
      &,
      > *,
      .photo-card {
        height: 100%;
      }
    }
  }
}
