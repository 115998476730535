@import "../../../../../vars";

.photo-report {
  &__title {
    text-align: left;
    font: normal normal bold 28px/34px Montserrat;
    letter-spacing: 0px;
    color: #282828;
    margin-bottom: 32px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;

    @include wide-tablet {
      font-size: 20px;
      line-height: 24px;
    }

    @include phone {
      margin-bottom: 20px;
    }

    @include phoneLandscape {
      margin-bottom: 20px;
    }
  }

  &__modal {
    max-width: 1024px !important;
    padding: 48px;

    @include phoneLandscape {
      padding: 28px;
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: calc(100vh - 238px);
    height: 614px;

    @include wide-tablet {
      height: 360px;
    }

    @include phoneLandscape {
      max-height: calc(100vh - 156px);
    }

    @include phone {
      height: 174px;
    }
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 614px;
    max-height: calc(100vh - 238px);
    position: relative;
    user-select: none;

    @include wide-tablet {
      height: 360px;
    }

    @include phoneLandscape {
      max-height: calc(100vh - 156px);
    }

    @include phone {
      height: 174px;
    }

    img {
      object-fit: cover;
      max-width: 100%;
      height: 100%;
    }
  }

  &__slider {
    position: relative;

    .common-slider__footer {
      position: unset;
      min-height: unset;

      .slider-arrows {
        position: unset;
        padding: 0;

        &-left {
          left: -25px;
        }
        &-right {
          right: -25px;
          .ic {
            right: 0;
          }
        }

        &-left,
        &-right {
          position: absolute;
          width: 50px;
          height: 100%;
          top: 0;
          z-index: 1;
          cursor: pointer;

          .ic {
            margin: 0;
            color: $black-text;
            position: absolute;
            width: 40px;
            height: 40px;
            top: 50%;
            transform: translateY(-50%);
            background: #ffffff;
            box-shadow: 0px 2px 10px #0000001a;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &:hover .ic {
            color: $gray;
          }
        }
      }
    }
  }
}
