.christmas-bulbs {
  pointer-events: all;

  &__bulb {
    cursor: pointer;
    &--active {
      animation: bulbShake 0.8s ease-in-out;
    }
  }

  @keyframes bulbShake {
    0%,
    100% {
      transform: translateX(0);
    }
    10%,
    30% {
      transform: translateX(-8px);
    }
    20%,
    40% {
      transform: translateX(8px);
    }
    50% {
      transform: translateX(-6px);
    }
    60% {
      transform: translateX(6px);
    }
    70% {
      transform: translateX(-4px);
    }
    80% {
      transform: translateX(4px);
    }
    90% {
      transform: translateX(-2px);
    }
  }
}
