@import "../../../vars";

.ideas {
  width: 100%;
  height: 100%;

  &__search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    .cr-button {
      @include tablet() {
        font: normal normal bold 11px/14px var(--font-family-primary);
        letter-spacing: 0.15px;
      }
    }

    .cr-search {
      max-width: 392px;

      @include tablet() {
        max-width: 220px;
      }
    }
  }

  &__count {
    font: normal normal bold 20px/28px var(--font-family-primary);
    font-weight: bold;
    white-space: nowrap;
  }

  &__tags {
    margin-top: 25px;

    &-list {
      display: flex;
      flex-wrap: wrap;

      &-tag {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: 0 10px 10px 0;
        padding: 2px 10px;
        border: 1px solid #009dde;
        white-space: nowrap;
        font: normal normal bold 14px/18px var(--font-family-primary);
        letter-spacing: 1.35px;
        text-transform: uppercase;
        color: #009dde;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: var(--round-corners-sm);

        &-text {
          max-width: 260px;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .promo-icon-14 {
          font-size: 14px;
          width: 14px;
          height: 14px;
          margin-left: 10px;
        }

        &.activity__tag {
          &:hover,
          &.active {
            color: white;
            background: #009dde;
          }
        }
      }
    }
  }

  &__list {
    margin-top: 24px;
    width: 100%;
    height: 100%;
  }
}

.fb-share {
  &-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
  }

  &-button {
    width: 24px;
    height: 24px;
    overflow: hidden;
    opacity: 0;

    > span {
      width: 24px !important;
      height: 24px !important;

      > iframe {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ideas {
    &__header {
      .cr-search {
        max-width: 224px;

        &__list {
          width: 348px;
          right: 0;
        }
      }
    }

    &__list {
      grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));

      grid-gap: 24px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .ideas {
    &__search {
      .cr-button {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin: 0;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        flex-shrink: 0;
      }
    }
  }
}
