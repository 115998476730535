@import "../../../../vars";

.modal-avatar {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include phone() {
    height: calc(100% - 60px);
  }

  &__title {
    margin: 0 0 20px;
    font: normal normal normal 18px/30px Arial;
    letter-spacing: 0.18px;
    color: var(--base-text);

    @include phone() {
      text-align: center;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &__avatar {
    width: 100%;
  }

  &__item {
    position: relative;
    overflow: hidden;
    display: inline-block;
    height: 80px;
    width: 80px;
    margin: 0 10px 13px 10px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #dddddd;
    transition: all 0.2s;
    box-shadow: 0 0 0 3px transparent;
    background-size: cover;
    background-position: center center;

    &.selected {
      box-shadow: 0 0 0 3px $blue;
    }

    &:hover {
      box-shadow: 0 0 0 3px $blue;
    }

    &_load {
      & span::after {
        content: "";
        position: absolute;
        background: center
          url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAiCAMAAAAau2s5AAAAkFBMVEUAru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru9iTbBoAAAAL3RSTlMABgcJDA0OEBgZGyorLFJtbm9/gI6PkJSVmLW2u7zHy8/Q0dTW19ja8/T4+fr8/Rdds7cAAAEJSURBVHgBrdTrboIwAMXxUzZRESpeRKdblTmQy7Dv/3Y7kaRhZG2axf+H5pD8ElMv4ndrbVrDEZ2XpPOSdE4pUlVp0waPNtpUqVSARRc9iG4s2SUCxNj9KfMAy5GzyCUUz9McjuYnEoWWJ51TkjTQDIMW26JpvrYLDNJsBMX+rh/d315cULxr04dwwIMetLfDuOPDTYahvHF0sRXuuMsp2LTk3FlhwS37KTkLK2y5w36+cra+sPJ96avzMkV/mYIzs8Kk40MpJxNZcnSJ5xt+8P4In/GlYHFWftfXLAHc0PRv6PNTmJFUUDyPM5eLjiQKqfYqRZD7uM/A/KU4yyMwsTrXLlWfVwL4AQjgczXyoz9iAAAAAElFTkSuQmCC")
          no-repeat;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: 20px;
        transition: all 0.2s;
      }
    }
  }

  &__label {
    display: inline-block;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;

    input {
      display: none;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    padding-bottom: 20px;

    @include phone() {
      margin-top: auto;
      flex-direction: column-reverse;
    }

    .cr-button {
      &:not(:first-child) {
        margin-left: 10px;
        @include phone() {
          margin-left: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.modal-avatar-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.edit-avatar-modal {
  max-width: 600px !important;
  display: flex;
}

@media only screen and (max-width: 500px) {
  .modal-avatar {
    .btn {
      width: 100%;

      + .btn {
        margin: 20px 0 0;
      }
    }
  }

  .edit-avatar-modal {
    padding-top: 0 !important;
  }
}
