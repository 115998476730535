@import "../../../../../vars";

.crowd-birthday10-toast-container {
  top: 91px !important;
  width: max-content;
  z-index: 1001 !important;

  @include medium-tablet {
    top: 50px !important;
  }

  .react-toast-notifications {
    &__toast {
      padding: 6px 22px 6px 6px;
      background: #fff;
      border-radius: 11px;
      width: auto;
      box-shadow: none;

      @include medium-tablet {
        padding: 4px 10px 4px 4px;
      }

      &__icon {
        display: none !important;
        &-wrapper {
          width: 42px;
          height: 42px;
          background-size: 40px !important;
          padding: 0;

          @include medium-tablet {
            width: 30px;
            height: 30px;
            background-size: 28px !important;
          }
        }
      }

      &__content {
        padding: 8px 12px 8px;
        min-height: auto;
        font: normal normal normal 20px/26px PT Sans;
        color: #404040;

        @include medium-tablet {
          padding: 4px 10px 4px 4px;
          font-size: 16px;
          line-height: 20px;
        }
      }

      &__dismiss {
        &-button {
          align-self: center;
          padding: 0;
          color: #484d65;
          opacity: 1;
          transform: scale(1.14);
        }
      }

      &__countdown {
        display: none;
      }

      &--success {
        .react-toast-notifications__toast__icon-wrapper {
          background: url(../../../../../assets/birthday10/icons/toast-success.svg) center no-repeat;
        }
        &[type="photo"] {
          .react-toast-notifications__toast__icon-wrapper {
            background: url(../../../../../assets/birthday10/icons/round-pin.png) center no-repeat;
          }
        }
      }
    }
  }
}
