@import "../../../../vars";

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 16px;
  column-gap: 24px;

  @include wide-tablet {
    grid-template-columns: repeat(3, 1fr);
  }

  @include phone {
    display: block;
  }

  .card {
    position: relative;
    border-radius: var(--corners-xxs);
    height: 80px;
    cursor: pointer;
    background: transparent linear-gradient(180deg, #f9f4f9 0%, #d4e0e6 100%) 0%
      0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 0 8px 0 16px;
    overflow: hidden;

    @include tablet {
      padding: 0 4px 0 12px;
      height: 62px;
    }

    @include phone {
      padding: 20px 4px 16px;
      flex-direction: column-reverse;
      gap: 16px;
      align-items: center;
      width: 112px;
      height: 100%;
      text-align: center;
      justify-content: flex-end;
      border-radius: var(--corners-m);
    }

    .fill-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: var(--corners-xxs);
      overflow: hidden;
      @include phone-except {
        transform: translateZ(0);
      }
    }

    .fill {
      --translate-x: -49.8%;
      pointer-events: none;
      position: absolute;
      width: 500%;
      height: 120%;
      transform: translate(var(--translate-x), -50%) rotateY(-75deg)
        rotateX(30deg);
      transition: transform 0.2s;
      left: 0;
      top: 50%;

      @include phone {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: none;

        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: inherit;
          border-radius: inherit;
          border-radius: var(--corners-m);
        }
        &:before {
          filter: brightness(70%);
        }
        &:after {
          bottom: 4px;
        }
      }
    }
    &:hover {
      .fill {
        --translate-x: -40%;
      }
    }

    .title {
      font-weight: 600;
      position: relative;
      color: white;
      flex: 1;
      max-width: 150px;
    }

    .bg {
      width: 72px;
      height: 72px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;

      @include tablet {
        width: 56px;
        height: 56px;
      }

      @include phone {
        width: 64px;
        height: 64px;
      }
    }

    &-empty {
      background: #57bcfd;
      .bg {
        background-image: url(../../../../assets/spheres/idea.svg);
      }

      &:hover {
        .bg {
          background-image: url(../../../../assets/spheres/idea-light.svg);
        }
      }
    }

    &-dpir {
      .title {
        max-width: 170px;
        @include phone {
          hyphens: auto;
        }
      }
    }
  }

  .slider {
    overflow: visible;

    .slide {
      flex: 1;
      height: unset;
    }
  }
}
