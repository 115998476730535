@import "../../../../vars";

.h1 {
  font: var(--text-h1);
  color: var(--base-text);
}
.h2 {
  font: var(--text-h2);
  color: var(--base-text);

  @include tablet {
    font: var(--text-h3);
  }

  @include phone {
    font: var(--text-h4);
  }
}
