@import "../../../../vars";

.wrapper {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  position: relative;
  padding: 0 4px;
  gap: 24px;

  @include wide-tablet {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    padding: 0;
  }

  @include adaptive(750) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include phone {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 12px;
  }
}

.link {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  align-items: center;
  width: 100%;

  &:hover {
    .icon {
      transform: scale(1.05);
    }

    .text {
      color: var(--base-blue);
    }
  }

  @include wide-tablet {
    gap: 12px;
    flex-direction: row;
    text-align: left;
  }
}

.text {
  font: var(--text-body2);
  letter-spacing: 0px;
  color: #0c1014;
  transition: all 0.15s ease-in-out;

  @include wide-tablet {
    font: var(--text-body3);
  }

  @include phone {
    font: var(--text-body4);
  }
}

.icon {
  width: 80px;
  height: 80px;
  vertical-align: middle;
  border-radius: var(--corners-m);
  overflow: hidden;
  transition: all 0.15s ease-in-out;

  @include wide-tablet {
    width: 56px;
    height: 56px;
    border-radius: var(--corners-s);
  }

  @include phone {
    width: 40px;
    height: 40px;
    border-radius: var(--corners-xs);
  }
}

.uslugi {
  box-shadow: 0px 0px 4px #00000014;
}