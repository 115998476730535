@import "../../../../../../vars";

.sudoku-game {
  display: flex;
  flex-direction: column;

  &__content {
    flex-shrink: 0;
    display: flex;
    border-radius: 10px;
    padding: 17px;
    background-image: url("/assets/newyear2023/games/sudoku/images/background.svg");
    background-position: center;
    background-color: #ddbd8a;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__grid {
    margin-right: 17px;
  }

  .grid {
    position: relative;
    border-collapse: collapse;

    &--decorator {
      width: 66px;
      background-color: rgba(255, 255, 255, 0.56);
      border-radius: 10px;
      position: relative;

      .grid__image-container {
        padding: 6px 8px;
      }
    }

    &__background {
      background-color: #42210b;
      position: absolute;
      &-cell {
        background-image: url("../../../../../../assets/newyear2023/games/sudoku/images/box.png");
        background-position: center;
        background-size: contain;
        padding: 76px !important;
        border-collapse: collapse;
      }
    }

    &__table {
      position: relative;
    }

    &__image-container {
      width: 100%;
      height: 76px;
      display: flex;
      padding: 6px 12px;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__row {
      vertical-align: bottom;
      border-collapse: collapse;
    }

    &__row:nth-child(2n) {
      img {
        transform: translateY(-4px);
      }
    }

    &__cell {
      width: 76px;
      height: 76px;
      vertical-align: middle;
      border-collapse: collapse;
      cursor: pointer;
      padding: 0 0 !important;
      font-weight: normal !important;
    }
    &__cell:nth-child(even) {
      .grid__image-container {
        transform: translateX(-4px);
      }
    }
  }
}

@include medium-tablet() {
  .sudoku-game {
    flex-direction: row;
    &__content {
      padding: 14px;
    }

    &__timer {
      position: absolute;
      left: 20px;
      top: 0;
    }

    .grid {
      &__background-cell {
        padding: 64px !important;
      }

      &__cell {
        width: 64px;
        height: 64px;
      }

      &__image-container {
        height: 64px;
        padding: 8px 10px;
      }

      &--decorator {
        width: 56px;

        .grid__image-container {
          padding: 8px 7px;
        }
      }
    }
  }
}
