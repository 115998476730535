@import "../../../../../vars";

.leisure-landing-body {
  overflow: hidden;
}

.leisure-landing {
  $root: &;

  transform: scale(var(--bg-scale));
  transform-origin: 0 0;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity 0.5s;

  &_hidden {
    opacity: 0;
    pointer-events: none;
  }

  &__welcome {
    position: absolute;
    width: 1920px;
    max-width: calc(100vw / var(--bg-scale));
    left: 0;
    top: 99px;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__wrapper {
      width: 980px;
      height: 313px;
      background: linear-gradient(180deg, #ededfacc 0%, rgba(237, 237, 250, 0.4) 100%);
      border-radius: 24px;
      padding: 40px 40px 98px;
      color: var(--loden-purple);
      text-align: center;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      h1 {
        color: var(--loden-purple);
        text-align: center;
        font-family: Montserrat;
        font-size: 86px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
        margin: 0 0 10px;
      }
      a {
        text-decoration: none !important;
      }
    }

    .circle-counter-list {
      margin-top: -90px;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--full-width);
    height: var(--full-height);

    &-inner {
      width: 100%;
      height: 100%;
      transition: all var(--transition-duration-landing) linear;
    }

    & #{$root}__object {
      position: absolute;
      &_unactive {
        pointer-events: none;
      }
    }
  }

  & #{&}-footer {
    position: absolute;
    left: 0;
    width: calc(100% / var(--bg-scale));
    top: calc(100% / var(--bg-scale));
    transform: translateY(-100%);
  }

  & #{&}-idea-sent-modal {
    top: calc(50% / var(--bg-scale));
    left: calc(50% / var(--bg-scale));
    margin-top: -30px;
  }
  & #{&}__baketball-modal {
    top: calc(50% / var(--bg-scale));
    left: calc(50% / var(--bg-scale));
  }
  & #{&}-finish-informers-modal {
    top: calc(50% / var(--bg-scale));
    left: calc(50% / var(--bg-scale));
    margin-top: -60px;
  }
  & #{&}-final-modal {
    left: 14400px;
    z-index: 0;
  }
  & #{&}-events-slider {
    left: 12480px;
    z-index: 0;
    margin-top: -30px;
  }

  @include keyframe("floating") {
    from {
      transform: rotate(-5deg);
    }
    to {
      transform: rotate(5deg);
    }
  }
}
