@import "../../../../vars";

.idea-photo-viewer {
  height: 100%;

  &__body {
    height: 100%;
  }

  &__footer {
    padding: 16px 24px;

    @include phone() {
      padding: 16px 20px;
    }
  }

  &__project {
    font: normal normal normal 18px/20px var(--font-family-primary);
    letter-spacing: 0.15px;

    @include tablet {
      font-size: 16px;
    }

    @include phone {
      font-size: 14px;
    }

    > span {
      color: #009dde;
      font-weight: normal;
    }
  }

  &__idea {
    font: normal normal bold 18px/20px var(--font-family-primary);
    letter-spacing: 0.32px;
    color: var(--base-text);
    margin-top: 12px;

    @include tablet() {
      letter-spacing: 0.29px;
      font: normal normal bold 16px/20px var(--font-family-primary);
    }

    @include phone() {
      font: normal normal bold 14px/18px var(--font-family-primary);
      letter-spacing: 0.25px;
    }

    > span {
      font-weight: normal;
    }
  }

  &__number {
    margin-top: 12px;
    text-align: right;
    font: normal normal normal 14px/20px var(--font-family-primary);
    letter-spacing: 0.25px;
    color: #8a8a8a;
  }

  .idea-card {
    max-height: unset;
    height: 600px;
    box-shadow: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @include phone() {
      height: 355px;
      max-height: 50vh;
    }

    @include adaptive(500) {
      border-radius: 0;
    }
  }
}

.idea-photo-viewer-modal {
  padding: 0;
  max-width: 600px;

  @include phone() {
    .next-modal__arrow {
      &-left {
        left: -20px;
      }
      &-right {
        right: -15px;
      }
    }

    .modal__mobile-header {
      margin-bottom: 20px;
      .logo {
        margin-left: 20px;
      }
    }
  }

  @include adaptive(500) {
    .next-modal__arrows {
      padding-bottom: 20px;
    }
  }

  @media screen and (min-width: 501px) {
    &-overlay {
      padding-top: 60px;
    }

    .modal__close-btn {
      top: 0;
      right: 0;
      transform: translate(100%, -100%);

      span {
        color: #ffffffbf;
        &:before {
          content: "\e98a";
          font-size: 30px;
        }
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
}
