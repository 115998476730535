@import "../../../../vars";

.news-section-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.news-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 104px);
  row-gap: 12px;
  column-gap: 24px;

  @include wide-tablet {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 403px 280px;
    row-gap: 16px;
  }

  @include phone {
    display: flex;
  }
}

.slider {
  width: 100%;

  &--loading {
    opacity: 0.6;
    pointer-events: none;
  }
}

.slider-container {
  width: 100%;

  .news-slider-arrow {
    color: var(--base-blue);
    font-size: 24px;
  }
}

.show-all-section {
  align-self: flex-end;

  @include phone {
    align-self: center;
  }
}
