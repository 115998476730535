@import "../../../../../vars";
.modal-welcome {
  width: 700px;

  @include wide-tablet {
    width: 660px;
  }

  @include medium-tablet {
    max-width: calc(100% - 32px);
    width: 420px;
    height: auto;
    border-radius: 32px;
    &:before {
      opacity: 0.8;
    }
  }

  .ny-modal__close-btn {
    display: none;
  }
}
