@import "../../../../vars";
@import "../../../pages/WorkResult//WorkResult.helper.scss";

.inprogress-card {
  height: 330px;
  box-shadow: 0px 2px 10px #0000001a;
  padding: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 330px;
  transition: all 0.3s ease-in;
  border-radius: var(--round-corners);

  &:hover {
    background-color: #eefbff;
  }

  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__avatar {
    img {
      height: 64px;
      width: 64px;
      border-radius: 100%;
    }
  }

  &__left {
    display: flex;
    width: min-content;
    overflow: hidden;

    @include phone() {
      display: none;
    }
  }

  &__right {
    color: #8a8a8a;
    font: normal normal normal 14px/23px var(--font-family-primary);
    letter-spacing: 0px;

    span {
      font: normal normal bold 14px/23px var(--font-family-primary);
    }
  }

  &__body {
    overflow: hidden;
    margin-top: 24px;
    position: relative;
    padding-left: 65px;
    display: flex;
    flex-direction: column;
    @include phone() {
      padding-left: 35px;
    }

    &::before {
      content: "\e978";
      font-family: "promo-icons";
      color: #dedede;
      font-size: 28px;
      position: absolute;
      top: 0;
      left: 0;

      @include phone() {
        font-size: 18px;
      }
    }
  }

  &__name-phone {
    margin-top: 8px;
    font: normal normal normal 14px/23px var(--font-family-primary);
    color: #8a8a8a;
    @include phone-except {
      display: none;
    }
  }

  &__name {
    font: normal normal bold 20px/24px var(--font-family-primary);
    letter-spacing: 0px;
    color: #282828;
    display: flex;
    flex-direction: column;
    margin-left: 16px;

    @include tablet() {
      font: normal normal bold 18px/24px var(--font-family-primary);
      letter-spacing: 0.15px;
    }
  }

  &__text {
    font: normal normal normal 20px/27px var(--font-family-primary);
    letter-spacing: 0px;
    color: #282828;
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    ol {
      @extend wr-ol;
    }

    ul {
      @extend wr-ul;
    }

    @include tablet() {
      font: normal normal normal 18px/23px var(--font-family-primary);
      letter-spacing: 0.15px;
    }

    @include phone() {
      font: normal normal normal 16px/23px var(--font-family-primary);
      letter-spacing: 0.13px;
    }

    @include scrollbar(5px);
  }
}
