@import "../../../../vars";

.change-password {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 100px;

  margin: 10px;
  background-color: #fff;
  font-size: 14px;
  padding: 30px;

  h3 {
    text-align: center;
    margin: 0 auto 30px;
  }

  &__form {
    font: 18px/20px PT Sans;
    letter-spacing: 0.15px;
    color: var(--base-text);
    max-width: 500px;
    width: 100%;
    margin: 0 auto;

    &__hint {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      font: 14px/20px PT Sans;
      letter-spacing: 0.25px;
      color: #808080;
    }

    .cr-input {
      margin-bottom: 15px;

      &__label {
        font-weight: 500;
      }
    }
    .cr-button {
      margin-top: 25px;
    }
  }
}
