@import "../../../../vars";

.banner {
  display: flex;
  padding: 16px 0;
  justify-content: space-between;
  background-color: #f6f3f1;
  position: relative;
  align-items: center;

  @include phone {
    background-color: unset;
    padding: 8px 0 0;
  }

  &::before,
  &::after {
    content: "";
    background-color: inherit;
    height: 100%;
    position: absolute;
    width: 50vw;
    top: 0;
  }
  &:before {
    left: 100%;
  }

  &::after {
    right: 100%;
  }
}

.content {
  max-width: 496px;
  width: 45%;
  margin-bottom: 52px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include wide-tablet {
    margin-bottom: 3px;
  }

  @include tablet {
    max-width: 286px;
  }

  @include phone {
    max-width: unset;
    width: 100%;
    margin-bottom: 0;
  }
}

.banner.mayor {
  .content {
    margin-bottom: 44px;

    @include wide-tablet {
      margin-bottom: 3px;
    }

    @include phone {
      margin-bottom: 0;
    }
  }
}

.trio {
  width: 55%;
  max-width: 663px;
  padding-right: 80px;
  padding-left: 87px;
  margin-left: auto;

  @include wide-tablet {
    padding-right: 65px;
    padding-left: 70px;
  }

  @include tablet {
    padding-right: 38px;
    padding-left: 56px;
  }

  @include small-tablet {
    max-width: 386px;
    padding-right: 36px;
    padding-left: 50px;
  }

  @include phone() {
    display: none;
  }
}

.title {
  color: var(--base-text);
  font: normal normal 600 24px/29px var(--font-family-primary);

  @include tablet {
    font-size: 18px;
    line-height: 22px;
  }
}

.name {
  font: normal normal 600 36px/44px var(--font-family-primary);
  color: var(--base-blue);
  margin-top: 8px;

  @include tablet {
    font-size: 20px;
    line-height: 24px;
    margin-top: 4px;
  }

  &:hover {
    text-decoration: none;
  }
}

.time {
  margin-top: 8px;

  @include tablet {
    margin-top: 4px;
  }

  @include phone {
    margin-bottom: -4px;
  }
}

.description {
  margin-top: 12px;

  @include tablet {
    margin-top: 8px;
  }

  @include phone {
    margin-top: 12px;
  }
}

.mayor {
  &-title {
    font: normal normal 600 36px/44px var(--font-family-primary);
    color: var(--base-text);

    @include tablet {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &-description {
    margin-top: 9px;

    @include tablet {
      margin-top: 8px;
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }
  &-signature {
    margin-top: 15px;

    @include tablet {
      margin-top: 12px;
    }

    @include phone {
      margin-top: 8px;
    }
  }
}

.footer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include tablet {
    margin-top: 12px;
    gap: 12px;
  }

  @include phone {
    width: 100%;
    flex-direction: column;
  }

  .row {
    display: flex;
    align-items: center;
    gap: 24px;
    @include tablet {
      gap: 12px;
    }

    @include phone {
      flex-direction: column;
    }
  }

  .stats {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;

    @include phone {
      width: max-content;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;

      @include tablet {
        gap: 4px;
      }

      i {
        font-size: 32px;
        color: var(--base-dark-gray);

        @include tablet {
          font-size: 24px;
        }
      }

      span {
        font: normal normal 500 30px/36px var(--font-family-primary);
        letter-spacing: 0.12px;
        color: var(--base-text);

        @include tablet {
          font: normal normal 500 20px/24px var(--font-family-primary);
          letter-spacing: 0;
        }
      }
    }
  }

  .share {
    display: flex;
    flex-direction: column;
    letter-spacing: 0.25px;
    gap: 8px;

    @include tablet {
      letter-spacing: 0;
      gap: 4px;
    }

    @include phone {
      align-items: center;
    }

    &-buttons {
      margin-top: -12px;
    }

    .subscribe {
      color: var(--base-text-secondary);
    }
  }
}

.mobile-swiper {
  display: none;
  width: 100%;
  margin-top: 12px;

  @include phone() {
    display: block;
  }

  .mobile-slide {
    width: 100%;
    height: auto;
    border-radius: var(--corners-s);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: center;
    }
  }
}
