@import "../../../../../vars";

.quiz {
  display: flex;
  justify-content: center;
  margin: auto;
  @include medium-tablet() {
    align-items: center;
    height: 100%;
  }

  &__loader {
    width: 400px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    max-width: 500px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-size: 30px;
    font-weight: 900;
    line-height: 36px;
  }

  &__subtitle {
    margin: 11px auto 32px;

    &--limited {
      max-width: 320px;
      margin: 17px auto 32px;
    }

    &--win {
      margin: 17px auto 0;
    }
  }

  &__icon {
    background-color: var(--enokitake-mushroom);
    margin: 0 auto 16px;

    &--win {
      mask: url("../../../../../assets/newyear2023/icons/victory.svg") 0 0 / contain no-repeat;
      width: 64px;
      height: 72px;
      margin: 0 auto 16px;
      @include medium-tablet() {
        margin: 0 auto 32px;
      }
    }

    &--loose {
      mask: url("../../../../../assets/newyear2023/icons/loose.svg") 0 0 / contain no-repeat;
      width: 72px;
      height: 62px;
    }
  }
}
