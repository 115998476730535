@import "../../../../../vars";

.progress-bar {
  display: flex;
  align-items: center;
  height: fit-content;

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background-color: #d0e2f7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;

    svg,
    img {
      width: 100%;
      height: 100%;
    }

    @include medium-tablet() {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  .bar-container {
    display: flex;
    align-items: center;
    position: relative;

    .bar-background {
      width: 137px;
      height: 9px;
      background: #d2d2de;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      border: 1px solid #d0ccde;
      z-index: -1;

      @include medium-tablet() {
        width: 83px;
        height: 6.7px;

        border: 0.6px solid #d0ccde;
      }
    }

    .bar-foreground {
      height: 9px;
      background: radial-gradient(82.81% 153.02% at 49.44% 146.77%, #65aae0 0%, #026abc 100%);
      transition: width 0.3s ease;
      border-radius: 10px;
      position: absolute;
      z-index: 10;

      @include medium-tablet() {
        height: 6.7px;
      }

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 10px;
        padding: 1px;
        /* control the border thickness */
        background: linear-gradient(131.33deg, #468abe 27.6%, #026bbd 82.59%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
      }
    }

    .progress-text {
      margin-left: 8px;
      font: 700 16px/20.7px "PT Sans", sans-serif;
      color: #404040;

      @include medium-tablet() {
        margin-left: 5px;
        font: 700 9px/11.65px "PT Sans", sans-serif;
      }
    }
  }
}
