@import "../../../../vars";

.textAreaEditor {
  pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    font: normal normal normal 20px / 32px PT Sans;
    font-style: inherit;
  }

  [contentEditable="true"]:empty:not(:focus):before {
    content: attr(placeholder);
    color: #757679;
    font: normal normal normal 18px/24px PT Sans;
  }

  &__editable {
    background-color: #fff;
    padding: 16px;
    font: normal normal normal 18px/24px PT Sans;
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    min-height: 160px;
    white-space: pre-line;
    overflow-y: auto;
    height: 160px;
    &.error {
      border-color: $error;
      box-shadow: 0 0 0 1px $error;
    }

    a {
      cursor: pointer;
      color: var(--base-blue);
    }
  }

  &__editable:focus-visible {
    outline: none;
    border: 1px solid var(--base-bright-blue);
    box-shadow: 0 0 0 1px var(--base-bright-blue);
  }

  &__hint {
    font: normal normal normal 14px/20px PT Sans;
    color: #757679;
    letter-spacing: 0.25px;
    margin: 4px 0 0 16px;
  }

  &__quote {
    background: #ebebeb;
    padding: 16px 24px 16px 60px;
    border-radius: 4px;
    margin: 16px 0 24px;
    background-repeat: no-repeat;
    background-position: 20px 28px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAKCAMAAACzB5/1AAAAElBMVEWPj4+Pj4+Pj4+Pj4+Pj4+Pj4+wJnG0AAAABXRSTlMAP0C/wOKAE0EAAAAhSURBVHgBY2AFAwYoTU0uAwMjKysLA4JmZmVlYoDS+CUBogYB4VIw2WMAAAAASUVORK5CYII=);
    font-style: italic;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;

    &__author {
      color: #808080;

      @include phone() {
        font: italic normal normal 14px / 18px PT Sans;
      }
    }
    &__text {
      pre {
        font: normal normal normal 18px / 24px PT Sans;
        @include phone() {
          font: normal normal normal 16px / 20px PT Sans;
        }
      }
    }

    @include phone() {
      padding: 20px 16px 16px 48px;
      margin: 16px 0 16px;
    }
  }

  &__error {
    color: var(--base-red);
    margin: 0 0 0 16px;
    font: normal normal normal 14px/23px PT Sans;
  }
}
