@import "../../../../../../vars";

.birthday-sphere-block {
  $this: &;

  %bold-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  font-family: "Montserrat";
  font-weight: normal;
  font-style: normal;
  width: 1200px;
  color: var(--birthday9-black);

  @include short-desktop {
    width: auto;
  }

  .birthday-title {
    margin-bottom: 10px;
    white-space: nowrap;
    @include short-desktop {
      white-space: normal;
    }
    @include phone {
      margin-bottom: 20px;
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: var(--birthday9-gray);
  }

  &__stat {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;

    @include phone {
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(37, 37, 38, 0.2);
      border-radius: 18px;
      padding: 20px 30px;
      margin-bottom: 55px;
      flex-direction: column;
    }

    > * {
      display: flex;
      &:not(:last-child) {
        margin-right: 60px;
        @include phone {
          margin: 0 0 20px 0;
        }
      }

      @include short-desktop {
        flex-direction: column;
      }
    }

    &__nums {
      display: flex;
      font-size: 20px;
      line-height: 32px;
      @include phone {
        font-size: 14px;
        line-height: 17px;
      }

      > * {
        &:not(:last-child) {
          margin: 0 32px 0 0;
        }

        @include phone {
          display: flex;
          flex-direction: column;
          &:not(:last-child) {
            margin: 0 24px 0 0;
          }
        }
      }
    }

    &__num {
      @extend %bold-text;
    }

    &_named {
      #{$this}__stat__nums {
        font-size: 16px;
        flex-direction: column;

        > *:not(:last-child) {
          margin: 0 0 4px 0;
        }

        @include phone {
          font-size: 14px;
          flex-direction: row;
          > *:not(:last-child) {
            margin: 0 24px 0 0;
          }
        }
      }
    }

    #{$this}__subtitle {
      margin: 0 16px 0 0;
      padding-top: 3px;
      @include short-desktop {
        padding-top: 0;
        margin: 0 0 8px 0;
      }
      @include phone {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 4px;
      }
    }
  }

  &__content {
    display: flex;

    > * {
      position: relative;
      &:last-child {
        flex: 1;
        margin: 0 0 0 80px;
      }
    }

    &_reverse {
      flex-direction: row-reverse;
      > *:last-child {
        margin: 0 80px 0 0;
      }
    }

    @include short-desktop {
      flex-direction: column;
      align-items: stretch;

      > * {
        &:first-child {
          align-self: center;
        }
        &:last-child {
          flex: auto;
          margin: 40px 0 0 0;
        }
      }
    }
  }

  &__people,
  &__lists {
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__people {
    padding: 0 40px;
    @include phone {
      padding: 0 75px;
    }

    .swiper-slide {
      cursor: pointer;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      img {
        width: 120px;
        max-height: 100%;
        transition-property: width, height;
        transition-duration: 0.7s;
      }
      &-active,
      &-duplicate-active {
        z-index: 1;
        img {
          width: 200px;
        }
      }

      @include phone {
        height: calc(100vw / 3);
        img {
          width: calc(100vw / 5);
        }
        &-active {
          img {
            width: calc(100vw / 3);
          }
        }
      }
    }

    &__name {
      @extend %bold-text;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 10px;
      @include phone {
        #{$this} & {
          margin-top: 10px;
          margin-bottom: 4px;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    &__pos {
      text-align: center;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      @include phone {
        font-size: 13px;
        line-height: 20px;
      }
    }

    &__desc {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;

      &--homutovskaya {
        width: 102%;
      }

      @include phone {
        line-height: 22px;
      }
    }
  }

  &__lists {
    padding: 20px 0 0 0;
    @include short-desktop {
      position: unset;
      padding: 0;
      text-align: center;
    }

    > * {
      &:not(:last-child) {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid;
        @include phone {
          margin-bottom: 32px;
          padding-bottom: 32px;
        }
      }
    }

    ul {
      max-height: 112px;
      overflow-y: auto;
      margin-top: 20px;
      @include scrollbar(6px, 3px, #dadfec, #65aae1);
      @include short-desktop {
        max-height: none;
      }
      li {
        @extend %bold-text;
        max-width: max-content;
        text-decoration: underline;
        cursor: pointer;
        @include short-desktop {
          margin: 0 auto;
        }

        & + li {
          margin-top: 20px;
        }

        &:hover {
          text-decoration: none;
        }

        &:after {
          content: "";
          display: inline-block;
          margin-left: 10px;
          width: 18px;
          height: 14px;
          vertical-align: middle;
          background: url("../../../../../../assets/birthday9/images/arrow.svg")
            0 0 no-repeat;
          @include phone {
            display: none;
          }
        }

        &#{$this}__lists__more {
          &:after {
            width: 14px;
            height: 8px;
            background-image: url("../../../../../../assets/birthday9/images/chevron.svg");
            @include phone {
              display: inline-block;
            }
          }
          &_open:after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__bg {
    pointer-events: none;
    width: 600px;
    object-fit: contain;
    margin-bottom: 40px;
    @include phone {
      width: calc(100vw + 120px);
    }
  }

  &__bg2 {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: calc(100vw - 30px);
  }

  .cr-button {
    height: 60px;
    margin-top: 40px;
    padding: 20px 40px;
    border-radius: 18px;
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    @include phone {
      margin-top: 32px;
    }
  }

  &_wait-for-swiper {
    .swiper-slide img {
      transition: unset;
    }
  }
}
