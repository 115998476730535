@import "../../../../../vars";

.birthday-spheres {
  $colors: 1 #f3b9b8, 2 #f4bb74, 3 #96caf2, 4 #f4bb74, 5 #8ed7e5, 6 #c7b7fb;

  min-height: 500px;
  padding-top: 40px;
  opacity: 0;
  animation: fadeIn 0.01s linear 0.5s 1 forwards;
  @include phone {
    padding-top: 30px;
  }
  @include short-desktop {
    width: 100%;
    padding: 0 100px;
  }

  @include tablet {
    padding: 0 15px;
  }

  &__item {
    position: relative;
    & + & {
      margin-top: 300px;
      @include short-desktop {
        margin-top: 120px;
      }
      @include phone {
        margin-top: 100px;
      }
    }

    @each $i, $color in $colors {
      &:nth-child(#{$i}) {
        .birthday-sphere-block__lists > *:not(:last-child) {
          border-color: $color;
        }
        .birthday-sphere-block__stat__num {
          @include phone {
            color: $color;
          }
        }
      }
    }

    .birthday-sphere-block {
      margin: 0 auto;
    }
  }

  &__line-wrapper {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);

    path {
      transition: stroke-dashoffset 0.1s ease-out;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
