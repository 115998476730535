@import "../../../../vars";

.cr-textarea {
  position: relative;
  margin-top: 24px;

  &__input {
    font: normal normal normal 18px/23px var(--font-family-primary);
    letter-spacing: 0.13px;
    color: var(--base-text);
    border: 1px solid #dedede;
    outline: none;
    padding: 8px 14px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    height: 120px;
    border-radius: var(--round-corners);

    &-invalid {
      border-color: $error;
      box-shadow: 0 0 0 1px $error;
    }

    &:focus {
      border: 1px solid #00aeef;
    }
  }

  &__label {
    font: normal normal normal 16px/28px var(--font-family-primary);
    letter-spacing: 0.44px;
    margin-bottom: 6px;

    &-required {
      color: #009dde;
      margin-left: 5px;
    }
  }

  &__length {
    @include hint-text();
  }

  &__hint {
    @include hint-text();
  }

  &-disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.6;
    cursor: default;
  }

  &__tooltip {
    position: absolute;
    background-color: var(--base-red);
    color: white;
    padding: 0.5rem;
    bottom: -8px;
    width: 100%;
    z-index: 2;

    &:after {
      content: "";
      position: absolute;
      top: 6px;
      left: 7%;
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-bottom-color: var(--base-red);
      border-top: 0;
      margin-left: -10px;
      margin-top: -10px;
    }

    span {
      display: block;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  &__only-vertical-resize {
    resize: vertical;
  }
}
