@import "../../../../vars";

.cr-district-selector {
  position: relative;
  width: 100%;
  margin-right: 16px;

  @include medium-tablet() {
    margin-right: 24px;
  }

  .scrollbar-container {
    position: absolute !important;
    height: fit-content !important;
    .ScrollbarsCustom-Content {
      max-height: 328px !important;
    }

    .ScrollbarsCustom-Wrapper {
      position: static !important;

      .ScrollbarsCustom-Scroller {
        position: static !important;
      }
    }

    .ScrollbarsCustom-Track {
      background-color: rgba(28, 28, 28, 0.1) !important;
      height: calc(100% - 27px) !important;
      top: 17px !important;
      width: 6px !important;
      right: 6px !important;

      @include medium-tablet() {
        height: calc(100% - 32px) !important;
        top: 16px !important;
      }
    }

    .ScrollbarsCustom-Thumb {
      background-color: rgba(28, 28, 28, 0.3) !important;
    }
  }


  &__list {
    max-height: 360px;
    height: fit-content;
    width: 100%;
    position: absolute;
    // overflow: auto;
    top: 46px;
    z-index: 2;
    background: #ffffff;
    padding: 16px;
    box-shadow: 0px 3px 10px #28282826;
    border-radius: var(--round-corners);
    height: fit-content;

    @include scrollbar(7px, 4px, #eff6fc, #b3b8bc);

    &-wrapper {
      &:not(:first-child) {
        margin-top: 6px;
      }
    }

    &-letter {
      text-align: left;
      font: normal normal bold 20px/26px PT Sans;
      letter-spacing: 0px;
      color: #282828;
      text-transform: uppercase;
      margin-left: 4px;
    }

    &-item {
      font: normal normal normal 20px/26px PT Sans;
      letter-spacing: 0px;
      color: #282828;
      text-transform: capitalize;
      margin-top: 1px;
      cursor: pointer;
      padding: 4px;
      &:hover {
        background-color: #f9f9f9;
      }
      border-radius: var(--round-corners);
    }
  }

  .cr-input {
    margin-top: 0;
    > input {
      width: 100%;
      border: 1px solid var(--base-blue);
    }
  }

  &__arrow,
  .close {
    position: absolute;
    bottom: 12px;
    font-size: 16px;
    @include phone() {
      bottom: 10px;
    }
  }

  &__arrow {
    right: 16px;
    cursor: pointer;
    &_up {
      transform: rotate(-90deg);
      color: #65aae0;
    }
    &_down {
      transform: rotate(90deg);
      color: #797a80;
    }
  }

  .close {
    right: 42px;
    color: #797a80;
  }
}
