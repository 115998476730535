@import "../../../../../../../vars";
@import "~video-react/styles/scss/video-react";

.play-icon {
  height: 96px;
  width: 96px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../../../../../../../assets/video-play-icon.svg") 0 0 /
    contain no-repeat;

  @include phone {
    height: 64px;
    width: 64px;
  }
}

.control-bar {
  display: none !important;
}

.original-play-button {
  height: 0 !important;
  width: 0 !important;
  display: none !important;
}
