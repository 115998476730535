@import "../../../../vars";

.like {
  min-width: 81px;
  height: 42px;
  background-color: var(--base-pink);
  border-radius: var(--corners-xs);
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  gap: 4px;

  &:before {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: var(--base-pink);
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%) rotate(45deg);
  }

  .text {
    font-weight: 500;
    color: white;
    margin-top: -4px;
  }

  i {
    font-size: 24px;
    color: white;
  }
}
