.cr-input {
  margin-top: 24px;
  position: relative;

  &__input {
    font: normal normal normal 18px/23px var(--font-family-primary);
    letter-spacing: 0.13px;
    color: var(--base-text);
    border: 1px solid #dedede;
    outline: none;
    padding: 8px 14px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: var(--round-corners);
  }

  &__label {
    font: normal normal normal 16px/28px var(--font-family-primary);
    letter-spacing: 0.44px;
    margin-bottom: 6px;

    &-required {
      color: var(--base-blue);
      margin-left: 5px;
    }
  }

  &-disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.6;
    cursor: default;
  }

  &__tooltip {
    position: absolute;
    background-color: var(--base-red);
    color: white;
    padding: 0.5rem;
    top: 80px;
    width: 100%;
    z-index: 2;

    &:after {
      content: "";
      position: absolute;
      top: 6px;
      left: 7%;
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-bottom-color: var(--base-red);
      border-top: 0;
      margin-left: -10px;
      margin-top: -10px;
    }

    &--no-label {
      top: 46px;
    }

    span {
      display: block;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  &__length {
    font: normal normal normal 14px/20px var(--font-family-primary);
    color: #757679;
    letter-spacing: 0.25px;
    margin: 4px 0 0 16px;
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 500px) {
}
