.cr-icon-button {
  display: flex;
  align-items: center;
  border: 1px solid var(--base-blue);
  cursor: pointer;
  padding: 7px;
  position: relative;
  transition: all 0.2s;
  min-height: 40px;
  border-radius: var(--round-corners);

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;

    > .ic {
      color: var(--base-blue);
    }
  }

  &__text {
    font: Bold 16px/19px Bebas Neue;
    letter-spacing: 0.8px;
    color: var(--base-blue);
  }

  &:hover {
    background-color: #cceffc;
  }

  &:active {
    background-color: var(--base-blue);
  }

  &:active > &__text,
  &:active > &__icon > .ic {
    color: white;
  }
}

@media only screen and (max-width: 768px) {
  .cr-icon-button {
  }
}

@media only screen and (max-width: 500px) {
  .cr-icon-button {
  }
}
