.circle-counter {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &__outer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
  }

  &__title {
    color: var(--loden-purple);
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  &__count {
    font-family: Open Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    color: var(--loden-purple);
  }

  &__subtitle {
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--loden-purple);
    max-width: 110px;
    text-align: center;
  }
}
