@import "../../../vars";

.link-popup {
  background: white;
  width: 400px;
  max-width: 90vw;
  z-index: 100;
  position: absolute;
  bottom: 30px;
  box-shadow: 0px 0px 8px #00000029;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;

  &__title {
    font: normal normal bold 1.25rem/1.75rem PT Sans;
    color: var(--base-text);
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 32px;
    @include phone {
      gap: 16px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    display: flex;
    margin-top: 32px;
    @include phone {
      margin-top: 16px;
    }
  }
  .cr-button:not(:first-child) {
    margin-left: 1rem;
  }

  .cr-input {
    &__tooltip {
      font-size: 14px;
      line-height: 14px;
      font-weight: normal;
    }
  }
}
