@import "../../../../../../vars";

.generation {
  &__title {
    font: normal normal bold 24px/32px PT Sans;
    color: var(--base-text);
    margin-bottom: 24px;

    @include tablet {
      font-size: 22px;
      line-height: 29px;
      margin-bottom: 16px;
    }

    @include phone {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__description {
    font: normal normal normal 20px/32px PT Sans;
    color: var(--base-text);
    margin-bottom: 32px;
    @include tablet {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    @include phone {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }

  &__link:hover {
    text-decoration: none;
  }

  &__propose-form {
    display: flex;
    flex-flow: column;
    gap: 32px;
    @include tablet {
      gap: 16px;
    }

    &__title {
      font: normal normal bold 20px/32px PT Sans;
      color: var(--base-text);
      margin-bottom: 24px;
      @include tablet {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 16px;
      }
      @include phone {
        font-size: 16px;
        line-height: 22px;
      }
    }

    &__description {
      display: inline-block;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 32px;
      color: #797a80;

      @include phone() {
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 20px;
      }

      &.empty {
        display: none;
      }
    }

    .spell-checker__backdrop {
      overflow-y: hidden;
    }

    .cr {
      &-input,
      &-textarea,
      &-select,
      &-file-selector__head {
        margin-top: 0;
        justify-content: flex-start;
      }

      &-input input,
      &-select__input {
        border: 1px solid var(--base-blue);
        padding: 15px 16px 15px;
        font: normal normal normal 18px/24px PT Sans;
        &-invalid,
        &.error {
          border-color: $error;
        }
      }

      &-input {
        input {
          &::placeholder {
            color: #757679;
            opacity: 1;
          }
        }
      }

      &-input__tooltip,
      &-textarea__tooltip {
        top: calc(100% + 8px);
        bottom: unset;
      }

      &-select {
        span {
          height: 24px;
        }
        &__label {
          display: none;
        }
      }

      &-file-selector {
        margin-top: 14px;
        @include tablet {
          margin-top: 22px;
        }

        &__head {
          &-text {
          }
          &-button {
            font: normal normal bold 18px/24px PT Sans;
            color: var(--base-blue);
            text-transform: none;
            border: none;
            padding: 0;
            background: none;
            letter-spacing: 0px;
            @include tablet {
              font-size: 16px;
            }
            @include phone {
              font-size: 14px;
              line-height: 19px;
            }
            .promo-icon {
              transform: translateY(4px);
              color: var(--base-blue);
              margin-right: 12px;
              vertical-align: text-bottom;
              @include phone {
                font-size: 20px;
                margin-right: 8px;
              }
            }
          }
        }
      }

      &-button {
        border-radius: 6px;
        font: normal normal bold 18px/20px PT Sans;
        text-transform: none;
        padding: 9px 24px;
        height: auto;

        @include phone {
          font-size: 16px;
        }
      }
    }

    &__controls {
      align-self: flex-end;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 16px;

      @include tablet() {
        margin-top: 8px;
      }

      @include phone() {
        width: 100%;
        flex-direction: column;
        margin-top: 4px;
      }
    }
  }

  &__add-link {
    color: var(--base-text);
    position: relative;
    cursor: pointer;
    width: max-content;
    margin-top: 32px;
  }
}
