.share {
  &-icon {
    position: relative;
    padding: 6px;
    font: 14px/18px PT Sans;
    letter-spacing: 0.25px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-icons {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(calc(-100% - 16px));
    padding: 8px 16px;
    border-radius: 5px;
    background: inherit;
    color: inherit;
    border-top-color: inherit;

    &::after {
      content: "";
      position: absolute;
      right: 9px;
      bottom: -16px;
      border: 8px solid transparent;
      border-top: 8px solid;
      border-top-color: inherit;
    }

    .ic {
      cursor: pointer;
      opacity: 0.75;
      position: relative;

      &:first-child {
        padding-left: 1px;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }

      > button {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        outline: none;
      }

      @media (hover: hover) {
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &-icons-unfolded {
    position: relative;
    transform: unset;
    padding: 0;
    margin-top: 12px;

    &::after {
      display: none;
    }
  }
}

.ok-hover {
  &:hover {
    color: #e47d08;
  }
}

.vk-hover {
  &:hover {
    color: #2787f5;
  }
}

.fb-hover {
  &:hover {
    color: #3b5998;
  }
}

.tw-hover {
  &:hover {
    color: #50abf1;
  }
}

.tg-hover {
  &:hover {
    color: #28a8e9;
  }
}

.wa-hover {
  &:hover {
    color: #4caf50;
  }
}

.fb-share-block {
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
  opacity: 0;
}
