.cr-tooltip {
  .__react_component_tooltip {
    width: fit-content;
    background-color: #777b8c;
    box-shadow: 0px 4px 20px #0000001a;
    text-transform: capitalize;
    margin: 0;
    font: normal normal normal 14px/20px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    border-radius: 0;
    padding: 16px;
    text-transform: inherit;
    opacity: 1 !important;

    &::before,
    &::after {
      content: none;
    }
  }
}
