@import "../../../../vars";

.common-slider {
  width: 100%;
  position: relative;

  &--fade {
    .swiper-container {
      overflow: visible;
    }
  }

  .visible-slide {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .invisible-slide {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  &__footer {
    position: relative;
    width: 100%;
    min-height: 60px;
    @include phone() {
      min-height: 20px;
    }
  }

  &__link {
    position: absolute;
    right: 0;
    bottom: 18px;
    font: normal normal normal 18px/22px var(--font-family-primary);
    letter-spacing: 0.17px;
    text-decoration: underline;
    color: #65aae0;

    @include tablet() {
      font: normal normal normal 16px/20px var(--font-family-primary);
      letter-spacing: 0.29px;
      bottom: 16px;
    }

    @include adaptive(730) {
      font: normal normal normal 14px/19px var(--font-family-primary);
    }

    @include phone() {
      bottom: -20px;
      font: normal normal normal 14px/19px var(--font-family-primary);
      letter-spacing: 0.1px;
      width: 100%;
      text-align: center;
    }
  }

  .slider-arrows {
    padding: 20px 0;

    @include tablet() {
      padding: 26px 0;
    }

    @include phone() {
      padding: 22px 0;
    }

    .ic {
      margin: 0 15px;
    }
  }
}
