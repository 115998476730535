@import "../../../../../vars";

.discussion-landing-button {
  border-radius: 20px;
  text-transform: none;
  padding: 12px 32px;
  font: normal normal bold 20px/24px Montserrat;
  @include phone {
    padding: 11px 16px;
    font-size: 14px;
    line-height: 18px;
  }

  &.cr-button-filled {
    border: none;
  }
}
