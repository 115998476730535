.base {
  &.italic {
    font-style: italic;
  }
  &.bold {
    font-weight: bold;
  }
  &.noWrap {
    white-space: nowrap;
  }
  &.wordBreak {
    word-break: break-word;
    overflow-wrap: break-word;
  }
  &.underline {
    text-decoration: underline;
  }
  &.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  &.align-left {
    text-align: left;
  }
  &.align-right {
    text-align: right;
  }
  &.align-center {
    text-align: center;
  }
  &.align-justify {
    text-align: justify;
  }
  &.align-inherit {
    text-align: inherit;
  }
  &.uppercase {
    text-transform: uppercase;
  }
}
