@import "../../../../../vars";

.birthday-modal {
  padding: 60px;
  color: var(--birthday9-black);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;

  @include phone {
    padding: 88px 15px 60px;
  }

  &__close-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 20px;
    height: 20px;
    background: none;
    border: none;
    outline: none;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 28px;
      height: 3px;
      top: 50%;
      left: 50%;
      background-color: var(--birthday9-gray);
      border-radius: 4px;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover {
      &:before,
      &:after {
        background-color: var(--birthday9-black);
      }
    }

    @include phone {
      top: 24px;
      right: 15px;
      width: 40px;
      height: 40px;
      background: var(--base-blue);
      border-radius: 12px;
      &:before,
      &:after {
        background-color: white;
        width: 19px;
        height: 2px;
      }

      &:hover {
        background-color: #23527c;
        &:before,
        &:after {
          background-color: white;
        }
      }
    }
  }
}

#birthday-modal-overlay {
  padding: 1.2em;
  > div {
    max-width: 720px;
    width: 100%;
    border-radius: 24px;
  }
  @include phone {
    padding: 0;
    > div {
      border-radius: 0;
      min-height: 100%;
    }
  }
}
