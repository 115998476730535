.progress-bar {
  color: white;
  position: relative;
  transform-style: preserve-3d;

  &__bar {
    height: 100%;
    background-color: var(--base-blue);
    font: normal normal normal 14px PT Sans;
    letter-spacing: 0.25px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--round-corners-lg);
  }

  &__text {
    z-index: 1;
  }

  &__value {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: var(--base-dark-blue);
    border-radius: var(--round-corners-lg);
  }
}
