@import "../../../../vars";

.qr-modal {
  position: relative;
  padding: 24px;
  text-align: center;
  width: fit-content !important;

  @include phone {
    width: 100% !important;
  }

  &__container {
    max-width: 100%;
    width: 440px;
    height: 318px;
    margin: 0 auto;
    @include wide-tablet {
      width: 348px;
      height: 299px;
    }

    padding: 24px;
  }

  @include phone {
    padding: 0;

    &__container {
      padding: 20px 0 0;
    }
  }

  &__loader {
    .data-loader {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__title {
    font: normal normal bold 24px/32px PT Sans;
    letter-spacing: 0px;
    color: #282828;
    margin-bottom: 20px;
  }

  &__code {
    margin: 0 auto 20px auto;

    & > * {
      max-width: 134px;
      max-height: 134px;
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    font: normal normal normal 20px/28px PT Sans;

    @include wide-tablet {
      font-size: 16px;
      line-height: 24px;
    }

    letter-spacing: 0.16px;
    color: #282828;
    margin-bottom: 12px;
  }

  &__date {
    font: normal normal bold 20px/28px PT Sans;

    @include wide-tablet {
      font-size: 16px;
      line-height: 24px;
    }

    letter-spacing: 0.16px;
    color: #282828;
  }
}
