@import "../../../../../vars";

.discussion-landing-gallery {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include wide-tablet {
    gap: 20px;
  }
  @include phone {
    gap: 16px;
  }

  .ideas-list {
    margin: 0;

    &__all {
      bottom: 0;
      @include phone {
        transform: translate(-50%, 0);
      }
    }

    .swiper-container {
      margin-top: 0 !important;
    }

    @include phone {
      .slider-arrows {
        padding-bottom: 38px !important;
      }
    }
  }
}
