@import "../../../vars";
@import "../WorkResult/WorkResult.helper.scss";

.wrd-page {
  width: 100%;
  position: relative;

  &__back {
    padding: 0 24px;
    max-width: 1274px;
    width: 100%;
    margin: 0 auto;
  }

  &__inner {
    max-width: 1272px;
    margin: 0 auto;
    padding: 0 24px;
    margin-top: -15px;

    @include tablet() {
      margin-top: -20px;
    }

    @include phone() {
      margin-top: -36px;
    }
  }

  .data-loader-wr {
    height: 100%;
  }

  &__accepted {
    @include phone() {
      + #{&} {
        margin-top: 32px;
      }
    }
  }

  &__title {
    font: normal normal bold 48px/48px var(--font-family-primary);
    letter-spacing: -0.4px;
    color: #282828;
    margin-bottom: 16px;

    @include tablet() {
      font: normal normal bold 28px/28px var(--font-family-primary);
      letter-spacing: 0.21px;
      margin-top: 18px;
    }

    @include phone() {
      font: normal normal bold 30px/40px var(--font-family-primary);
      letter-spacing: -0.47px;
    }
  }

  &__wrapper {
    position: relative;
    max-width: 1224px;
    margin: 0 auto;
    padding: 32px 24px;
  }

  &__realized {
    margin-top: 16px;
  }

  &__accepted {
    margin-top: 32px;
  }

  &__content {
    .cr-button {
      font: normal normal normal 20px/26px var(--font-family-primary);
      letter-spacing: 0.15px;
      border: none;

      @include phone() {
        font: normal normal normal 14px/19px var(--font-family-primary);
        letter-spacing: 0.1px;
      }
    }
  }
}

.wrd-list {
  padding-left: 32px;
  margin: 16px 0;
  list-style-type: none;
  counter-reset: item;

  &:first-child {
    margin-top: 0;
  }

  &--nomarker {
    list-style-type: none !important;
  }

  &__item {
    position: relative;
    margin-top: 10px;

    ol {
      @extend wr-ol;
    }

    ul {
      @extend wr-ul;
    }

    span {
      position: absolute;
      left: -45px;
      top: -3px;
      width: 50px;
      text-align: center;

      font: normal normal bold 16px/28px var(--font-family-primary);
      letter-spacing: 0.13px;
      color: #282828;
    }
  }

  &__point {
    font: normal normal normal 16px/20px var(--font-family-primary);
    letter-spacing: 0.13px;
    color: #282828;
    cursor: pointer;
    &:hover {
      color: var(--base-blue);
    }
  }

  @include phone {
    margin-top: 12px;
    &__item {
      margin-top: 8px;
    }
  }
}

.wrd__card {
  box-shadow: 0px 2px 10px #0000001a;
  margin: 16px 0;
  padding: 16px;
  border-radius: var(--round-corners);

  @include phone() {
    box-shadow: none;
    padding: 0;
  }

  &-title {
    font: normal normal bold 24px/32px var(--font-family-primary);
    letter-spacing: 0px;
    color: #282828;

    @include tablet() {
      font: normal normal bold 24px/32px var(--font-family-primary);
      letter-spacing: 0px;
    }
  }
}

.wrd-navigation {
  display: flex;
  justify-content: end;
  width: 100%;
  height: 72px;

  &--fixed {
    .wrd-navigation__inner {
      position: fixed;
      background-color: white;
      top: $header-desk-height + px;

      @include tablet() {
        top: $header-tab-height + px;
      }
      @include phone() {
        top: $header-mob-height + px;
      }
    }

    .wrd-navigation__content {
      padding: 17px 0px;
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    width: 100%;
    z-index: 5;
  }

  &__content {
    position: relative;
    width: 100%;
    max-width: 1224px;
    margin: auto;
    display: flex;
    justify-content: flex-end;

    .cr-button {
      border: none;
      padding: 5px 5px;
      margin: 0 15px;
      background: transparent;
      color: var(--base-blue) !important;

      &__text {
        border-bottom: 1px dashed;

        @include tablet() {
          font: normal normal bold 14px/19px var(--font-family-primary);
          letter-spacing: 1.35px;
        }

        @include phone() {
          font: normal normal bold 12px/15px var(--font-family-primary);
          letter-spacing: 1.16px;
        }
      }
    }
  }
}
