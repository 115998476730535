@import "../../../../../../vars";

.all-comments {
  position: relative;
  background-color: #eefbff;
  margin-top: 32px;
  padding-bottom: 32px;

  @include phone() {
    padding-bottom: 20px;
  }

  @include afterBeforeBackground(#eefbff);

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 32px;

    @include tablet() {
      padding-top: 20px;
      align-items: flex-end;
    }
  }

  &__title {
    font: normal normal bold 32px/39px Montserrat;
    letter-spacing: 0px;
    color: #282828;

    @include tablet() {
      font: normal normal bold 28px/34px Montserrat;
    }

    @include phone() {
      font: normal normal bold 20px/24px Montserrat;
    }
  }

  &__expand {
    font: normal normal bold 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #65aae0;
    text-decoration: none !important;

    @include tablet() {
      font: normal normal bold 16px/19px Montserrat;
      letter-spacing: 0px;
    }

    @include phone() {
      font: normal normal bold 14px/18px Montserrat;
      width: fit-content;
      margin: 16px auto 0;
    }
  }

  .comment-card {
    background-color: white;
    margin-top: 24px;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }
}
