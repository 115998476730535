@import "../../../../../../vars";

$colors: (
  purple: #B593FF,
  orange: #FFB867,
  light-blue: #54C3F6,
  green: #04CC88,
  red: #F87F7F,
  pink: #F4649C,
  blue: var(--base-blue),
);

@mixin colorStyles($color) {
  .rating-card__splitter {
    background-color: map-get($colors, $color);
  }

  .author {
    &__order {
      color: map-get($colors, $color);
    }

    &__info {
      border-bottom: 1px solid map-get($colors, $color);
    }
  }
}

@each $color-name, $color-code in $colors {
  .#{"#{$color-name}"} {
    @include colorStyles($color-name);
  }
}

.rating-card {

  &__project {
    display: flex;
    margin-bottom: 20px;
    @include phone {
      flex-direction: column;
    }
  }

  &__img {
    flex-shrink: 0;
    width: 286px;
    height: 155px;
    border-radius: 20px;
    margin-right: 40px;
    position: relative;
    overflow: hidden;
    @include wide-tablet {
      margin-right: 24px;
    }

    @include phone {
      width: auto;
      height: 152px;
      margin-right: 0;
      margin-bottom: 16px;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__title {
    font: normal normal bold 24px/29px Montserrat;
    color: #282828;
    margin-bottom: 16px;
    @include phone {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 12px;
    }
  }

  &__link {
    letter-spacing: 0px;
    font: normal normal bold 20px/24px Montserrat;
    position: relative;

    &::after {
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -32px;
      background: transparent url("../../../../../../assets/discussion-landing/arrow.svg") center center no-repeat;
    }

    @include wide-tablet {
      font-size: 16px;
      line-height: 19px;
    }
    @include phone {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__splitter {
    display: block;
    height: 1px;
    width: 100%;
  }

  &__authors {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .author {
    display: flex;
    align-items: center;

    @include medium-tablet() {
      align-items: flex-start;
    }

    &__order {
      flex-shrink: 0;
      padding: 4px 0;;
      margin-right: 24px;
      margin-left: auto;
      width: 80px;
      display: flex;
      justify-content: flex-end;
      font: normal normal bold 80px/80px Montserrat;
      letter-spacing: 0px;
      @include wide-tablet {
        font-size: 60px;
        line-height: 73px;
        width: 100px;
      }
      @include phone {
        width: 55px;
        margin-right: 20px;
      }
    }

    &__info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      height: 104px;
      padding: 16px 0;

      @include medium-tablet() {
        height: auto;
        min-height: 47px;
      }
    }

    &:last-child .author__info {
      border-bottom: none;
    }

    &__name {
      text-align: left;
      font: normal normal bold 24px/29px Montserrat;
      letter-spacing: 0px;
      color: #282828;
      margin-bottom: 4px;
      @include wide-tablet {
        font-size: 20px;
        line-height: 20px;
      }
      @include phone {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__idea {
      font: normal normal 500 16px/18px Montserrat;
      letter-spacing: 0px;
      color: #282828;
      @include wide-tablet {
        font-size: 14px;
        line-height: 20px;
      }
      @include phone {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
