@import "../../../../../vars";

.special-projects-card {
  height: 100%;
  padding: 16px;
  border-radius: 0px 0px 4px 4px;
  color: #65aae0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include desktop-only() {
    &:hover {
      text-decoration: none;
      color: #ffffff;
      background-color: #3c8de2;
    }
  }
  @include tablet() {
    padding: 12px;
  }
  @include phone() {
    padding: 12px 0;
    border-radius: 0px;
    border-bottom: 2px solid;
    flex-direction: row;
    justify-content: stretch;
    align-items: end;
  }
  &__image {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 256/144;
    box-shadow: 1px 1px 1px #00000040;
    border-radius: 4px;
    @include phone() {
      margin-right: 16px;
      max-width: 88px;
      height: 100%;
    }
  }
  &__bottom {
    width: 100%;
    padding: 12px 0 0 0;
    border-top: 2px solid;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 8px;
    @include tablet {
      padding: 8px 0 0 0;
    }
    @include phone() {
      align-items: flex-end;
      border-top: none;
      padding: 0 0 2px 0;
    }
    &__text {
      font: normal normal bold 20px/24px var(--font-family-primary);
      @include tablet() {
        font: normal normal bold 14px/18px var(--font-family-primary);
      }
      @include phone {
        line-height: 14px;
      }
    }
    &__arrow {
      &::before {
        content: "\e989";
        font-family: "promo-icons";
        font-size: 24px;

        @include tablet {
          font-size: 20px;
        }
      }
    }
  }
}
