@import "../../../../vars";
.text {
  color: var(--base-text);
}

.size-1 {
  font: var(--text-body1);
}
.size-2 {
  font: var(--text-body2);
}
.size-3 {
  font: var(--text-body3);
}
.size-4 {
  font: var(--text-body4);
}
.size-5 {
  font: var(--text-body5);
}

.inherit {
  font: inherit;
  color: inherit;
}
