.puzzle {
    position: relative;
    width: 100%;
    height: 100% !important;
    touch-action: none;
}

.puzzle__grid {
    display: grid;
    height: 100%;
    margin-top: -1px;
    margin-left: -1px;

    &-item {
        border: 1px solid #cecece;
        margin-top: 1px;
        margin-left: 1px;
    }
}

.puzzle__piece {
    position: absolute;
    border: 1px solid #cecece;
    cursor: pointer;
    z-index: 1;
    user-select: none;
}

.puzzle__piece.puzzle__piece--solved {
    border-color: #000;
    z-index: 0;
}
.puzzle__piece.puzzle__piece--dragging {
    z-index: 5;
    box-shadow: 1px 1px 5px 0px #00000091;
}