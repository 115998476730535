@import "../../../../../vars";

.discussion-landing-rating {

  .swiper-container {
    width: 100%;
  }

  .swiper-slide {
    width: 100% !important;
  }

  display: flex;
  flex-direction: column;
  gap: 24px;
  @include wide-tablet {
    gap: 20px;
  }
  @include phone {
    gap: 16px;
  }

  &__arrows {
    margin: 0 auto;
  }

  .cr-pagination {
    justify-content: center;
    
    &__arrow {
      cursor: pointer;
    }
  }
}
