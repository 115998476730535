@import "../../../../../vars";

.discussion-landing-media {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include wide-tablet {
    gap: 20px;
  }
  @include phone {
    gap: 16px;
  }
}
