.common-viewer {
  &--single {
    .next-modal__arrows,
    .next-modal__carrows {
      display: none;
    }
  }

  .modal__mobile-header .logo {
    margin-left: 24px;
  }
}
