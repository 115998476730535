.leisure-header {
  display: flex;
  z-index: 1;
  position: absolute;
  left: 0;
  width: calc(100% / var(--bg-scale));

  &__inner {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    padding: 0 60px;
    margin-top: 30px;

    img {
      cursor: pointer;
    }
  }

  &__counter {
    color: var(--loden-purple);
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 14px;
  }

  .circle-button-list {
    margin-left: 32px;
  }

  .cr-button {
    width: 134px;
    height: 54px;
    flex-shrink: 0;
    border-radius: 27px;
    border: 2px solid var(--loden-purple);
    color: var(--loden-purple);
    background: transparent;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-left: auto;

    &:active,
    &:hover {
      background: transparent;
    }
  }
}
