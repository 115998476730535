@import "../../../../../vars";

.birthday-game {
  &__inner {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include wide-tablet() {
      padding: 0 15px;
    }
  }

  .game-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    max-width: 1040px;
    text-align: center;
  }

  &__subtitle {
    @extend .game-title;
    margin-top: 17px;
    color: var(--birthday9-black);

    @include wide-tablet() {
      margin-top: 30px;
    }

    @include phone() {
      margin-top: 10px;
    }

    a {
      color: var(--birthday9-black);
      font-weight: 700;
    }
  }

  &__losttitle {
    @extend .game-title;
    margin-top: 60px;
    margin-bottom: 30px;
    color: var(--birthday9-red);

    @include phone() {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  &__wontitle {
    @extend .game-title;
    margin-top: 20px;

    a {
      font-weight: 700;
      text-decoration: underline;
      color: var(--birthday9-black);
    }
  }

  &__won-bg {
    width: 100%;
    max-width: 1000px;
    margin-top: 35px;
    img {
      width: 100%;
    }
  }

  .cr-button {
    height: 60px;
    padding: 20px 40px;
    border-radius: 18px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-top: 40px;

    @include phone() {
      max-width: 186px;
      margin-top: 30px;
    }
  }

  .puzzle-game {
    margin: 40px auto 0;
    height: 668px;
    width: 100%;
    max-width: 688px;

    @include adaptive(750) {
      height: 89vw;
    }

    @include phone() {
      margin-top: 30px;
    }
  }

  .puzzle {
    background-color: var(--birthday9-yellow);
    border: 17px solid var(--birthday9-yellow);

    &__grid {
      &-item {
        border: 2px solid var(--birthday9-orange);
      }
    }
  }

  .timer {
    background-color: rgba(243, 185, 184, 0.25);
    border-radius: 32px;
    width: 186px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    @include phone() {
      margin-top: 30px;
    }

    &__count {
      color: var(--birthday9-black);
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
    }
  }
}
