@import "../../../../../vars";

.discussion-landing-welcome {
  position: relative;
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 50vw;
    top: 0;
    bottom: 0;
    background-color: #f6f3f1;
  }
  &:before {
    right: 100%;
  }
  &:after {
    left: 100%;
  }

  &__banner {
    display: flex;
    flex-direction: column;
    min-height: 689px;
    padding: 40px 64px 40px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include wide-tablet {
      min-height: 610px;
      padding: 24px;
    }
    @include phone {
      padding: 20px;
      min-height: unset;
    }

    &__plate {
      flex: 1;
      padding: 32px 40px 65px;
      background-color: rgba(101, 170, 224, 0.9);
      border-radius: 20px;
      max-width: 992px;
      @include wide-tablet {
        padding: 24px 24px 32px 24px;
      }
      @include phone {
        padding: 12px;
      }
    }

    .discussion-landing-title {
      color: #ffffff;
      margin-bottom: 32px;
      @include wide-tablet {
        margin-bottom: 16px;
      }
    }

    &__description {
      white-space: pre-line;
      font: normal normal normal 20px/24px Montserrat;
      margin-bottom: 40px;
      color: #ffffff;
      @include wide-tablet {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 24px;
      }
    }

    &__bottom {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      gap: 32px;
      @include wide-tablet {
        gap: 24px;
        > *:nth-child(2) {
          grid-area: 2/1/3/2;
        }
        > *:nth-child(3) {
          grid-area: 2/2/3/3;
        }
      }
      @include adaptive(740) {
        grid-template-columns: 1fr;
        > * {
          grid-area: auto !important;
        }
      }
      @include phone {
        gap: 16px;
        align-items: flex-start;
      }

      .cr-button {
        width: 256px;

        @include phone() {
          width: calc(100% - 8px);
          margin: 8px auto auto;
        }
      }
    }

    &__counter {
      display: flex;
      align-items: center;

      &--visible {
        visibility: visible;
      }
      visibility: hidden;

      @include phone {
        flex-direction: column;
        align-items: flex-start;
      }

      &__num {
        margin-right: 24px;
        border: 2px solid #fff;
        border-radius: 3px;
        font: normal normal bold 40px/46px Montserrat;
        color: #ffffff;
        display: flex;
        @include phone {
          margin-right: 0;
          margin-bottom: 12px;
          font-size: 36px;
          line-height: 40px;
        }

        > span {
          text-align: center;
          width: 40px;
          & + span {
            border-left: 2px solid #fff;
          }
        }
      }
      &__txt {
        font: normal normal normal 24px/24px Montserrat;
        color: #ffffff;
        @include wide-tablet {
          max-width: 95px;
          font-size: 16px;
          line-height: 20px;
        }
        @include phone {
          max-width: unset;
          line-height: 19px;
        }
      }
    }
  }

  &__expert {
    border-radius: 21px 24px 24px 96px;
    background-color: #e2f2ff;
    display: flex;
    align-items: flex-start;
    max-width: 828px;
    margin-top: -32px;
    position: relative;
    left: calc(100% - 848px);
    @include wide-tablet {
      max-width: 654px;
      margin-top: -16px;
      left: calc(max(0px, min(42px, 100% - 678px)));
      right: 0;
    }
    @include phone {
      margin-top: 0;
      position: unset;
      left: unset;
      right: unset;
      flex-direction: column;
      align-items: center;
      border-radius: unset;
      background-color: unset;
      padding: 20px 20px 0;
    }

    &__avatar {
      padding: 20px;
      background-color: #a9d5f8;
      border-radius: 21px 96px 96px 96px;
      @include phone {
        margin-bottom: 12px;
      }

      img {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        display: block;
      }
    }

    &__quote {
      padding: 16px 20px;
      @include phone {
        padding: 16px;
        background: #e2f2ff;
        border-radius: 20px;
      }

      &__text {
        font: normal normal normal 14px/18px Montserrat;
        color: var(--base-text);
        margin-bottom: 4px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 9;
        line-clamp: 9;
        -webkit-box-orient: vertical;

        @include wide-tablet {
          -webkit-line-clamp: 13;
          line-clamp: 13;
        }

        @include phone {
          margin-bottom: 16px;
          display: initial;
        }
      }
    }
  }

  &__participant-tip.cr-tooltip {
    cursor: default;

    .__react_component_tooltip {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 8px 20px #0000001a;
      border-radius: 8px;
      opacity: 1 !important;
      padding: 16px;
      max-width: 396px;
      width: 100%;

      font: normal normal normal 14px/18px Montserrat;
      color: var(--base-text);

      @include wide-tablet {
        max-width: 292px;
        padding: 12px;
      }

      @include phone {
        max-width: 280px;
      }

      &::before,
      &:after {
        display: none;
      }
    }
  }
}
