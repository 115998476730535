.player-container {
  position: relative;
  margin-top: 24px;
  width: 100%;
  border-radius: var(--round-corners);
  overflow: hidden;
}

.play-button {
  display: none !important;
}

.play-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-icon {
  width: 96px;
  height: 96px;
  background: url("../../../../../src/assets/video-play-icon.svg") center /
    contain no-repeat;
  border: none;
  cursor: pointer;
}
