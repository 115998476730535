@import "../../../vars";

.archive-page {
  .project {
    &__nav-container {
      padding-left: 0;
    }
    &__header {
      min-height: auto;
      padding-top: 0;
      background: none;

      @include tablet() {
        min-height: 208px;
      }

      @include phone {
        margin-left: 0;
        margin-right: 0;
      }

      &::before,
      &::after {
        content: none;
      }
    }
    &__info {
      align-items: flex-start;
      margin-left: 0;
      margin-right: 24px;
      @include adaptive(500) {
        margin-right: 0;
      }
    }

    &__logo {
      flex-basis: 392px;
      height: 236px;
      overflow: hidden;

      > div {
        height: 100%;
      }

      &:not(.project__logo--mobile) {
        @include tablet {
          flex-basis: 224px;
          height: 234px;
        }
      }

      &--mobile {
        @include phone() {
          flex-basis: auto;
          height: fit-content;
          overflow: unset;
          width: auto;
          margin-top: 20px;
        }
      }
    }

    &__stats {
      display: flex;
      font: Bold 34px/41px var(--font-family-primary);
      letter-spacing: 0.25px;
      color: var(--base-text);
      align-items: center;
      justify-content: center;
      margin: 25px auto 50px auto;

      > div {
        display: flex;
        align-items: end;
        &:first-child {
          margin-right: 36px;
        }
        .ic {
          width: 36px;
          height: 36px;
          font-size: 36px;
          line-height: 36px;
          margin-right: 12px;
        }
      }
    }

    &__page-description {
      padding-bottom: 0px;
      font-size: 20px;
      line-height: 28px;
      margin-top: 24px;
      .user-text-style {
        font: inherit;
        margin-top: 0;
      }

      @include tablet {
        font-size: 18px;
        line-height: 24px;
      }

      @include phone {
        margin-top: 16px;
        font-size: 16px;
      }
    }

    &__title {
      font: normal normal bold 60px/72px var(--font-family-primary);
      letter-spacing: -0.5px;
      color: var(--base-text);
      @include tablet {
        font-size: 50px;
        line-height: 61px;
        letter-spacing: -0.42px;
      }
      @include phone {
        font-size: 40px;
        line-height: 40px;
        letter-spacing: -0.33px;
      }
    }

    &__sub-title {
      text-align: left;
      @include tablet() {
        width: 262px;
        font: 14px/18px var(--font-family-primary);
        letter-spacing: 0.25px;
        margin-top: 12px;
      }

      @include phone() {
        font: 16px/24px var(--font-family-primary);
        letter-spacing: 0.29px;
        margin-bottom: 27px;
      }
    }

    &__buttons {
      @include phone() {
        flex-direction: column;
        align-items: center;
      }

      .cr-button {
        margin: 0 15px;
        @include phone() {
          margin: 10px 0;
        }
      }
    }

    &__button {
      &:first-child {
        @include phone() {
          margin-bottom: 16px;
        }
      }

      &--main button {
        padding: 15px;
        margin-bottom: 48px;
      }
      &--plan button,
      &--ideas button {
        margin-right: 24px;
      }

      button {
        @include tablet() {
          padding: 14px;
        }

        @include phone() {
          width: 280px;
          font: normal normal bold 14px/18px var(--font-family-primary);
          letter-spacing: 1.35px;
          padding: 11px 24px;
          margin-right: 0;
        }
      }
    }

    &__sub-projects {
      margin-top: 48px;
      margin-bottom: 96px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .project-flipcard {
        height: auto;
        width: 288px;
        margin-right: 12px;
        margin-left: 12px;
        margin-bottom: 24px;

        @include tablet {
          width: 224px;
        }

        @include phone() {
          width: calc(min(300px, 100%));
          margin-left: 0;
          margin-right: 0;
        }

        .project-flipcard-inner__back-footer {
          margin-top: unset;
          bottom: unset;
          width: 100%;
          height: auto;
          position: static;
        }

        .project-flipcard-inner__front {
          position: static;
          .cr-icon-button {
            position: static;
          }
        }
      }

      &_landing {
        .project-flipcard {
          cursor: default;
          height: 432px;
          &-inner {
            &__back-footer {
              .cr-icon-button {
                position: relative !important;
                &__link {
                  left: 0;
                  top: 0;
                }
              }
            }
          }
          @include tablet {
            height: 338px;
            &-inner {
              &__front-footer {
                padding-top: 12px;
              }
            }
          }
          @include phone {
            height: 414px;
          }
        }
      }

      @include tablet {
        margin-top: 32px;
      }
    }
  }
}
