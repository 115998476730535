.not-exist-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;

  &__logo {
    width: 110px;
    margin-bottom: 35px;
  }

  h1 {
    font: normal normal bold 48px/48px Bebas Neue;
    letter-spacing: -0.4px;
    color: var(--base-blue);
  }

  &__text {
    font: normal normal normal 20px/22px PT Sans;
  }
}
