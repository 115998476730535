@import "../../../../../vars";

.birthday-footer {
  height: 100px;
  background-color: var(--base-blue);
  max-width: unset !important;
  padding: 0 !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: white;

  @include medium-tablet() {
    height: 200px;
  }

  &__inner {
    max-width: 1488px;
    margin: 0 auto;
    padding: 0 24px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include medium-tablet() {
      flex-direction: column;
      justify-content: center;
    }

    span {
      text-align: center;
      @include medium-tablet() {
        margin-top: 20px;
      }
    }
  }

  &__left {
    display: flex;
    align-items: center;

    @include medium-tablet() {
      flex-direction: column;
    }
  }

  &__right {
  }

  &__help {
    cursor: pointer;
  }

  &__rules {
    margin-left: 32px;

    @include medium-tablet() {
      margin-left: auto;
    }

    a {
      color: white;
      text-decoration: none;
    }
  }
}
