@import "../../../../vars";

.user-details-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1064px !important;
  padding: 0;

  > div {
    height: 100%;
    width: 100%;

    .data-loader-wr {
      margin: auto;
    }
  }

  @include phone {
    padding-top: 0 !important;
  }
}

.user-details {
  height: auto;
  position: relative;
  display: grid;
  grid-template-columns: 0.7fr 1fr 0.7fr;
  grid-template-rows: 1fr auto;
  grid-column-gap: 16px;
  //display: flex;
  //justify-content: space-between;

  padding: 56px 24px 24px 24px;

  @include tablet {
    grid-column-gap: 24px;
  }

  @include adaptive(500) {
    padding: 20px 0;
    grid-template-columns: 1fr;
    grid-template-rows: unset;
    grid-gap: 0;

    > *:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__info {
    grid-row: 1/3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 16px;
    border: 1px solid #dedede;
    width: 100%;
    //height: 100%;
    //max-width: 288px;
    //margin-right: 12px;
    border-radius: var(--round-corners);

    @include tablet {
      padding: 20px 12px;
    }

    @include adaptive(500) {
      grid-row: unset;
      padding: 20px 16px;
    }

    &-avatar {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      > img {
        width: 100%;
        height: 132px;
        max-width: 132px;
        border-radius: 50%;

        @include tablet {
          width: 100px;
          height: 100px;
        }

        @include adaptive(500) {
          width: 134px;
          height: 134px;
        }
      }
    }

    &-name {
      display: flex;
      align-items: center;
      justify-content: center;
      font: Bold 18px/23px PT Sans;
      letter-spacing: 0.47px;
      color: var(--base-text);
      margin-top: 12px;

      @include tablet {
        margin-top: 20px;
        font: normal normal bold 14px/19px PT Sans;
        letter-spacing: 0.1px;
      }

      @include adaptive(500) {
        font: normal normal bold 24px/32px PT Sans;
        letter-spacing: 0px;
      }

      &-first,
      &-last {
        text-align: center;
      }
    }

    &-location {
      display: flex;
      flex-direction: column;
      align-items: center;
      font: normal normal normal 16px/20px PT Sans;
      letter-spacing: 0.15px;
      color: #8a8a8a;
      margin-top: 8px;

      @include tablet {
        margin-top: 12px;
        font: normal normal normal 14px/19px PT Sans;
        letter-spacing: 0.1px;
      }

      @include adaptive(500) {
        font: normal normal normal 16px/24px PT Sans;
        letter-spacing: 0.13px;
      }

      &-region,
      &-district {
        text-align: center;
      }
    }
  }

  &__stats {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    //height: 100%;
    //max-width: 392px;
    //margin-left: 12px;
    //margin-right: 12px;

    &-counters {
      display: flex;
      flex-direction: column;
      font: normal normal normal 16px/20px PT Sans;
      letter-spacing: 0.15px;
      flex-grow: 1;
      padding: 28px 16px 24px;
      border: 1px solid #dedede;
      height: 100%;
      border-radius: var(--round-corners);

      @include tablet {
        padding: 20px 12px;
      }

      @include adaptive(500) {
        padding: 20px 16px;
      }
    }
  }

  &__ideas {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid #dedede;
    width: 100%;
    //height: 100%;
    //max-width: 288px;
    //margin-left: 12px;
    position: relative;
    overflow: hidden;
    border-radius: var(--round-corners);

    @include tablet {
      padding: 20px 12px;
    }

    @include adaptive(500) {
      padding: 20px 16px;
    }

    &-swiper {
      display: flex;
      width: 100%;
      height: 100%;
      position: relative;

      @include tablet {
        max-width: max-content;
      }

      @include adaptive(500) {
        max-width: unset;
      }

      &-control {
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: flex-end;

        @include tablet {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          position: absolute;
          right: 8px;
          top: 50%;
          margin-top: -16px;
          z-index: 2;
          background: #1a1a1a88;
          backdrop-filter: blur(1px);
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @include adaptive(500) {
          width: auto;
          height: auto;
          border-radius: unset;
          position: relative;
          top: unset;
          right: -8px;
          margin-top: unset;
          background: unset;
          backdrop-filter: unset;
          color: var(--base-text);
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }

      .control-left {
        transform: rotate(180deg);
        right: unset;
        left: 8px;

        @include adaptive(500) {
          left: -8px;
        }
      }

      .control-disabled {
        cursor: auto;
        opacity: 0.5;
      }

      .swiper-container {
        width: 100%;
        max-width: 176px;
        max-height: 176px;

        @include tablet {
          max-width: 138px;
          max-height: 138px;
        }

        @include adaptive(500) {
          max-width: 176px;
          max-height: 176px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: var(--round-corners);
      }
    }

    &-footer {
      width: 100%;
      height: 30%;
      border-top: 1px solid #dedede;
      margin-top: 15px;
      padding-top: 16px;
    }
  }

  &__projects {
    border: 1px solid #dedede;
    font: normal normal normal 16px/20px PT Sans;
    letter-spacing: 0.15px;
    padding: 12px 16px;
    margin-top: 16px;
    text-align: start;
    color: var(--base-text);
    border-radius: var(--round-corners);
    grid-column: 2/4;

    @include tablet {
      margin-top: 24px;
      font: normal normal normal 14px/20px PT Sans;
      letter-spacing: 0.25px;
    }

    @include adaptive(500) {
      grid-column: unset;
      grid-row: 3/4;
      margin-top: 0;
      font: normal normal normal 16px/20px PT Sans;
      letter-spacing: 0px;
    }

    > a {
      color: var(--base-text);
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__statitem {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &:not(:last-child) {
      padding-bottom: 8px;
      border-bottom: 1px solid #dedede;
      margin-bottom: 16px;

      @include tablet {
        margin-bottom: 10px;
      }

      @include adaptive(500) {
        margin-bottom: 18px;
        border: unset;
        padding: unset;
      }
    }

    .ic {
      font-size: 24px;
      color: #009dde;
    }

    &-title {
      font: normal normal bold 18px/22px PT Sans;
      letter-spacing: 0.47px;
      color: var(--base-text);
      display: flex;
      flex-direction: column;
      align-items: start;
      flex-grow: 1;
      height: 100%;
      justify-content: center;
      position: relative;
      padding: 0 12px;

      @include tablet {
        padding: 0 8px;
        font: normal normal bold 13px/16px PT Sans;
        letter-spacing: 0px;
        padding-top: 2px;
      }

      @include adaptive(500) {
        padding: 0 12px;
        font: normal normal bold 14px/19px PT Sans;
        letter-spacing: 0.25px;
      }

      > span {
        font: normal normal normal 14px/18px PT Sans;
        letter-spacing: 0.25px;
        color: var(--base-text);

        @include tablet {
          font: normal normal normal 11px/14px PT Sans;
          letter-spacing: 0px;
          color: #8a8a8a;
        }

        @include adaptive(500) {
          font: normal normal normal 12px/15px PT Sans;
          letter-spacing: 0.4px;
        }
      }
    }

    &-count {
      font: normal normal bold 34px/42px Bebas Neue;
      letter-spacing: 0.25px;
      color: var(--base-text);
      margin-top: -4px;

      @include tablet {
        font: normal normal bold 20px/24px Bebas Neue;
        letter-spacing: 0.15px;
        margin-top: -2px;
      }

      @include adaptive(500) {
        font: normal normal bold 34px/42px Bebas Neue;
        letter-spacing: 0.25px;
        margin-top: -4px;
      }
    }
  }
}
