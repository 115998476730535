@import "../../../../_vars";

.routes {
  display: flex;
  gap: 25px;

  @include wide-tablet {
    max-height: 150px;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px 64px;
  }

  @include phone {
    flex-wrap: nowrap;
    max-height: none;
  }
}
