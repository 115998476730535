@import "../../../vars";

.captcha-mosru {
  display: flex;
  margin-top: 24px;
  align-items: center;

  @include phone() {
    flex-direction: column;
  }

  input {
    width: 178px;
    height: 56px;
    padding: 16px;
    margin-right: 24px;
    font: normal normal normal 18px/24px PT Sans;
    letter-spacing: 0px;
    color: #8a8a8a;
    // border: 1px solid #65aae0;

    @include phone() {
      width: 100%;
      margin: 0 0 12px 0;
    }
  }

  img {
    border: 1px solid #dedede;
    margin-right: 24px;
    width: 152px;
    height: 42px;

    @include phone() {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }

  .refresh {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 18px;

    @include phone() {
      padding: 0;
    }

    &__text {
      font: normal normal normal 16px/28px PT Sans;
      letter-spacing: 0.44px;
      color: #65aae0;
    }

    .ic {
      margin-right: 12px;
      color: #65aae0;
    }
  }
}

.recaptcha-wrapper--error .captcha-mosru {
  input {
    border: 1px solid $error;
    box-shadow: 0 0 0 1px $error;
  }
}
