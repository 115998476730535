$scroll-top-button-color: #a1ddf3;

.scroll-top-button {
  position: relative;
  border: 1px solid $scroll-top-button-color;
  border-radius: 50px;
  cursor: pointer;
  background: $scroll-top-button-color;
  width: 50px;
  height: 50px;

  &__wrapper {
    position: fixed;
    bottom: 50px;
    z-index: 100;
    right: 43px;
  }

  .ic {
    color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}