@import "../../common.scss";
@import "../../../../../vars";

.birthday-header {
  height: $header-desktop-height;
  width: 100%;
  position: fixed;
  z-index: 2;
  background-color: white;

  @include wide-tablet() {
    height: $header-tablet-height;
  }

  &__inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .logo {
    height: 75px;
    width: 96px;
    margin-right: 60px;
    background-position: left;
    margin-left: 0;

    @include wide-tablet() {
      height: 68px;
      width: 45px;
    }
  }

  &__links {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    @include wide-tablet() {
      display: none;
    }
  }

  &__link {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 50px;
    }

    > a {
      color: var(--birthday9-black);
      text-decoration: none;
      padding-bottom: 5px;

      &:hover {
        color: var(--birthday9-black);
        font-weight: 500;
      }
    }

    &--active {
      > a {
        color: var(--birthday9-black);
        font-weight: 500;
        border-bottom: 2px solid var(--base-blue);
      }
    }
  }

  .cr-button {
    width: 135px;
    height: 60px;
    border-radius: 18px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-left: auto;

    @include wide-tablet() {
      height: 40px;
      width: 95px;
    }
  }
}
