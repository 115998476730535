@import "../../../../vars";

.cr-search {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  &__input {
    border: 1px solid var(--base-blue) !important;
    height: 40px;
    font: 16px/28px PT Sans;
    letter-spacing: 0.44px;
    padding: 10px 56px;
    padding-left: 38px ;

    @include tablet() {
      height: 36px;
    }

    &-rounded {
      border-radius: 24px;
    }

    .close {
      right: 40px;
      font-size: 14px;
      width: 14px;
      height: 14px;
      top: calc(50% - 7px);
    }
    &:active,
    &:focus {
      ~ .close,
      ~ .search {
        color: var(--base-blue);
      }
    }
  }

  &__list {
    outline: none;
    position: absolute;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid #dedede;
    bottom: 42px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.058);
    top: 46px;
    z-index: 99;
    height: fit-content;
    max-height: 540px;
    overflow: auto;
    border-radius: var(--round-corners);

    &-item {
      padding: 10px;
      cursor: pointer;
      font: normal normal bold 14px/18px PT Sans;
      letter-spacing: 1.35px;
      color: var(--base-text);
      text-transform: uppercase;
      border-radius: var(--round-corners-sm);

      &:hover {
      }
    }

    &-dots {
      font-weight: bold;
      width: 100%;
      font-size: 26px;
      font-family: serif;
      text-align: center;
      height: 26px;
      cursor: pointer;
    }
  }

  &__mutiplePerRow {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 24px;

    .cr-search__list-item {
      padding: 4px 12px;
      letter-spacing: 1.35px;
      color: var(--base-blue);
      text-transform: uppercase;
      border: 1px solid var(--base-blue);
      margin-right: 12px;
      margin-top: 12px;
      white-space: nowrap;
      width: min-content;
      text-overflow: ellipsis;
      overflow: hidden;

      &-selected {
        background-color: var(--base-blue);
        color: white;
      }
    }
  }

  .search {
    position: absolute;
    right: 18px;
    color: #797a80;
    transform: scaleX(-1);
    height: 20px;
    width: 20px;
    font-size: 20px;
  }

  .close {
    position: absolute;
    color: #797a80;
    right: 40px;
    font-size: 12px;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 900px) {
  .cr-search {
    &__input {
      padding-right: 55px !important;
    }

    .search {
      font-size: 21px;
      width: 21px;
      height: 21px;
    }

    .close {
      font-size: 21px;
      width: 21px;
      height: 21px;
      right: 35px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .cr-search {
    &__list {
      right: calc(-24px + 1vw) !important;
      width: 98vw !important;
    }
  }
}
