@import "../../../../../../vars";

.expert-game-modal {
  padding: 32px 22px 24px 40px;

  @include wide-tablet {
    padding: 16px 22px;
  }

  &__description {
    display: flex;
    gap: 14px;
  }

  &__img {
    border-radius: 16px;
    overflow: hidden;
    flex-shrink: 0;
    width: 200px;
    height: 200px;
    @include wide-tablet {
      width: 113px;
      height: 113px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .crowd-birthday-10-modal__buttons > * {
    width: 190px;

    @include wide-tablet {
      width: 107px;
    }
  }
}
