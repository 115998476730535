@import "../../../../vars";

.support-form {
  .cr-file-selector {
    &__head {
      justify-content: flex-start;
    }
  }
  .recaptcha-wrapper--error {
    iframe {
      border: 1px solid $error;
      box-shadow: 0 0 0 1px $error;
    }
  }
}
