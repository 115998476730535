@import "../../../../vars";

.submit-form {
  $root: &;
  &-modal {
    @include phone() {
      padding-top: 0 !important;
    }
  }

  &__title {
    font: normal normal bold 24px/29px Bebas Neue;
    letter-spacing: 0.18px;
    color: var(--base-text);
    margin-bottom: 24px;

    @include phone() {
      margin-top: 24px;
    }
  }

  &__footer {
    text-align: end;
    margin-top: 25px;

    @include phone() {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    #{$root}__cancel {
      margin-right: 16px;

      @include phone() {
        margin-bottom: 12px;
      }
    }
  }
}

.submit-disabled {
  opacity: 0.3;
  pointer-events: none;
}
