@import "../../../../../vars";

.ideas-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;

  @include tablet {
    gap: 24px;
  }

  @include phone {
    gap: 20px;
  }

  .loading {
    opacity: 0.6;
    pointer-events: none;
  }

  .wrapper {
    gap: 24px;
  }

  .sliders {
    display: flex;
    gap: 24px;
    padding-bottom: 48px;

    @include tablet {
      padding-bottom: 36px;
    }

    @include phone {
      padding-bottom: 40px;
    }

    > * {
      flex: 1;
      width: calc((100% - 48px) / 3);
    }

    .slider,
    .slide,
    .slide > * {
      overflow: visible;
    }

    .slide {
      overflow: hidden;
    }

    .arrows {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .view-all {
    position: absolute;
    right: 0;
    bottom: 0;

    @include phone {
      position: unset;
      margin-top: -4px;
      align-self: center;
    }
  }

  .upload {
    display: flex;
    gap: 24px;

    @include wide-tablet {
      flex-direction: column;
    }

    @include phone {
      gap: 16px;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
      width: calc(33% - 12px);
      @include wide-tablet {
        max-width: 100%;
        width: 534px;
      }

      &-text {
        letter-spacing: 0.13px;
      }
    }

    .instruction {
      align-self: flex-start;
      flex: 1;
      height: 86px;
      mask-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0.4)
      );

      @include wide-tablet {
        flex: unset;
        align-self: unset;
      }

      @include tablet {
        height: 81px;
      }

      @include phone {
        height: auto;
        mask-image: linear-gradient(
          to top,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0.4)
        );
      }

      ul {
        display: flex;
        justify-content: space-between;
        position: relative;

        @include phone {
          flex-direction: column;
          gap: 15px;
          margin-left: 23px;
        }

        &:before {
          content: "";
          position: absolute;
          height: 3px;
          left: 55px;
          right: 135px;
          background-color: var(--base-dark-blue);
          bottom: -26px;

          @include tablet {
            bottom: -30px;
            right: 110px;
          }

          @include phone {
            width: 3px;
            height: unset;
            left: 7px;
            top: 16px;
            bottom: 16px;
            right: unset;
          }
        }

        li {
          display: flex;
          gap: 16px;
          align-items: flex-end;
          width: 200px;
          position: relative;

          @include tablet {
            gap: 10px;
            width: 165px;
          }

          @include phone {
            gap: 12px;
            padding-left: 48px;
          }

          &:before {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            background-color: var(--base-dark-blue);
            bottom: -32px;
            left: 53px;

            @include tablet {
              left: 46px;
              bottom: -37px;
            }

            @include phone {
              left: 0;
              bottom: unset;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          .step-num {
            font-size: 64px;
            line-height: 54px;
            color: var(--base-dark-blue);

            @include tablet {
              font-size: 56px;
              line-height: 44px;
            }

            @include phone {
              font-size: 48px;
              line-height: 44px;
            }
          }

          .step-text {
            font-weight: 500;
            color: var(--base-dark-blue);
          }
        }
      }
    }
  }
}
