@import "../../../../../../vars";

.thematic-project-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 10px #0000001a;
  border-radius: 20px;
  overflow: hidden;
  &__img {
    flex-shrink: 0;
    position: relative;
    height: 202px;

    @include wide-tablet {
      height: 180px;
    }

    @include phone {
      height: 144px;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__repost-btn {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }

  &__info {
    flex-grow: 1;
    padding: 20px 16px;
    min-height: 206px;

    @include phone {
      padding: 16px;
      min-height: auto;
    }
  }

  &__date {
    text-align: right;
    font: normal normal 500 14px/18px Montserrat;
    letter-spacing: 0px;
    color: #8a8a8a;
    margin-bottom: 16px;
  }

  &__title {
    text-align: left;
    font: normal normal bold 20px/24px Montserrat;
    color: $black-text;
    min-height: 48px;
    margin-bottom: 8px;

    @include phone {
      font-size: 18px;
      line-height: 22px;
    }

    @include phone {
      min-height: 44px;
    }
  }

  &__scroll {
    height: 76px;

    @include wide-tablet {
      height: 95px;
    }

    @include phone {
      height: 114px;
    }
  }

  &__text {
    text-align: left;
    font: normal normal normal 16px/19px Montserrat;
    letter-spacing: 0px;
    color: $black-text;

    @include phone {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__button {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 28px 16px;
    font: normal normal bold 20px / 24px Montserrat;

    @include wide-tablet {
      padding: 20px 16px;
    }

    @include phone {
      font-size: 16px;
      line-height: 19px;
      padding: 16px;
    }

    &--propose {
      background-color: #65a9e0 !important;
    }

    &--estimate {
      background-color: #1473e6 !important;
    }

    &--discuss {
      background-color: #ffc28d !important;
    }

    &--soon-start {
      background-color: #a69cef;
      cursor: default;
      pointer-events: none;
    }

    &--finished {
      background-color: #dedede;
      cursor: default;
      pointer-events: none;
    }
  }
}
