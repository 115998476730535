@import "../../../../../../vars";

.publication {
  text-align: left;
  margin: 25px;

  @include phone {
    margin-top: 20px;
    padding: 0;
  }

  &--modal {
    max-width: 904px !important;
  }

  @include wide-tablet {
    &--modal {
      max-width: 692px !important;
    }
  }

  &__title {
    word-break: break-word;
    font: normal normal bold 28px/34px Montserrat;
    height: 68px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    letter-spacing: 0px;
    color: #282828;

    @include phone {
      font-size: 20px;
      line-height: 24px;
      height: auto;
      margin-bottom: 12px;
    }
  }

  &__scroll {
    padding-top: 20px;
    max-height: calc(100dvh - 238px);

    @include wide-tablet {
      max-height: 570px;
    }

    @include phone {
      padding-top: 12px;
    }
  }

  &__date {
    font: normal normal normal 14px/20px Montserrat;
    letter-spacing: 0.25px;
    color: #8a8a8a;
    margin-bottom: 24px;

    @include phone {
      margin-bottom: 20px;
    }
  }

  &__img {
    max-width: 100%;
    height: 416px;
    user-select: none;
    margin-bottom: 24px;
    margin-right: 18px;

    @include phone {
      height: 148px;
      margin-bottom: 20px;
      margin-right: 10px;
    }

    img {
      object-fit: cover;
      max-width: 100%;
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    word-break: break-word;
    font: normal normal normal 16px/20px Montserrat;
    letter-spacing: 0px;
    color: #282828;
    margin-right: 18px;

    h2 {
      font: normal normal bold 20px/24px Montserrat;
    }

    h3 {
      font: normal normal bold 18px/22px Montserrat;
    }

    h4 {
      font: normal normal bold 16px/20px Montserrat;
    }

    blockquote {
      font: normal normal normal 20px/24px Montserrat;
      background: url("../../../../../../assets/discussion-landing/icon-blockquote.svg") 0 0 no-repeat;
      margin: 25px 0;
      margin-left: 18px;

      p {
        margin-left: 50px;
        padding-left: 20px;
        border-left: 2px solid #8a8a8a;
      }

      @include phone() {
        font: normal normal normal 16px/19px Montserrat;
        margin: 20px 0;
        background-size: 24px 19px;

        p {
          padding-left: 12px;
          margin-left: 36px;
        }
      }
    }

    li {
      &::marker {
        color: var(--base-blue);
        margin-right: 10px;
      }
    }

    ul {
      list-style: disc;
      margin-left: 35px;
    }

    ol {
      list-style: decimal;
      margin-left: 15px;

      li {
        margin-left: 15px;
        padding-left: 10px;

        &::marker {
          font-weight: bold;
        }
      }
    }

    & img {
      object-fit: cover;
      max-width: 100%;
      max-height: 416px;

      @include phone {
        max-height: 148px;
      }
    }

    @include phone {
      margin-right: 10px;
      padding-bottom: 20px;
    }
  }
}
