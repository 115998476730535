@import "../../../../vars";

.cr-back-button {
  position: relative;
  display: flex;
  align-items: center;
  font: normal normal normal 20px/26px var(--font-family-primary);
  letter-spacing: 0.15px;
  color: #65aae0;
  text-decoration: none;
  margin: 26px 0 24px;
  cursor: pointer;
  width: fit-content;

  @include tablet() {
    font: normal normal normal 16px/20px var(--font-family-primary);
    letter-spacing: 0.12px;
    margin-top: 10px;
  }

  @include phone() {
    font: normal normal normal 14px/19px var(--font-family-primary);
    letter-spacing: 0.1px;
  }

  &:hover {
    color: #00aeef;
    outline: none;
  }

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    transform: rotate(-90deg);

    @include tablet() {
      margin-right: 4px;
    }
  }

  &__text {
    white-space: nowrap;
  }
}
