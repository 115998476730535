.leisure-landing-final-modal {
  width: 420px;
  text-align: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    > * + * {
      margin-top: 20px;
    }
  }

  .circle-button-list {
    margin-left: auto;
    margin-right: auto;
    .circle-button__button {
      cursor: default;
    }
  }
}
