@import "../../../../../vars";

@mixin generate-grid-colums-template($columns-count) {
  @for $i from 1 through $columns-count {
    .grid-col-#{$i} {
      grid-template-columns: repeat($i, 1fr);
    }
  }
}

@mixin generate-grid-rows-template($rows-count) {
  @for $i from 1 through $rows-count {
    .grid-row-#{$i} {
      grid-template-rows: repeat($i, 1fr);
    }
  }
}

.category-button {
  width: 100%;
  height: 92px;
  font: normal normal bold 20px/24px Montserrat;
  white-space: normal;
  text-align: center;
  padding: 0 20px;
  text-transform: uppercase;
  border-radius: 20px;
  border: none;
  color: #fff;

  @include wide-tablet() {
    height: 76px;
    font-size: 16px;
  }

  @include phone() {
    height: 92px;
  }
}

.buttons-container {
  max-width: 1224px;
  margin-top: 24px;

  .invisible-slide {
    opacity: 1;
  }

  @include wide-tablet() {
    display: block;
    margin-top: 20px;
  }
}

.grid {
  display: grid;
  gap: 24px;
}

@include generate-grid-colums-template(4);
@include generate-grid-rows-template(4);
