@import "../../node_modules/reset-css/sass/reset";
@import "../_vars";
@import "../theme.scss";

.app-color {
  &-white {
    background-color: white;
  }

  &-lightblue {
    background-color: var(--base-ligth-blue);
  }

  &-lightgray {
    background-color: var(--base-light-gray);
  }

  &-darkblue {
    background-color: var(--color-primary);
  }

  &-gray {
    background-color: var(--base-gray);
  }
}

[class^="promo-icon-"],
[class*=" promo-icon-"] {
  font-size: 24px;
}

.role-icon {
  display: block;
  flex-shrink: 0;
  height: 18px;
  width: 18px;
  background-position: center;

  &.chief-expert-icon {
    background: url("../assets/icons/chief-expert-icon.png") 0 0 / contain
      no-repeat;
  }

  &.project-boss-icon {
    background: url("../assets/icons/project-boss-icon.png") 0 0 / contain
      no-repeat;
  }
}

.app {
  overflow: hidden;
  min-height: 100vh;

  .wrapper.zero-top-padding {
    padding-top: 0;
  }

  .type-info {
    z-index: 10000;
  }
}

.simple-btn {
  background: transparent;
  border: none;
  cursor: pointer;
}
