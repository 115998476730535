@import '../../../../../../vars';

.congratulation-card {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(37, 37, 38, 0.2);
  border-radius: 72px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  height: 338px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: var(--birthday9-black);
    margin: 21px 32px 32px;
    height: 100%;
    overflow: auto;
    padding-right: 5px;
    overflow-x: hidden;
    word-break: break-word;

    @include scrollbar(5px);
  }

  &__avatar {
    width: 145px;
    height: 145px;
    position: relative;

    @include phone() {
      width: 110px;
      height: 110px;
    }

    img {
      width: 124px;
      height: 124px;
      border-radius: 100%;
      object-fit: cover;
      z-index: 3;
      position: absolute;
      left: 10px;
      top: 10px;

      @include phone() {
        width: 90px;
        height: 90px;
      }
    }

    svg {
      position: absolute;
      left: 0;
      top: 0;

      @include phone() {
        width: 110px;
        height: 110px
      }
    }
  }

  &__name {
    margin: 0 auto 0 15px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: var(--birthday9-black);
    max-width: 56%;

    @include phone() {
      font-size: 16px;
    }

    > p {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .FreeScrollbar {
    &-vertical-track {
      background-color: white !important;
      border: none !important;
      width: 6px !important;
    }
  }
}
