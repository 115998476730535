@import "../../../../vars";

.submit-form__title {
  font-size: 30px;
  margin-top: 4px;
  font-family: var(--font-family-primary);
}

.propose-idea-form {
  &__subtitle {
    font-family: var(--font-family-primary);
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.15px;
    color: var(--base-text);
    margin: 0 0 8px;
  }

  &__description {
    font-family: var(--font-family-primary);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #808080;
  }

  &__catdesc {
    font-family: var(--font-family-primary);
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #8a8a8a;
    margin: 8px 12px 0;
  }

  &__radios {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__selects {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .cr-select {
      width: 48%;
      margin-top: 0;
    }
  }

  .cr-search {
    &__input {
      padding: 18px 70px 18px 16px;
      height: 41px;
      font-family: var(--font-family-primary);
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-overflow: ellipsis;

      span {
        text-overflow: ellipsis;
      }

      @include tablet() {
        padding-right: 70px !important;
      }
    }

    &__label {
      font-family: var(--font-family-primary);
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.44px;
      margin-bottom: 6px;
    }

    &-list {
      padding: 0;
      padding-top: 8px;
      max-height: 560px;

      @include tablet() {
        width: 100%;
      }

      @include phone() {
        right: 0;
        width: 100%;
      }

      &__item {
        padding: 8px 15px 8px 15px;
        font-family: var(--font-family-primary);
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-transform: none;
        color: var(--base-text);
        letter-spacing: 0;

        &:hover {
          background-color: #cceffc;
        }
      }
    }
    .search {
      font-size: 22px;
      text-align: center;
      height: 22px;
    }

    .cr-search__input:focus ~ .search {
      color: #888888;
    }

    .promo-icon-14 {
      font-size: 18px;
      color: #373b46;
      width: 20px;
      height: 20px;
      text-align: center;
      right: 48px;
      top: 12px;
    }
  }

  &__paragraph {
    margin-top: 5px;
    font-family: var(--font-family-primary);
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.44px;
    color: var(--base-text);

    &-require {
      color: #009dde;
    }
  }
  &__head {
    font-family: var(--font-family-primary);
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.12px;
    color: #282828;
    @include tablet() {
      margin-bottom: 16px;
    }
    @include phone() {
      font-family: var(--font-family-primary);
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.29px;
      margin-top: 20px;
    }
  }
  &__status {
    font-family: var(--font-family-primary);
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
    @include tablet() {
      font-family: var(--font-family-primary);
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
    }
    @include phone() {
      font-family: var(--font-family-primary);
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }
    a {
      text-decoration: none;
    }
  }
  &__spheres-title {
    font-family: var(--font-family-primary);
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
    @include tablet() {
      font-family: var(--font-family-primary);
      font-weight: bold;
      font-size: 22px;
      line-height: 29px;
      margin-bottom: 16px;
    }
    @include phone() {
      font-family: var(--font-family-primary);
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
    }
    span {
      color: #65aae0;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &__spheres-list {
    display: flex;
    flex-direction: column;
    p {
      font-family: var(--font-family-primary);
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 12px;
      color: #65aae0;
      overflow: hidden;
      text-overflow: ellipsis;
      @include tablet() {
        font-family: var(--font-family-primary);
        font-weight: bold;
        font-size: 18px;
        line-height: 26px;
      }
      @include phone() {
        font-family: var(--font-family-primary);
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .cr-checkbox {
    label {
      display: flex;
      align-items: center;
    }
    input {
      margin: 0 10px 0 0;
    }
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 500px) {
  .propose-idea-form {
    &__selects {
      flex-direction: column;
      > .cr-select {
        width: 100%;
      }
    }

    &__radios {
      flex-direction: column;
      align-items: flex-start;

      .cr-radio {
        margin: 5px 0;
      }
    }

    .cr-file-selector {
      &__head {
        flex-direction: column;
      }

      &__head-text {
        margin-left: 0;
        margin-top: 10px;
      }

      &__head-button {
        width: 100%;
        text-align: center;
      }
    }
  }
}
