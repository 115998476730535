@import "../../../../../vars";

.ny-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  z-index: 10;
  max-width: calc(100% - 32px);
  width: 700px;
  overflow: hidden;
  border-radius: 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--nato-blue);
    opacity: 0.9;
    z-index: -1;
  }

  @include medium-tablet {
    padding: 20px;
    max-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: 0;
    justify-content: center;
    &:before {
      opacity: 1;
    }
  }

  &,
  a {
    color: var(--enokitake-mushroom);
  }

  &__close-btn {
    position: absolute;
    top: 12px;
    right: 12px;
    border: none;
    outline: none;
    background: none;
    color: var(--enokitake-mushroom);
  }

  &__logo {
    margin-bottom: 16px;
  }

  &__title {
    font: normal normal 900 30px / normal Montserrat;
    margin-bottom: 10px;

    @include wide-tablet {
      font-size: 26px;
    }

    @include medium-tablet {
      font-size: 16px;
    }
  }

  &__content {
    font: normal normal 500 14px/20px Montserrat;
    @include medium-tablet {
      font-size: 12px;
      line-height: 18px;
    }

    a {
      font-weight: 700;
    }
  }
}
