@import "../../../../../../vars";

.game {
  &__wrapper {
    opacity: 0;
    transition: opacity 2s;
    position: relative;
    &--active {
      opacity: 1;
    }
  }
  &__snow {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url("../../../../../../assets/newyear2023/games/maze/snow.svg") center / cover;
  }

  &__maze {
    position: relative;
    touch-action: none;
    background: url("../../../../../../assets/newyear2023/games/maze/back pic.svg") center / cover no-repeat;
    padding: 9px 9px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 0 1.25px;
    width: 402.5px;
    height: 338px;
    @include medium-tablet() {
      width: fit-content;
      height: fit-content;
      margin: 0;
    }
  }

  &__mazeWrapper {
    position: relative;
  }

  &__rowWrapper {
    display: flex;
  }

  &__playerWrapper {
    top: 0;
    left: 0;

    width: 15px;
    height: 15px;
    background-color: transparent;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__playerImg{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__target {
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    position: absolute;
    display: flex;
    justify-content: center;
  }

  &__mazeTimer {
    @include medium-tablet() {
      position: fixed;
      left: 20px;
      top: 0;
    }
  }
}
