@import "../../../../../vars";

.active-session-form {
  &__title {
    font: normal normal bold 30px/37px Bebas Neue;
    letter-spacing: 0.12px;
    color: #282828;

    @include phone() {
      font: normal normal bold 24px/29px Bebas Neue;
      letter-spacing: -0.29px;
      margin-top: 20px;
    }
  }

  &__user {
    position: relative;
    border: 1px solid #65aae040;
    border-radius: 4px;
    padding: 16px 24px;
    margin-top: 24px;
    display: flex;
    align-items: center;

    @include phone() {
      margin-top: 20px;
    }
  }

  &__info {
    margin-left: 20px;
  }

  &__name {
    font: normal normal bold 14px/19px PT Sans;
    letter-spacing: 0.13px;
    color: #282828;
  }

  &__phone {
    font: normal normal normal 14px/19px PT Sans;
    letter-spacing: 0.13px;
    color: #8a8a8a;
    margin-top: 4px;
  }

  &__logo {
    position: absolute;
    right: 24px;
    top: 16px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    @include phone() {
      margin-top: 4px;
    }

    .cr-button {
      font: normal normal bold 14px/19px PT Sans;
      letter-spacing: 1.35px;
      padding: 12px 36px;
      margin-top: 16px;
    }
  }
}

.active-session-form-modal {
  max-width: 488px !important;
  padding: 48px;

  @include phone() {
    padding-top: 0;
  }

  .modal__close-btn {
    top: 24px;
    right: 24px;
  }
}
