.leisure-landing-popup {
  $root: &;

  position: relative;

  &__svg {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }

  &__bubble {
    color: var(--loden-purple);
    cursor: default;
    position: absolute;
    z-index: 1;
    display: none;
    max-width: 426px;
    width: max-content;
    min-height: 152px;
    padding: 28px 28px 64px;
    flex-direction: column;
    justify-content: center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--bright-dusk);
      clip-path: url("#leisurePopupClipPath");
      z-index: -1;
      opacity: 0.9;
    }

    &__top-block {
      font: normal normal 500 14px / 20px Montserrat;
      text-align: center;
    }

    &__bottom-block {
      font: normal normal 400 12px / normal Montserrat;
      text-align: center;
      margin-top: 10px;
    }

    &_left {
      transform: translate(calc(-100% + 60px), calc(-100% + 25px));

      &:before {
        transform: rotateY(180deg);
      }
    }

    &_right {
      transform: translate(-60px, calc(-100% + 25px));
    }

    &_shown {
      display: flex;
    }
  }
}
