.screen-stub {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../../../../../assets/newyear2023/screen-stub-bg.png) left bottom / cover no-repeat #240c13;
  z-index: 1000;

  & &__modal {
    width: 308px;
    color: var(--nato-blue);
    padding: 246px 30px 30px 30px;
    border-radius: 24px;
    height: auto;

    &:before {
      background-color: var(--enokitake-mushroom);
      opacity: 0.8;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: url(../../../../../assets/newyear2023/screen-stub-icon.svg) center 30px no-repeat;
    }
    .ny-modal {
      &__close-btn {
        display: none;
      }
      &__title {
        font-size: 18px;
        font-weight: 700;
      }
      &__content {
        font-weight: 400;
      }
    }
  }
}
