@import "../../../../../../vars";

.crowd-birthday-10-ideas-group {
  $root: &;

  width: 760px;
  padding-bottom: 26px;
  @include wide-tablet {
    padding-bottom: 15px;
  }

  &#{&}__reset-pos {
    margin: 0;
    position: absolute;
  }

  .swiper-container {
    overflow: hidden;
    width: 100%;
    position: relative;

    .swiper-wrapper {
      position: relative;
      z-index: 2;
      pointer-events: none;
    }
  }

  &__success-text {
    color: #3a9d3e;
    font: normal normal normal 20px/26px PT Sans;
    text-transform: uppercase;
    padding-left: 32px;
    background: url(../../../../../../assets/birthday10/icons/success.svg) 0 center no-repeat;

    @include wide-tablet {
      font-size: 11px;
      line-height: 14px;
      background-size: 14px;
      padding-left: 18px;
    }
  }

  &__idea {
    display: flex;
    gap: 24px;
    align-items: center;

    @include wide-tablet {
      gap: 14px;
    }

    img {
      width: 200px;
      height: 200px;
      border-radius: 16px;
      object-fit: cover;
      object-position: center;
      cursor: pointer;

      @include wide-tablet {
        width: 113px;
        height: 113px;
        border-radius: 9px;
      }
    }

    &__text {
      height: 200px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @include wide-tablet {
        height: 113px;
        gap: 9px;
      }

      > * {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        &:first-child {
          -webkit-line-clamp: 3;
        }
        &:last-child {
          -webkit-line-clamp: 2;
        }
      }
    }

    &_unreal {
      img {
        border: 6px solid #0072cb;
        pointer-events: all;

        @include wide-tablet {
          border-width: 3px;
        }
      }
    }
    &_found {
      img {
        border: 6px solid #3a9d3e;

        @include wide-tablet {
          border-width: 3px;
        }
      }
    }
  }

  &__nav {
    position: absolute;
    bottom: 0;
    left: 224px;
    display: flex;
    gap: 16px;
    z-index: 1;
    pointer-events: all;

    @include wide-tablet {
      left: 127px;
      bottom: 0;
      gap: 9px;
    }

    &-left,
    &-right {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: url(../../../../../../assets/birthday10/icons/arrow-bolt.svg) center no-repeat #b6d6f2;

      @include wide-tablet {
        width: 23px;
        height: 23px;
        background-size: 14px;
      }
    }

    &-right {
      transform: rotate(180deg);
    }
  }

  &__tooltip__inner {
    position: absolute;
    right: 16px;
    bottom: 11px;
    @include wide-tablet {
      right: 10px;
      bottom: 6px;
    }
  }

  .cr-tooltip {
    #{$root}__tooltip {
      border-radius: 10px;
      background-color: #7d8599;
      color: #fff;
      padding: 10px 13px 13px 13px;
      font: normal normal 400 14px/18.12px PT Sans;
      width: 240px;

      @include wide-tablet {
        border-radius: 5px;
        padding: 6px 2px 7px 7px;
        font-size: 7.92px;
        line-height: 10.25px;
        width: 134px;
      }

      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border: 6px solid #7d8599;
        border-left-color: transparent;
        border-bottom-color: transparent;
        bottom: -12px;
        right: 16px;
        left: unset;
        margin: 0;
        @include wide-tablet {
          right: 9px;
        }
      }
    }
  }

  &__tip {
    width: 40px;
    height: 40px;
    background: url(../../../../../../assets/birthday10/icons/info-question.svg) 0 0 / contain no-repeat;
    cursor: pointer;
    @include wide-tablet {
      width: 22px;
      height: 22px;
    }
  }

  &__photo-link {
    align-self: flex-start;
  }

  a {
    font: normal normal normal 20px/26px PT Sans;

    @include wide-tablet {
      font-size: 11px;
      line-height: 14px;
    }
  }
}
