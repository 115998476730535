@import "../../../vars";
$light-black: var(--base-text);
$grey: #aeaeae;
$blue: #0481e1;
$pale-blue: #65aae0;
$blue-icon: #049de1;
$darkblue: #1e266e;
$black: var(--base-text);

.propose-details {

  .propose-idea__cards-list {
    
    .cards-list {
      margin-top: 8px;
    }

    .cr-pagination {
      justify-content: center;
      
      &__arrow {
        cursor: pointer;
      }
    }
  }

  .page-description {
    @include medium-tablet() { 
      margin-top: 16px;
    }

    @include phone() {
      margin-top: 20px;
    }
  }

  .flippable-card {
    &__front,
    &__back {
      background-color: #ffffff;
    }

    &__back {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__front {
      position: static;
      height: fit-content;

      @include phone() {
        height: 100%;
      }
    }
  }

  flex: 1;
  &__controls-wrap {
    display: flex;
    align-items: center;
    margin-top: 24px;
    justify-content: flex-end;
    flex-wrap: wrap;
    @include adaptive(700) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 16px;
    }
    &--search {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      @include wide-tablet {
      }
      @include adaptive(700) {
        //flex-direction: column-reverse;
      }
      @include phone() {
        width: 100%;
      }
      .cr-search {
        max-width: 279px;

        @include adaptive(700) {
          max-width: none;
          margin-top: 20px;
        }

        .cr-search__input {
          padding-left: 20px !important;
        }
      }
    }


    .cr-button {
      letter-spacing: 0.67px;

      @include wide-tablet {
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.15px;
        padding: 11px 24px;
      }

      @include adaptive(700) {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 1.35px;
      }
    }
  }


  &__head {
    display: flex;
    align-items: center;

    @include wide-tablet() {
      flex-direction: column;
      align-items: start;
    }
  }

  &__statistic {
    display: flex;
    margin-left: auto;

    @include wide-tablet() {
      margin-left: unset;
    }

    @include phone() {
      box-shadow: 0px 2px 10px #0000001a;
      border-radius: 4px;
      padding: 20px;
      margin-top: 20px;
      flex-direction: column;
      width: 100%;
    }

    &-item {
      display: flex;
      flex-direction: column;
      margin-left: 32px;
      font: normal normal bold 18px/23px var(--font-family-primary);
      letter-spacing: 0px;
      color: #8a8a8a;
      &:first-child {
        margin-left: 0;
      }

      @include wide-tablet() {
        font: normal normal bold 16px/20px var(--font-family-primary);
      }

      @include phone() {
        margin-left: 0;
        flex-direction: row;
        align-items: center;
      }
    }

    &-count {
      font: normal normal bold 40px/43px var(--font-family-primary);
      letter-spacing: 0px;
      color: #65aae0;

      @include wide-tablet() {
        font: normal normal bold 32px/43px var(--font-family-primary);
      }

      @include phone {
        margin-right: 8px;
      }
    }
  }

  &__checkboxes {
    display: flex;
    margin-left: auto;

    @include phone() {
      order: 3;
      flex-direction: column-reverse;
      margin-left: 14px;
      margin-top: 20px;
      gap: 12px
    }

    .cr-checkbox {
      white-space: nowrap;
      color: $pale-blue;
      font: normal normal normal 16px/28px var(--font-family-primary);
      letter-spacing: 0.44px;
      margin: 0 24px 0 0;

      &:first-child {
        margin-left: 24px;
      }

      @include adaptive(700) {
        margin-top: 10px;
        margin-left: 12px !important;
      }

      @include phone() {
        font: normal normal normal 14px/19px var(--font-family-primary);
        margin-top: 0;

        &__input {
          margin-top: 0;
        }
        
        label {
          align-items: center;
        }
      }
       
    }
  }

  &__propose-btn {
    @include phone() {
      order: 1;
    }
  }

  &__inputs {
    display: flex;

    @include medium-tablet() {
      margin-left: 0;
      margin-top: 16px;
      width: 100%;
    }

    @include phone() {
      order: 2;
      flex-direction: column-reverse;
      margin-top: 20px;
    }

    input {
      @include medium-tablet() {
        height: 36px;
      }
    }

    .cr-district-selector {
      width: 278px;
      @include medium-tablet() {
        max-width: unset;
        width: 50%;
      }
      @include phone() {
        width: 100%;
        margin-top: 12px;
      }
    }

    .cr-search {
      width: 278px;
      @include medium-tablet() {
        width: 50%;
      }
      @include phone() {
        width: 100%;
      }
    }
  }

  .page-title {
    margin-top: 0;
    word-break: unset;
    width: min-content;
    white-space: nowrap;
    margin-right: 24px;

    @include phone {
      white-space: unset;
    }
  }

  .ideadetails-card-wrapper {
    width: calc((100% - (48px)) / 3);
    margin-top: 8px;
    margin-bottom: 8px;

    @include medium-tablet () {
      width: calc((100% - (24px)) / 2);
    }


    @include phone() {
      width: 100%;
    }

    .ideadetails-card {
      &__footer {
        .share-icon {
          background: $pale-blue;
          color: white;
          border-top-color: $pale-blue;
        }

        .cr-button {
          font: normal normal bold 20px/24px var(--font-family-primary);
          letter-spacing: 0.67px;
          padding: 9px 48px;

          @include adaptive(900) {
            font: normal normal bold 16px/19px var(--font-family-primary);
            letter-spacing: 0.53px;
            padding: 10px 24px;
          }

          @include phone() {
            width: initial;
          }

          @include adaptive(480) {
            padding: 9px 48px;
          }

          &:hover {
            background-color: var(--base-blue);
          }
        }
      }
    }
  }

  .ideadetails-card-wrapper:nth-child(1),
  .ideadetails-card-wrapper:nth-child(7) {
    .ideadetails-card {
        &__no-image img {
          content: url("../../../assets/propose-item/propose-item-gray.svg");
        }
      }
    }

  .ideadetails-card-wrapper:nth-child(2),
  .ideadetails-card-wrapper:nth-child(8) {
    .backside {
      .ideadetails-card__body {
        flex-direction: column !important;
      }
    }
    .ideadetails-card {
      &__no-image img {
          content: url("../../../assets/propose-item/propose-item-ligth-blue.svg");
      }
    }
  }

  .ideadetails-card-wrapper:nth-child(3),
  .ideadetails-card-wrapper:nth-child(9) {
    .ideadetails-card {
      &__no-image img{
        content: url("../../../assets/propose-item/propose-item-blue.svg");
      }
    }
  }

  .ideadetails-card-wrapper:nth-child(4),
  .ideadetails-card-wrapper:nth-child(10) {
    .frontside {
      .ideadetails-card__body {
        justify-content: flex-start;
      }
    }
    .ideadetails-card {
      &__no-image img {
        content: url("../../../assets/propose-item/propose-item-blue.svg");
      }
    }
  }

  .ideadetails-card-wrapper:nth-child(5),
  .ideadetails-card-wrapper:nth-child(11) {
    .backside {
        .ideadetails-card__body {
          flex-direction: column !important;
        }
      }

    .ideadetails-card {
      &__no-image img {
        content: url("../../../assets/propose-item/propose-item-gray.svg");
      }
    }
  }

  .ideadetails-card-wrapper:nth-child(6),
  .ideadetails-card-wrapper:nth-child(12) {
    .frontside {
      .ideadetails-card__body {
        justify-content: flex-start;
      }
    }
    .ideadetails-card {
      &__no-image img {
        content: url("../../../assets/propose-item//propose-item-ligth-blue.svg");
      }
    }
  }

  .ideadetails-card-wrapper:nth-child(3n + 2) {
    .ideadetails-card {
      &__body {
        flex-direction: column-reverse;
        @include phone {
          flex-direction: column;
        }
      }
    }
  }

  .ideadetails-card-wrapper:nth-child(1),
  .ideadetails-card-wrapper:nth-child(3),
  .ideadetails-card-wrapper:nth-child(4),
  .ideadetails-card-wrapper:nth-child(6),
  .ideadetails-card-wrapper:nth-child(7),
  .ideadetails-card-wrapper:nth-child(9),
  .ideadetails-card-wrapper:nth-child(10),
  .ideadetails-card-wrapper:nth-child(12) {
    .ideadetails-card {
      &__name {
        margin-top: 0;
      }
      
      .ideadetails-card__text-container {
        margin-bottom: 16px;

        @include phone() {
          margin-bottom: 0;
        }
      }
    }
  }

  .ideadetails-card-wrapper:nth-child(2n - 1) {
    @include medium-tablet() {
      .ideadetails-card {
        &__name{
          margin-top: 0;
        }

        &__text-container {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .ideadetails-card-wrapper:nth-child(2n) {
    @include medium-tablet() {
      .ideadetails-card {
        &__body {
          flex-direction: column;
        }

        &__text-container { 
          margin-top: 0;
          margin-bottom: 16px;
        }

        &__name {
          margin-top: 0;

          @include phone() {
            margin-top: 16px;
          }
        }
      }
    }
  }

  .cr-search__list {
    width: max-content;
    min-width: 100%;
    right: 0;
    flex-direction: column;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 12px 24px 24px;

    &-item {
      border: 1px solid var(--base-blue);
      padding: 4px 12px;
      letter-spacing: 1.35px;
      color: var(--base-blue);
      text-transform: uppercase;
      margin-right: 12px;
      margin-top: 12px;

      overflow: hidden;
      &:hover {
        background: var(--base-blue);

        color: #ffffff;
      }
    }
  }

  &__stub {
    padding: 122px 0;
    max-width: 808px;
    position: relative;
    margin: 18px auto 0;
    z-index: 1;
    text-align: center;
    font: italic normal normal 20px/28px var(--font-family-primary);
    letter-spacing: 0.16px;
    color: #8a8a8a;

    @include phone {
      padding: 0;
      margin: 50px auto 66px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.13px;
    }
    &:after {
      content: "";
      position: absolute;
      background-color: #f5f5f5;
      height: 100%;
      width: 100vw;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;

      @include phone {
        display: none;
      }
    }
  }
}

.card-grid-page__tags-list-tag {
  color: var(--base-blue);
  border: 1px solid var(--base-blue);
}
