@import "../../../../../../vars";
.new-year-2023-ball {
  cursor: pointer;
  position: relative;
  margin: auto;
  @include disableSelect;
  &__canvas {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__picture {
    width: 100%;
    height: 100%;
  }
  &--active {
    animation: wishShake 1.5s;
  }

  @keyframes wishShake {
    0%,
    100% {
      transform: translateX(0);
    }
    10%,
    30% {
      transform: translateX(-30px);
    }
    20%,
    40% {
      transform: translateX(30px);
    }
    50% {
      transform: translateX(-20px);
    }
    60% {
      transform: translateX(20px);
    }
    70% {
      transform: translateX(-10px);
    }
    80% {
      transform: translateX(10px);
    }
    90% {
      transform: translateX(-5px);
    }
  }
}
