@import "../../../pages/WorkResult/WorkResult.helper.scss";
@import "../../../../vars";

$card-desc-height: 392;
$card-tab-height: 348;
$card-mob-height: 280;

.content-card {
  height: #{$card-desc-height}px;
  position: relative;
  transition: 0.4s ease-out;
  cursor: pointer;
  border-radius: var(--round-corners);
  overflow: hidden;

  @include tablet() {
    height: #{$card-tab-height}px;
  }

  @include phone() {
    // для телефона фиксированный квадрат
    max-width: #{$card-mob-height}px;
    height: #{$card-mob-height}px;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover,
  &.with-stub {
    &:before {
      opacity: 1;
    }

    .content-card__fadeable {
      opacity: 1;
      transform: translateY(-100%);
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.5);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;

    @include phone() {
      height: 143px;
      bottom: 0;
      top: unset;
      opacity: 1;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__fadeable {
    position: relative;
    height: 100%;
    z-index: 3;
    opacity: 0;
    transform: translateY(-105%);
    transition: 0.5s;
    padding: 24px;
    color: #ffffff;
    overflow: hidden;
    height: #{$card-desc-height}px;

    @include tablet() {
      height: #{$card-tab-height}px;
    }

    @include phone() {
      height: #{$card-mob-height}px;
      opacity: 1;
      height: 143px;
      padding: 20px 23px 20px 20px;
      transform: translateY(-100%);
    }

    ol {
      @extend wr-ol;
    }

    ul {
      @extend wr-ul;
    }
  }

  &__info {
    -webkit-line-clamp: 9;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font: normal normal normal 20px/26px var(--font-family-primary);
    letter-spacing: 0px;

    @include tablet() {
      font: normal normal normal 18px/24px var(--font-family-primary);
      letter-spacing: 0.15px;
    }

    @include phone() {
      font: normal normal normal 16px/20px var(--font-family-primary);
      letter-spacing: 0px;
      -webkit-line-clamp: 3;
      max-height: 4em;
      flex-grow: 1;
    }
  }

  &__user {
    font: normal normal bold 20px/26px var(--font-family-primary);
    letter-spacing: 0px;

    @include phone() {
      font: normal normal bold 14px/19px var(--font-family-primary);
    }
  }

  &__quote {
    margin-top: 8px;

    &::before {
      content: "\e978";
      font-family: "promo-icons";
      color: white;
      float: left;
      margin-right: 10px;
      top: 4px;
      position: relative;
      font-size: 19px;

      @include phone() {
        font-size: 13px;
      }
    }

    @include phone() {
      display: flex;
    }
  }

  &__more {
    margin-top: 4px;
    font: normal normal bold 20px/26px var(--font-family-primary);
    letter-spacing: 0.15px;
    margin-left: 34px;

    @include tablet() {
      font: normal normal bold 18px/24px var(--font-family-primary);
      letter-spacing: 0.15px;
    }

    @include phone() {
      margin-left: unset;
      margin-top: unset;
      display: flex;
      align-self: flex-end;
    }

    .ic {
      position: absolute;
      right: 18px;
      bottom: 13px;

      @include phone {
        position: relative;
        right: unset;
        bottom: unset;
      }
    }
  }
}
