@import "../../../../../vars";

.discussion-landing-reviews {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include wide-tablet {
    gap: 20px;

    .common-slider__footer {
      min-height: unset;
    }
  }
  @include phone {
    gap: 16px;
  }
}
