.next-modal {
  position: relative;
  margin: auto 70px;
  width: 100%;
  height: fit-content;

  @media screen and (max-width: 500px) {
    margin: 0;
    padding-top: 0;
    width: 100%;
    min-height: 100%;
  }

  &__arrows {
    @media screen and (max-width: 500px) {
      width: 72px;
      margin: 20px auto 0;
      display: flex;
      justify-content: space-between;
      position: static;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    font-size: 30px;
    z-index: 2;
    cursor: pointer;
    color: white;

    @media screen and (max-width: 500px) {
      position: static;
      color: #000;
    }

    &-left {
      left: -75px;
    }

    &-right {
      right: -70px;
    }
  }

  &__carrows {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    margin-top: 35px;
    padding-bottom: 50px;
  }

  &__carrow {
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal normal 14px/19px PT Sans;
    letter-spacing: 0.1px;
    color: #65aae0;
    cursor: pointer;

    &-right {
      flex-direction: row-reverse;
    }
  }

  &__ctitle {
    width: max-content;
    margin: 0 7px;
  }

  .arrow__disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.next-modal-overlay {
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 500px) {
    padding: 0;
    display: block;
  }
}
