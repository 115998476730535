@import "../../../../vars";

.browser-support {
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__header {
    .logo {
      height: 232px;
      width: 232px;

      @include phone() {
        height: 144px;
        width: 144px;
      }
    }
  }

  &__body {
    margin-top: 72px;

    @include phone() {
        margin-top: 32px;
    }

    p {
      text-align: center;
      font: normal normal normal 20px/28px PT Sans;
      letter-spacing: 0.16px;
      color: #282828;

      @include tablet() {
        font: normal normal normal 18px/24px PT Sans;
        letter-spacing: 0.15px;
      }

      @include phone() {
        font: normal normal normal 16px/24px PT Sans;
        letter-spacing: 0.13px;
        max-width: 240px;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 250px;
    margin-top: 16px;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
    }
  }

  &__image {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }

  &__name {
    font: normal normal normal 20px/28px PT Sans;
    letter-spacing: 0.16px;
    color: #282828;
  }
}
