@import "../../../../../vars";

$popup-margin-top-bot: 32px;
$popup-margin-top-bot-tablet: 24px;
$popup-padding-right: 32px;
$popup-padding-right-tablet: 12px;
$popup-padding-top: 24px;
$popup-header-desktop: 72px;
$popup-header-tablet: 56px;
$popup-header-phone: 101px;


.result-popup {
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.95);
  width: 392px;
  height: calc(100% - #{$popup-margin-top-bot} * 2);
  margin: #{$popup-margin-top-bot} 24px;
  box-shadow: 0px 2px 10px #0000001a;
  padding: 0 0 0 32px;
  border-radius: var(--round-corners);
  z-index: 99;

  @include tablet() {
    width: 248px;
    height: calc(100% - #{$popup-margin-top-bot-tablet} * 2);
    margin: #{$popup-margin-top-bot-tablet} 24px;
    padding: 0 0 0 12px;
  }

  @include phone() {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    left: 0;
    top: 0;
    background: white;
    z-index: 101;
    padding: 0 0 0 20px;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 0px;
    left: 0;
    background: white;
    z-index: 100;
    width: 100%;
    padding: 24px 32px;
    border-radius: var(--round-corners);
    background: transparent;

    &--one {
      margin-bottom: 20px !important;
    }

    @include tablet() {
      padding: 16px 12px;
    }

    @include phone() {
     position: relative;
     display: block;
     padding: 0;
     margin-bottom: 12px;
    }

    .logo {
      display: none;
      margin-left: 0;
      margin-bottom: 10px;

      @include phone() {
        display: block;
        margin-bottom: 12px;
        margin-top: 8px;
        margin-left: 4px;
      }
    }

    .modal__close-btn {
      position: relative;
      top: unset;
      right: unset;
      margin-left: auto;

      @include phone() {
        position: absolute;
        top: 14px;
        right: 14px;
      }
    }
  }

  &__count {
    font: italic normal normal 16px/20px PT Sans;
    letter-spacing: 0px;
    color: #707070;

    @include tablet() {
      font: italic normal normal 14px/16px Helvetica Neue;
      letter-spacing: 0px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$popup-header-desktop} - 12px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 5px;
    margin-top: #{$popup-header-desktop};

    @include scrollbar(5px, 4px);

    @include tablet() {
      height: calc(100% - #{$popup-header-tablet} - 12px);
      margin-top: #{$popup-header-tablet};
    }

    @include phone() {
      height: calc(100% - #{$popup-header-phone} - 12px);
      padding-right: 12px;
      margin-top: 0;
    }
  }

  &__list {
    margin-right: 24px;

    @include tablet() {
      margin-right: 8px;
    }

    @include phone() {
      margin-right: 0;
    }
  }

  &__idea {
    &:not(:first-child) {
      margin-top: 40px;

      @include tablet() {
        margin-top: 24px;
      }
    }

    &-slider {
      height: 185px;

      @include tablet() {
        height: 126px;
      }

      @include phone() {
        height: 158px;
      }

      img {
        height: 185px;
        width: 100%;
        object-fit: cover;
        border-radius: var(--round-corners);

        @include tablet() {
          height: 126px;
        }

        @include phone() {
          height: 158px;
        }
      }

      .common-slider {
        &__footer {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 100;
          width: 100%;
        }

        .slider-arrows {
          justify-content: space-between;
        }

        .ic {
          color: white;
          opacity: 1;
          backdrop-filter: blur(10px);
          border-radius: 100%;
          padding: 10px;
          background-color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    &-title {
      font: normal normal bold 24px/32px PT Sans;
      letter-spacing: 0px;
      color: #65aae0;
      margin-top: 16px;

      a {
        text-decoration: none;
        color: #65aae0;
      }

      @include tablet() {
        font: normal normal bold 18px/23px PT Sans;
        letter-spacing: 0px;
        margin-top: 12px;
      }
    }

    &-description {
      font: normal normal normal 20px/26px PT Sans;
      letter-spacing: 0px;
      color: #282828;
      margin-top: 8px;

      @include tablet() {
        font: normal normal normal 16px/20px PT Sans;
        letter-spacing: 0px;
        margin-top: 4px;
      }
    }

    &-user,&-address  {
      margin-top: 10px;
      display: flex;
      align-items: center;

      img {
        width: 24px;
      }

      .promo-icon {
        color: #8a8a8a;
      }

      @include tablet() {
        margin-top: 8px;
      }

      &-name, &-street {
        font: normal normal normal 16px/20px PT Sans;
        letter-spacing: 0px;
        color: #8a8a8a;
        margin-left: 4px;

        @include phone() {
          font: normal normal normal 14px/19px PT Sans;
          letter-spacing: 0px;
        }
      }
    }

    &-comment {
      margin-top: 16px;
      font: normal normal normal 18px/23px PT Sans;
      letter-spacing: 0px;
      color: #282828;
      border: 1px solid #DEDEDE;
      border-radius: 4px;
      padding: 16px;

      @include tablet() {
        margin-top: 12px;
        font: normal normal normal 14px/19px PT Sans;
        letter-spacing: 0px;
      }
    }
  }
}
