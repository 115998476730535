@import "../../../../../../vars";

.photo-report-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 10px #0000001a;
  border-radius: 20px;
  overflow: hidden;

  &__header {
    flex-shrink: 0;
    position: relative;
    height: 260px;
    @include wide-tablet {
      height: 232px;
    }
    @include phone {
      height: 186px;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__footer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 16px 16px;
    @include phone {
      padding: 16px;
    }
  }

  &__text {
    font: normal normal bold 20px / 24px Montserrat;
    color: #282828;
    letter-spacing: 0px;
    margin-bottom: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    @include wide-tablet {
      height: 48px;
      -webkit-line-clamp: 2;
    }

    @include phone {
      height: 44px;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 16px;
    }
  }
}
