.leisure-landing-footer {
  height: 83px;
  padding: 13px 60px 20px;

  color: white;
  font: normal normal 500 14px / normal Montserrat;

  display: grid;
  grid-template-columns: 1fr auto 1fr;

  &__block {
    align-self: center;

    &-left {
      justify-self: flex-start;
    }
    &-center {
      justify-self: center;
    }
    &-right {
      justify-self: flex-end;
    }
  }

  &__external-link {
    background: url("../../../../../assets/leisure-landing/external-icon.svg") right center no-repeat;
    padding-right: 38px;
    line-height: 28px;
    margin-right: 30px;
    display: inline-block;
    text-decoration: none !important;
  }

  &__help-link {
    margin-right: 20px;
  }
}
