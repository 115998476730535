.cr-map {
  height: 224px;
  width: 100%;
  position: relative;
  border-radius: var(--round-corners);
  overflow: hidden;

  .map-placeholder {
    height: 100%;
  }

  &__popup {
    height: 300px;
    width: 300px;
  }

  &__location {
    position: absolute;
    right: 24px;
    bottom: 90px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #0000001a;
    border-radius: 4px;
    border: unset;
    padding: 5px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .cr-map {
    height: 280px;
  }
}
