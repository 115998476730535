@import "../../../vars";

.wrap-fixed {
  max-width: 1272px;
  margin: 0 auto;
}

.wr-page {
  width: 100%;

  padding-bottom: 25px;

  .data-loader-wr {
    height: 100%;
  }

  &__page {
    > .wr-page__wrapper {
      padding-top: 0;
      @include phone() {
        padding-bottom: 0;
      }
    }
  }

  &__viewer {
    max-width: 1064px;
    padding: 0;
  }

  &__back {
    @extend .wrap-fixed;
    padding: 0 24px;
  }

  &__stab {
    font: italic normal normal 20px/28px var(--font-family-primary);
    letter-spacing: 0.16px;
    color: #8a8a8a;
    background-color: #f5f5f5;
    text-align: center;
    padding: 163px 0;

    @include tablet() {
      font: italic normal normal 18px/24px var(--font-family-primary);
      letter-spacing: 0.15px;
      color: #8a8a8a;
      padding: 225px 0;
    }

    @include phone() {
      font: italic normal normal 16px/24px var(--font-family-primary);
      letter-spacing: 0.13px;
      padding: 60px 0;
      background-color: white;
    }
  }

  &__prblock {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &__title {
    font: normal normal bold 48px/48px var(--font-family-primary);
    letter-spacing: -0.4px;
    color: #282828;

    @include tablet() {
      font: normal normal bold 28px/28px var(--font-family-primary);
      letter-spacing: 0.21px;
    }
  }

  &__wrapper {
    @extend .wrap-fixed;
    padding: 32px 24px;
  }

  &__heading {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &-name {
      font: normal normal bold 60px/72px var(--font-family-primary);
      letter-spacing: -0.5px;
      color: #282828;

      @include tablet() {
        font: normal normal bold 50px/61px var(--font-family-primary);
        letter-spacing: -0.42px;
      }

      @include phone() {
        font: normal normal bold 40px/40px var(--font-family-primary);
        letter-spacing: -0.33px;
      }
    }

    &-desc {
      font: normal normal normal 20px/28px var(--font-family-primary);
      letter-spacing: 0.16px;
      margin-top: 24px;

      @include tablet() {
        font: normal normal normal 18px/24px var(--font-family-primary);
        letter-spacing: 0.15px;
      }

      @include phone() {
        font: normal normal normal 16px/24px var(--font-family-primary);
        letter-spacing: 0.13px;
        margin-top: 16px;
      }
    }

    &-img {
      max-height: 196px;
      max-width: 392px;
      width: 100%;
      object-fit: cover;
      margin-left: 24px;
      border-radius: var(--round-corners);

      @include tablet() {
        max-width: 268px;
        max-height: unset;
        height: 224px;
      }

      @include phone() {
        height: 130px;
        width: 100%;
        margin: 20px 0 0;
        max-width: unset;
      }
    }
  }

  &__ideas {
    background: #f5f5f5;

    .wr-page__wrapper {
      @include phone() {
        padding-right: 0;
        padding-left: 0;

        .wr-page__title,
        .progress-bar,
        .progress-count {
          margin-right: 20px;
          margin-left: 20px;
        }

        .swiper-container {
          .content-card {
            max-width: unset;
          }
        }
      }
    }

    @include phone() {
      background-color: white;
    }

    .progress-count {
      font: normal normal normal 20px/28px var(--font-family-primary);
      letter-spacing: 0.16px;
      color: #282828;
      margin-top: 16px;

      @include tablet() {
        font: normal normal normal 18px/24px var(--font-family-primary);
        letter-spacing: 0.15px;
      }
    }

    &-count {
      letter-spacing: 0.16px;
      color: #282828;
      margin-top: 16px;
    }

    .progress-bar {
      height: 12px;
      margin-top: 8px;
    }

    .common-slider {
      margin-top: 16px;
    }
  }

  &__inprogress {
    .common-slider {
      margin-top: 32px;

      @include phone() {
        margin-top: 20px;
      }

      .inprogress-card {
        margin: 5px;
      }
    }

    .inprogress-card {
      height: 330px;

      @include tablet() {
        height: 255px;
      }

      @include phone() {
        height: 307px;
      }
    }
  }

  &__remained {
    font: normal normal normal 20px/28px var(--font-family-primary);
    letter-spacing: 0.16px;
    color: #282828;
    margin-top: 24px;

    @include tablet() {
      font: normal normal normal 18px/24px var(--font-family-primary);
      letter-spacing: 0.15px;
    }

    @include phone() {
      font: normal normal normal 16px/24px var(--font-family-primary);
      letter-spacing: 0.13px;
      margin-top: 20px;
    }
  }

  &__more {
    @include phone() {
      margin-top: 0;
    }

    .wr-page__wrapper {
      // padding-top: 0;
      @include phone() {
        padding: 20px 24px;
      }
    }

    .cr-button {
      margin-top: 24px;
      font: normal normal bold 14px/19px var(--font-family-primary);
      letter-spacing: 1.35px;
      padding: 12px 24px;

      @include tablet() {
        font: normal normal bold 14px/19px var(--font-family-primary);
        letter-spacing: 1.35px;
      }

      @include phone() {
        font: normal normal bold 14px/19px var(--font-family-primary);
        letter-spacing: 1.35px;
        margin-top: 0;
      }
    }

    .wr-page__title {
      @include phone() {
        display: none;
      }
    }
  }

  &__idea-lists {
    font-family: var(--font-family-primary);
    .wr-page__wrapper {
      display: flex;
      gap: 20px;
    }
  }

  .accepted-slider-group {
    > * + * {
      margin-top: 30px !important;
    }
  }
}
