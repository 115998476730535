@import "../../../../../../vars";

.welcome-modal {
  margin: auto;
  @include phone {
    margin-top: 0;
  }

  &__headline {
    font: normal normal 700 42px/58px PT Sans;
    text-align: center;

    @include wide-tablet {
      font-size: 28px;
      line-height: 36px;
    }
  }

  &__subheadline {
    font: normal normal 400 22px/28px PT Sans;
    text-align: center;

    @include wide-tablet {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__button {
    width: 300px;
    margin: 0 auto;
    gap: 7px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include wide-tablet {
      width: 167px;
    }
  }

  &__stars {
    width: 48px;
    height: 42px;

    @include wide-tablet {
      width: 27px;
      height: 21px;
    }
  }
}

#crowd-birthday-10:has(.welcome-modal) {
  @include phone {
    padding-top: 63px;
  }
}
