@import "../../../../vars";

.olm-loader {
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .data-loader-wr {
    .data-loader {
      width: 80px;
      height: 80px;

      @include phone() {
        width: 56px;
        height: 56px;
      }

      &::after {
        width: 100%;
        height: 100%;
        border: 4px solid #8a8a8a;
        border-right-color: #65aae0;
      }
    }
  }
}
