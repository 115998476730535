.moderation {
  font-size: 16px;

  &__title {
    font-weight: bold;
    text-align: center;
  }

  &__subtitle {
    font-family: sans-serif;
    font-size: 16px;
    margin: 16px 0;
  }

  &__list {
    margin: 16px 0;
    padding-left: 40px;
    list-style-type: decimal;

    li {
      margin: 5px 0;
    }
  }

  ol {
    list-style-type: decimal;
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}

.type-disc {
  list-style-type: disc;
}