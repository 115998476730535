.crowd-birthday-10-map-block {
  @supports not (-webkit-touch-callout: none) {
    backface-visibility: hidden;
    transform: translateZ(0);
  }

  &_disabled {
    pointer-events: none;
  }

  &__pin {
    position: absolute;
    transform-origin: 0% 0%;
  }

  &__lottie,
  img {
    pointer-events: none;
    position: absolute;
  }
  &__lottie svg {
    transform: unset !important;
  }
}
