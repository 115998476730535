@import "../../../../vars";

.spherecard {
  text-decoration: none !important;
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 2px 10px #0000001a;
  height: 243px;
  position: relative;

  @include phone() {
    flex-direction: column;
    max-height: none;
    height: 100%;
  }

  &__image {
    max-width: 208px;
    width: 100%;
    background-size: cover;
    background-position: center;

    @include phone() {
      max-width: none;
      height: 200px;
    }

    @include adaptive(350) {
      height: 140px;
    }

    > img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;

      @include phone() {
        max-height: 160px;
      }
    }
  }

  &__tooltip {
    .__react_component_tooltip {
      background-color: #777b8c;
      box-shadow: 0px 4px 20px #0000001a;
      text-transform: capitalize;
      margin: 0;
      font-family: var(--font-family-primary);
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0px;
      color: #ffffff;
      border-radius: var(--round-corners);
      padding: 16px;
      text-transform: inherit;

      &::before,
      &::after {
        content: none;
      }
    }
  }

  &__body {
    width: 100%;
    padding: 11px 24px 24px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @include phone() {
      padding: 23px 12px 12px;
    }

    &-icon {
      background: transparent;
      outline: none;
      border: none;
      color: var(--base-blue);
      margin-top: 6px;
    }
  }

  .custom-scrollbar {
    width: 100%;
    max-height: 75px;

    .ScrollbarsCustom-TrackY {
      width: 7px !important;
      background-color: #eff6fc !important;
    }

    .ScrollbarsCustom-ThumbY {
      background-color: rgba(28, 28, 28, 0.3) !important;
    }
  }

  &__list {
    width: 100%;
    font-family: var(--font-family-primary);
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #65aae0;
    word-break: break-word;

    @include phone() {
      font-family: var(--font-family-primary);
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0px;
      margin-top: 20px;
    }

    li {
      line-height: 1.5em;
      @include phone() {
        line-height: 1.5em;
      }
    }
  }

  &__link {
    text-decoration: none !important;
  }

  &__control {
    font-family: var(--font-family-primary);
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1px;
    color: #65aae1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    display: none;
    @include phone() {
      display: flex;
    }

    .ic {
      transform: rotate(90deg);
      transform: rotate(90deg);
      width: 15px;
      height: 15px;
      font-size: 15px;
      margin-left: 5px;
    }
  }

  &__heading {
    width: 100%;
    font-family: var(--font-family-primary);
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.1px;
    color: var(--base-text);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include phone() {
      font-family: var(--font-family-primary);
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.1px;
    }
  }

  &__title {
    margin-top: 6px;
    display: flex;
    align-items: start;
  }

  &__name,
  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    @include phone() {
      display: initial;
    }
  }

  &__name {
    -webkit-line-clamp: 2;
    &:hover {
      color: #3c8de2;
    }
  }

  &__label {
    font-family: var(--font-family-primary);
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0px;
    color: var(--base-blue);
    text-transform: uppercase;
    border: 1px solid var(--base-blue);
    border-radius: var(--round-corners-sm);
    padding: 1px 3px;
    margin-left: 4px;
    top: -6px;
    position: relative;
  }

  &__description {
    margin-top: 12px;
    margin-bottom: auto;
    font-family: var(--font-family-primary);
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.32px;
    color: #282828;
    -webkit-line-clamp: 3;

    @include tablet() {
      font-family: var(--font-family-primary);
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.15px;
    }

    @include phone() {
      font-family: var(--font-family-primary);
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0px;
    }
  }
}
