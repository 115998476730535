@import "../../../../vars";

.job-reports {
  &-modal {
    max-width: 440px !important;

    @include tablet() {
      max-width: 396px !important;
    }

    @include phone() {
      max-width: none !important;
      padding-top: 0 !important;
    }
  }

  &__title {
    font: normal normal bold 24px/29px var(--font-family-primary);
    letter-spacing: 0.18px;
    color: $black-text;
  }

  &__description {
    font: normal normal normal 18px/23px var(--font-family-primary);
    letter-spacing: 0.13px;
    color: $black-text;
    margin-top: 24px;

    @include tablet() {
      font: normal normal normal 16px/24px var(--font-family-primary);
      letter-spacing: 0.13px;
    }

    @include phone() {
      font: normal normal normal 16px/20px var(--font-family-primary);
      letter-spacing: 0.12px;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    @include phone() {
      margin-top: 20px;
    }
  }

  &__link {
    .button {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .empty-reports-message {
    margin-top: 10px;
    font: normal normal normal 16px/20px var(--font-family-primary);
    color: $gray;

    @include tablet() {
      font: normal normal normal 14px/20px var(--font-family-primary);
      letter-spacing: 0.13px;
    }

    @include phone() {
      font: normal normal normal 12px/16px var(--font-family-primary);
      letter-spacing: 0.12px;
    }
  }

  &__loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}
