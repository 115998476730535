.likePopup {
  display: flex;
  background-color: #fff;
  overflow: hidden !important;
  &__avatar {
    border-radius: 72px;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  &__avatarImg {
    width: 100%;
    height: 100%;
    display: block;
  }

  .data-loader-wr {
    width: 40px;
    height: 40px;
    position: relative;
  }
  .data-loader {
    width: 40px;
    height: 40px;
    &::after {
      width: 40px;
      height: 40px;
    }
  }

  .swiper-slide:last-child {
    margin-right: 0 !important;
  }
}
