.discussion-landing-sign {
  background: url("../../../../../assets/discussion-landing/icon-quote.svg") 0 7px no-repeat;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 38px;

  &__name,
  &__position {
    max-width: 293px;
    color: var(--base-text);
    text-align: right;
  }
  &__name {
    font: normal normal bold 14px/18px Montserrat;
    margin-bottom: 2px;
  }
  &__position {
    font: normal normal 500 10px/12px Montserrat;
    white-space: pre-line;
  }
}
