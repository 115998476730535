@import "../../../../vars";

.wrapper {
  height: 44px;
  width: 100%;
  background: transparent
    linear-gradient(86deg, #2e0e8d 0%, #2e0e8d 13%, #0d0847 41%, #00173e 100%)
    0% 0% no-repeat padding-box;
  transition: all 0.3s ease-in-out;
}

.wrapper-hidden {
  height: 0;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
  height: 100%;
  padding: 8px 40px;
  display: flex;
  gap: 191px;
  overflow: hidden;
  --header-top-section-font-color: #ffffff;

  &::-webkit-scrollbar {
    display: none;
  }

  @include hd() {
    gap: 120px;
  }

  @include wide-tablet() {
    padding: 8px 24px;
    gap: 16px;
    gap: 24px;
  }

  @include phone() {
    padding: 8px 20px;
    padding-right: 36px;
    gap: 36px;
  }

  .link-text {
    color: var(--header-top-section-font-color);
    letter-spacing: 0px;
  }

  .mayor {
    height: 100%;
    flex-shrink: 0;
  }
}

.links-container {
  height: 100%;
  width: 100%;
  max-width: 1128px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  @include phone() {
    gap: 16px;
  }
}

.links {
  width: 100%;
  text-wrap: nowrap;
  display: flex;
  justify-content: space-between;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @include wide-tablet() {
    justify-content: center;
    gap: 16px;
  }

  @include adaptive(650) {
    justify-content: flex-start;
  }

  @include phone() {
    width: 504px;
  }
}
