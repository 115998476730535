@import "../../../../vars";

.cr-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 24px;
  font: 16px var(--font-family-primary);
  letter-spacing: 0.44px;

  label {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    width: 100%;
  }

  &__input {
    appearance: none;
    width: 18px;
    height: 18px;
    position: relative;
    cursor: pointer;
    margin: 4px 10px 0 0;
    flex-shrink: 0;
    border-radius: var(--round-corners);

    &-invalid {
      border-color: $error;
      box-shadow: 0 0 0 1px $error;
    }

    &:checked {
      &::before {
        position: absolute;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAMAAAD3JJ6EAAAAKlBMVEUAru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru+eFSKnAAAADXRSTlMAAgMGS2Kcnp+vsLG2CF5vNAAAADFJREFUeAFjgAJGNlYoi4mdlw3OYmdCZrFwMsPEuHh5OHjZGUGSzDy8YBaYzc0GYQEAKy4BHJzULZ8AAAAASUVORK5CYII=);
        content: "";
        width: 18px;
        height: 18px;
        left: 0;
        top: 1px;
        display: inline-block;
        background-position: 3px 4px;
        background-repeat: no-repeat;
        z-index: 5;
        margin-left: auto;
        margin-right: auto;
        right: 0;
        text-align: center;
      }
    }
  }
}
