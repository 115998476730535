.leisure-landing-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  min-width: 220px;
  z-index: 99;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    background: var(--bright-dusk);
    opacity: 0.9;
    z-index: -1;
  }

  &__close-btn {
    width: 24px;
    height: 24px;
    background: url(../../../../../assets/leisure-landing/close-icon.svg) center no-repeat;
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    outline: none;
    z-index: 1;
  }

  &__title {
    color: var(--loden-purple);
    text-align: center;
    font: normal normal 700 20px / normal Montserrat;
    margin-bottom: 10px;
  }

  &__content {
    color: var(--loden-purple);
    font: normal normal 400 14px/20px Montserrat;
  }
}

.leisure-landing-modal-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  width: calc(100% / var(--bg-scale));
  height: calc(100% / var(--bg-scale));
}
