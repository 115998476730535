.restore-password-form {
  font: 18px/20px PT Sans;
  letter-spacing: 0.15px;
  color: var(--base-text);
  max-width: 500px;
  width: 100%;
  margin: 0 auto;

  &__error {
    color: #ff6666;
    font-size: 14px;
    margin: 10px 0 0;
  }

  &__input {
    margin-top: 15px;
  }

  .cr-button {
    margin-top: 15px;
  }
}
