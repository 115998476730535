@import "../../../../../../vars";

.comment-card {
  $root: &;

  background-color: #eefbff;
  padding: 24px;
  position: relative;
  border-radius: 20px;

  &--fixed {
    min-height: 316px;

    #{$root}__content-top {
      flex-basis: 200px;

      @include phone() {
        flex-basis: 290px;
      }
    }
  }

  @include tablet() {
    padding: 16px;
  }

  &__label {
    font: normal normal bold 12px/16px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    background-color: #ffb867;
    padding: 4px 12px;
    width: fit-content;
    border-radius: var(--round-corners);
    text-transform: uppercase;
    position: absolute;
    top: -13px;

    @include phone() {
      top: -15px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__date {
    font: normal normal normal 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #7e7e7e;

    @include phone() {
      font: normal normal normal 12px/18px Montserrat;
      text-align: right;
      margin-top: 8px;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__tools {
    display: flex;
    align-items: center;
    gap: 24px;

    @include tablet() {
      gap: 16px;
    }

    &-copy {
      color: #7e7e7e;
      transform: rotate(-45deg);
      cursor: pointer;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    &-info {
      margin-left: 16px;
    }

    &-name {
      font: normal normal bold 20px/24px Montserrat;
      letter-spacing: 0px;
      color: #282828;

      @include phone() {
        font: normal normal bold 16px/20px Montserrat;
      }
    }
  }

  &__body-wrapper {
    position: relative;
    letter-spacing: 0px;
  }

  &__body {
    font: normal normal normal 20px/32px Montserrat;
    color: #282828;
    margin-left: 64px;
    margin-top: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include phone() {
      font: normal normal normal 16px/24px Montserrat;
      margin-left: 0;
    }
  }

  &__show-more-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__show-more {
    font: normal normal normal 20px/24px Montserrat;
    color: var(--color-primary);
    text-decoration: underline;
    border: none;
    background: transparent;
    outline: none;

    @include phone() {
      font: normal normal normal 16px/19px Montserrat;
    }
  }

  &__footer {
    margin-left: 64px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include phone() {
      margin-top: 16px;
      margin-left: 0;
    }

    &--is-overflowing {
      margin-top: 20px;

      @include phone() {
        margin-top: 14px;
      }
    }

    .cr-button {
      font: normal normal bold 20px/24px Montserrat;
      letter-spacing: 0px;
      padding: 12px 32px;

      @include tablet() {
        padding: 12px 24px;
      }

      @include phone() {
        font: normal normal bold 14px/18px Montserrat;
        letter-spacing: 0px;
        width: auto;
        padding: 10px 27px;
      }
    }
  }

  &__popupWrapper {
    margin-left: auto;
  }

  &__popup {
    .popup {
      border-radius: 44px;
      padding: 16px;
      right: -5px;
      left: initial;
      top: -82px;
      min-width: 72px;
      cursor: auto;
      @include phone() {
        right: -12px;
      }
    }
    .swiper-container {
      max-width: 280px;
      height: 40px;
    }
  }

  &__offset {
    .popup {
      padding: 16px;
    }
  }
}
