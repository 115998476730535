@import "../../../../../vars";

.birthday-congratulation {
  &__inner {
    margin-top: 120px;
    display: flex;
    flex-direction: column;

    @include phone() {
      margin-top: 100px;
    }
  }

  &__subtitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: var(--birthday9-black);
    max-width: 1200px;
    margin: 10px auto 0;
  }

  .cr-button {
    height: 60px;
    border-radius: 18px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 38px;
    margin: 40px auto 0;
  }

  &__nocongrats {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: var(--birthday9-gray);
    max-width: 1200px;
    margin: 10px auto 0;
  }

  &__slider {
    margin-top: 40px;
    position: relative;

    @include phone() {
      width: 110%;
      left: -5%;
    }
  }

  .congratulation-slider {
    &__arrows {
      @include phone() {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 40%;
        margin: 20px auto 0;
      }
    }

    &__arrow {
      position: absolute;
      color: var(--birthday9-gray);
      cursor: pointer;
      z-index: 1;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;

      @include phone() {
        position: static;
        transform: unset !important;
      }

      .ic {
        font-size: 40px;
      }

      &-left {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("../../../../../assets/birthday9/icons/icon-left.svg");

        @include adaptive(1300) {
          left: -20px;
        }
      }

      &-right {
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("../../../../../assets/birthday9/icons/icon-right.svg");
        background-position: right;

        @include adaptive(1300) {
          right: -20px;
        }
      }
    }
  }

  // OVERRIDES
  .common-slider {
    max-width: 1300px;
    margin: 0 auto;

    .swiper-slide {
      &:nth-child(3n + 1) {
        svg {
          fill: var(--birthday9-yellow);
        }
      }
      &:nth-child(3n + 2) {
        svg {
          fill: var(--birthday9-july);
        }
      }
      &:nth-child(3n + 3) {
        svg {
          fill: var(--birthday9-violet);
        }
      }
    }

    .congratulation-card {
      margin: 20px;

      @include adaptive(1300) {
        // margin: 20px 40px;
      }

      @include phone() {
        margin: 17px
      }
    }

    &__footer {
      display: none;
    }
  }
}
