@import "../../../../../vars";

.node-with-stage-selector {
  $root: &;
  letter-spacing: 0;

  &__modal {
    padding: 48px;
    max-width: 800px !important;
    @include adaptive(500) {
      padding: 0 20px 20px !important;
    }
  }

  &__title {
    font: normal normal bold 36px/38px Bebas Neue;
    margin-bottom: 24px;
    @include tablet() {
      font-size: 32px;
      line-height: 38px;
    }
    @include phone() {
      font-size: 24px;
      line-height: 29px;
    }
    @include adaptive(500) {
      margin-top: 20px;
    }
  }

  &__description {
    font: normal normal normal 18px / 28px PT Sans;
    color: var(--base-text);
    @include tablet() {
      line-height: 24px;
    }
    @include phone() {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__subhead {
    margin-top: 24px;
    font: normal normal bold 24px / 28px PT Sans;
    @include phone() {
      font-size: 20px;
      line-height: 26px;
    }
  }

  a {
    color: var(--base-blue);
    &:hover {
      text-decoration: none;
    }
  }

  &__stage-item {
    padding-top: 32px;
    cursor: pointer;
    @include phone() {
      padding-top: 24px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d7d9e0;
      padding-bottom: 32px;
      @include phone() {
        padding-bottom: 24px;
      }
    }

    &__title {
      font: normal normal bold 20px / 28px PT Sans;
      color: var(--base-blue);
      margin-bottom: 4px;
      @include tablet() {
        font-size: 18px;
        line-height: 26px;
      }
      @include phone() {
        font-size: 16px;
        line-height: 22px;
      }
    }

    &__countdown {
      font: italic normal normal 18px / 24px PT Sans;
      color: #757679;
      @include tablet() {
        line-height: 28px;
      }
      @include phone() {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}
