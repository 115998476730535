.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.img {
  width: 32px;
  height: 32px;
  filter: grayscale(100%);
  transition: filter 0.15s ease-in-out;

  &:hover {
    filter: grayscale(0%);
  }
}
