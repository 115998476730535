@import "../../../vars";

.announcement,
.current {
  &__list {
    margin-top: 36px;
    margin-bottom: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .project-flipcard {
      margin-right: 12px;
      margin-left: 12px;
      margin-bottom: 24px;
      width: 288px;
      height: 496px;

      &-inner__front-footer {
        height: 136px;
        padding-top: 0;
        &-name {
          align-items: center;
          flex-grow: 1;
        }
      }
      @include tablet() {
        width: 224px;
        height: 416px;
        &-inner__front-logo {
          height: 280px;
        }
        &-inner__front-footer {
          &-name {
            font: Bold 16px/20px PT Sans;
            letter-spacing: 0.15px;
          }
        }
      }

      @include phone() {
        width: 280px;
        height: 486px;
        margin-left: 0;
        margin-right: 0;
        &-inner__front-logo {
          height: 350px;
        }
      }
    }
  }
}
