.modal {
  &__mobile-header {
    display: none;
  }

  &__mobile-profile {
    position: absolute;
    right: 64px;
    top: 10px;
    height: 40px;
    width: 40px;

    img {
      border-radius: 50%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .modal {
    &__mobile-header {
      display: flex;
      align-items: flex-start;
      height: 60px;

      .logo {
        margin-top: 8px;
        margin-left: 4px;
      }
    }
  }
}
