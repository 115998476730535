@import "../../../../../vars";

.wishesBall {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 555px;
  margin: auto;
  @include medium-tablet() {
    width: 100%;
  }

  &__load {
    @include medium-tablet() {
      height: 100%;
    }
  }

  &__textWrapper {
    text-align: center;
    padding-bottom: 74px;
  }

  &__title {
    font-size: 30px;
    font-weight: 900;
    line-height: 36px;
  }

  &__subTitle {
    margin: 10px 0 15px;
    @include medium-tablet() {
      margin: 10px 0 0px;
    }
  }

  &__ball {
    @include medium-tablet() {
      transform: scale(var(--ballScale)) translateY(var(--ballTranslate));
      position: absolute;
      bottom: 68px;
      left: 0;
      right: 0;
    }
  }

  &__snowWrapper {
    pointer-events: none;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 214px;
    left: 0;
    @include medium-tablet() {
      transform: translateY(var(--snowTranslate));
      bottom: -43px;
      right: 0;
      pointer-events: auto;
    }
  }

  &__snow {
    pointer-events: none;
    background: url("../../../../../assets/newyear2023/games/wishBall/snow.svg") top / contain no-repeat;
    height: 100%;
    @include medium-tablet() {
      transform: scale(var(--snowScale));
      width: 568px;
      height: 170px;
      background: url("../../../../../assets/newyear2023/games/wishBall/snow mobile.svg") top / contain no-repeat;
      margin: auto;
    }
  }

  &__wishTextWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    transition: opacity 0.5s;
    opacity: 0;
    padding: 40px 30px 0;
    &--active {
      opacity: 1;
    }
    @include medium-tablet() {
      padding-top: 49px;
    }
  }

  &__wishText {
    font-size: 24px;
    color: var(--nato-blue);
    line-height: 29px;
    @include medium-tablet() {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__wishSubText {
    font-size: 16px;
    color: var(--nato-blue);
    margin-top: 10px;
    @include medium-tablet() {
      font-size: 13px;
      margin-top: 0px;
    }
  }
}
