@import "../../../../../vars";

.birthday-main {
  &__inner {
    display: flex;
    align-items: center;
    margin-top: 70px;

    @include short-desktop() {
      margin-top: 30px;
      flex-direction: column;
    }
  }

  &__info {
    margin-right: 100px;

    @include short-desktop() {
      margin: 0 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__years {
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 140px;
    line-height: 140px;
    text-transform: uppercase;

    @include short-desktop() {
      br {
        display: none;
      }
    }

    @include medium-tablet() {
      font-size: 90px;
      line-height: 90px;
      text-align: center;
    }

    @include phone() {
      font-size: 54px;
      line-height: 54px;
      font-weight: 400;
    }
  }

  &__about {
    width: 120%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    max-width: 404px;
    color: var(--birthday9-black);
    margin-top: 20px;

    @include short-desktop() {
      width: 100%;
      max-width: 956px;
      text-align: center;
      font-size: 18px;
      line-height: 28px;
    }

    @include medium-tablet() {
      margin-top: 10px;
    }
  }

  &__celebration {
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    display: flex;

    @include short-desktop() {
      align-items: center;
      justify-content: center;
      margin-top: 40px;
    }

    &-bg {
      width: 100%;

      @include short-desktop() {
        max-width: 956px;
      }

      @include phone() {
        margin-left: 20%;
        width: 175%;
      }
    }

    &-item {
      position: absolute;

      @include phone() {
        display: none;
      }
    }

    &-item1 {
      left: 20%;
      top: 14%;

      @include tablet() {
        left: 8%;
      }
    }
    &-item2 {
      top: 20%;
      right: 10%;
    }
    &-item3 {
      bottom: 10%;
      right: 15%;
    }
    &-item4 {
      bottom: 10%;
      left: 5%;
    }

    &-mobile {
      display: none;
      width: 100%;
      left: 0;
      bottom: -85px;
      z-index: 1;
      position: absolute;

      align-items: center;
      justify-content: center;

      @include phone() {
        display: flex;
      }

      img {
        width: 110%;
        height: 100%;
      }
    }
  }
}
