@import "../../../_vars";

.footer {
  --footer-bg-color: #fafafa;
  --footer-border-color: #DEDEDE;

  background-color: var(--footer-bg-color);
  padding: 32px;
  border-top: 1px solid var(--footer-border-color);

  @include wide-tablet {
    padding: 24px;
  }

  @include phone {
    padding: 20px;
  }
}

.container {
  max-width: 1224px;
  margin: 0 auto;
}

.logo {
  width: 80px;
  height: fit-content;

  @include wide-tablet {
    width: 64px;
  }

  @include phone {
    width: 40px;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--footer-border-color);
  padding-bottom: 24px;

  @include wide-tablet {
    padding-bottom: 16px;
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  gap: 24px;

  @include wide-tablet {
    padding-top: 16px;
  }

  @include phone {
    flex-direction: column;
    gap: 16px;
  }
}
