@import "../../../vars";

.archive {
  &__title {
    @include page-title;
  }
  &__description {
    @include page-description;

    @include tablet() {
      font: normal normal normal 18px/24px PT Sans;
      letter-spacing: 0.15px;
    }

    @include phone() {
      font: normal normal normal 16px/24px PT Sans;
      letter-spacing: 0.13px;
    }
  }
  &__list {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0 24px;
    margin-bottom: 70px;

    @include adaptive(1200) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include tablet() {
      margin-top: 24px;
    }

    @include adaptive(700) {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 20px;
    }

    @include phone() {
      margin-top: 0;
      grid-template-columns: repeat(1, 1fr);
    }

    .project-flipcard {
      @include adaptive(700) {
        margin-top: 20px !important;
      }

      &:nth-child(2n) {
        margin-top: 32px;

        @include adaptive(1200) {
          margin-top: 0;
        }
      }

      &:nth-child(3n + 2) {
        @include adaptive(1200) {
          margin-top: 32px;
        }

        @include tablet() {
          margin-top: 24px;
        }

        @include phone() {
          margin-top: 20px;
        }
      }
    }
  }
}
