@import "../../../../vars";

.slider-arrows {
  padding: 0;
  margin-top: 24px;

  @include tablet {
    margin-top: 12px;
  }

  @include phone {
    margin-top: 16px;
  }
}

.slider-arrow {
  color: var(--base-blue);
}
