.popup {
  &__wrapper {
    width: max-content;
    position: relative;
  }

  padding: 24px;
  background-color: white;
  box-shadow: 0px 4px 24px #65aae04d;
  position: absolute;
  top: calc(100% + 40px);
  z-index: 1;
  width: max-content;

  &_left {
    left: 0;
  }
  &_right {
    right: 0;
  }

  &__header {
    margin-bottom: 32px;
    font: normal normal bold 36px/42px Bebas Neue;
    text-transform: uppercase;
    color: var(--base-text);
  }

  &__footer {
    display: flex;
    align-items: center;
    margin-top: 32px;

    .cr-button {
      margin-left: 24px;
      &:first-child {
        margin-left: auto;
      }
    }
  }
}
