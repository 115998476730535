:root {
  // палитра
  --base-purple: #a69cef;
  --base-red: #cc2222;
  --base-yellow: #ffb867;
  --base-blue: #65aae0;
  --base-ligth-blue: #eefbff;
  --base-bright-blue: #00aeef;
  --base-dark-blue: #1473e6;
  --base-light-gray: #f2f4f7;
  --base-gray: #efefef;
  --base-gray2: #dedede;
  --base-dark-gray: #575756;
  --base-green: #12cf90;
  --color-primary: #65aae0;
  --base-pink: #ff5f76;
  --base-light: #ffffff;

  --base-text: #282828;
  --base-text-secondary: #8a8a8a;
  --base-link: var(--base-blue);
  --base-link-hover: #22bbf4;

  --round-corners: 4px;
  --round-corners-sm: 2px;
  --round-corners-lg: 6px;

  // new
  --corners-l: 32px;
  --corners-m: 24px;
  --corners-s: 16px;
  --corners-xs: 12px;
  --corners-xxs: 8px;

  // типография
  $text-body-size1: "20px/24px";
  $text-body-size2: "16px/20px";
  $text-body-size3: "14px/17px";
  $text-body-size4: "12px/15px";
  $text-body-size5: "10px/12px";

  --font-family-primary: Golos;
  --font-family-secondary: PT Sans;
  --text-h1: normal normal 600 60px/72px var(--font-family-primary);
  --text-h2: normal normal 600 40px/44px var(--font-family-primary);
  --text-h3: normal normal 600 28px/33px var(--font-family-primary);
  --text-h4: normal normal 600 24px/29px var(--font-family-primary);
  --text-body1: normal normal normal #{$text-body-size1}
    var(--font-family-primary);
  --text-body2: normal normal normal #{$text-body-size2}
    var(--font-family-primary);
  --text-body3: normal normal normal #{$text-body-size3}
    var(--font-family-primary);
  --text-body4: normal normal normal #{$text-body-size4}
    var(--font-family-primary);
  --text-body5: normal normal normal #{$text-body-size5}
    var(--font-family-primary);

  /* =======ПЕРЕМЕННЫЕ ДЛЯ UI-KIT======= */
  // BUTTONS
  --button-filled-bg-color: var(--base-blue);
  --button-filled-bg-color-focus: #1473e6;
  --button-filled-bg-color-active: #1473e6;
  --button-filled-bg-color-hover: #3c8de2;
  --button-outlined-color: var(--base-blue);

  // Desktop
  // large
  --button-size-l-padding: 18px 36px;
  --button-size-l-radius: var(--corners-s);
  --button-size-l-font: normal normal 600 #{$text-body-size1}
    var(--font-family-primary);
  --button-size-l-text-transform: uppercase;

  // medium
  --button-size-m-padding: 11px 24px 12px;
  --button-size-m-radius: var(--corners-xxs);
  --button-size-m-font: normal normal 600 #{$text-body-size3}
    var(--font-family-primary);
  --button-size-m-text-transform: uppercase;

  // Tablet
  // large
  --button-size-l-tablet-padding: 11px 16px 10px;
  --button-size-l-tablet-radius: var(--corners-xxs);
  --button-size-l-tablet-font: normal normal 600 #{$text-body-size4}
    var(--font-family-primary);
  --button-size-l-tablet-text-transform: uppercase;

  // medium
  --button-size-m-tablet-padding: 8px 16px 9px;
  --button-size-m-tablet-radius: var(--corners-xxs);
  --button-size-m-tablet-font: normal normal 600 #{$text-body-size4}
    var(--font-family-primary);
  --button-size-m-tablet-text-transform: uppercase;

  // Mobile
  // large
  --button-size-l-mobile-padding: 12px 24px 11px;
  --button-size-l-mobile-radius: var(--corners-xxs);
  --button-size-l-mobile-font: normal normal 600 #{$text-body-size3}
    var(--font-family-primary);
  --button-size-l-mobile-text-transform: uppercase;

  // medium
  --button-size-m-mobile-padding: 10px 24px 9px;
  --button-size-m-mobile-radius: var(--corners-xxs);
  --button-size-m-mobile-font: normal normal 600 #{$text-body-size3}
    var(--font-family-primary);
  --button-size-m-mobile-text-transform: uppercase;
}
