@font-face {
  font-family: "promo-icons";
  src: url("fonts/promo-icons.eot?gp6jxl");
  src:
    url("fonts/promo-icons.eot?gp6jxl#iefix") format("embedded-opentype"),
    url("fonts/promo-icons.ttf?gp6jxl") format("truetype"),
    url("fonts/promo-icons.woff?gp6jxl") format("woff"),
    url("fonts/promo-icons.svg?gp6jxl#promo-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="promo-icon-"],
[class*=" promo-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "promo-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.promo-icon-navigation:before {
  content: "\e900";
}
.promo-icon-user:before {
  content: "\e901";
}
.promo-icon-zoom:before {
  content: "\e902";
}
.promo-icon-1:before {
  content: "\e903";
}
.promo-icon-2:before {
  content: "\e904";
}
.promo-icon-3:before {
  content: "\e905";
}
.promo-icon-4:before {
  content: "\e906";
}
.promo-icon-5:before {
  content: "\e907";
}
.promo-icon-6:before {
  content: "\e908";
}
.promo-icon-7:before {
  content: "\e909";
}
.promo-icon-8:before {
  content: "\e90a";
}
.promo-icon-9:before {
  content: "\e90b";
}
.promo-icon-10:before {
  content: "\e90c";
}
.promo-icon-11:before {
  content: "\e90d";
}
.promo-icon-12:before {
  content: "\e90e";
}
.promo-icon-13:before {
  content: "\e90f";
}
.promo-icon-14:before {
  content: "\e910";
}
.promo-icon-15:before {
  content: "\e911";
}
.promo-icon-16:before {
  content: "\e912";
}
.promo-icon-17:before {
  content: "\e913";
}
.promo-icon-18:before {
  content: "\e914";
}
.promo-icon-19:before {
  content: "\e915";
}
.promo-icon-20:before {
  content: "\e916";
}
.promo-icon-21:before {
  content: "\e917";
}
.promo-icon-22:before {
  content: "\e918";
}
.promo-icon-23:before {
  content: "\e919";
}
.promo-icon-24:before {
  content: "\e91a";
}
.promo-icon-25:before {
  content: "\e91b";
}
.promo-icon-26:before {
  content: "\e91c";
}
.promo-icon-27:before {
  content: "\e91d";
}
.promo-icon-28:before {
  content: "\e924";
}
.promo-icon-29:before {
  content: "\e925";
}
.promo-icon-30:before {
  content: "\e926";
}
.promo-icon-31:before {
  content: "\e927";
}
.promo-icon-32:before {
  content: "\e928";
}
.promo-icon-33:before {
  content: "\e929";
}
.promo-icon-34:before {
  content: "\e92a";
}
.promo-icon-35:before {
  content: "\e92b";
}
.promo-icon-36:before {
  content: "\e92c";
}
.promo-icon-37:before {
  content: "\e92d";
}
.promo-icon-38:before {
  content: "\e92e";
}
.promo-icon-39:before {
  content: "\e92f";
}
.promo-icon-40:before {
  content: "\e930";
}
.promo-icon-41:before {
  content: "\e931";
}
.promo-icon-42:before {
  content: "\e932";
}
.promo-icon-43:before {
  content: "\e933";
}
.promo-icon-44:before {
  content: "\e934";
}
.promo-icon-45:before {
  content: "\e935";
}
.promo-icon-46:before {
  content: "\e936";
}
.promo-icon-47:before {
  content: "\e937";
}
.promo-icon-48:before {
  content: "\e938";
}
.promo-icon-49:before {
  content: "\e939";
}
.promo-icon-50:before {
  content: "\e93a";
}
.promo-icon-51:before {
  content: "\e93b";
}
.promo-icon-52:before {
  content: "\e93c";
}
.promo-icon-53:before {
  content: "\e93d";
}
.promo-icon-54:before {
  content: "\e93e";
}
.promo-icon-55:before {
  content: "\e93f";
}
.promo-icon-56:before {
  content: "\e940";
}
.promo-icon-57:before {
  content: "\e941";
}
.promo-icon-58:before {
  content: "\e942";
}
.promo-icon-59:before {
  content: "\e943";
}
.promo-icon-60:before {
  content: "\e944";
}
.promo-icon-61:before {
  content: "\e945";
}
.promo-icon-62:before {
  content: "\e946";
}
.promo-icon-63:before {
  content: "\e947";
}
.promo-icon-64:before {
  content: "\e948";
}
.promo-icon-65:before {
  content: "\e949";
}
.promo-icon-66:before {
  content: "\e94a";
}
.promo-icon-67:before {
  content: "\e94b";
}
.promo-icon-68:before {
  content: "\e94c";
}
.promo-icon-69:before {
  content: "\e94d";
}
.promo-icon-70:before {
  content: "\e94e";
}
.promo-icon-71:before {
  content: "\e94f";
}
.promo-icon-72:before {
  content: "\e950";
}
.promo-icon-73:before {
  content: "\e951";
}
.promo-icon-74:before {
  content: "\e952";
}
.promo-icon-75:before {
  content: "\e953";
}
.promo-icon-76:before {
  content: "\e954";
}
.promo-icon-77:before {
  content: "\e955";
}
.promo-icon-78:before {
  content: "\e956";
}
.promo-icon-79:before {
  content: "\e957";
}
.promo-icon-80:before {
  content: "\e958";
}
.promo-icon-81:before {
  content: "\e959";
}
.promo-icon-82:before {
  content: "\e95a";
}
.promo-icon-83:before {
  content: "\e95b";
}
.promo-icon-84:before {
  content: "\e95c";
}
.promo-icon-85:before {
  content: "\e95d";
}
.promo-icon-86:before {
  content: "\e95e";
}
.promo-icon-87:before {
  content: "\e95f";
}
.promo-icon-88:before {
  content: "\e960";
}
.promo-icon-89:before {
  content: "\e961";
}
.promo-icon-90:before {
  content: "\e962";
}
.promo-icon-91:before {
  content: "\e963";
}
.promo-icon-92:before {
  content: "\e964";
}
.promo-icon-93:before {
  content: "\e965";
}
.promo-icon-94:before {
  content: "\e966";
}
.promo-icon-98:before {
  content: "\e967";
}
.promo-icon-99:before {
  content: "\e968";
}
.promo-icon-100:before {
  content: "\e969";
}
.promo-icon-101:before {
  content: "\e96a";
}
.promo-icon-95:before {
  content: "\e96b";
}
.promo-icon-96:before {
  content: "\e96c";
}
.promo-icon-97:before {
  content: "\e96d";
}
.promo-icon-102:before {
  content: "\e96e";
}
.promo-icon-103:before {
  content: "\e96f";
}
.promo-icon-104:before {
  content: "\e970";
}
.promo-icon-105:before {
  content: "\e971";
}
.promo-icon-107:before {
  content: "\e972";
}
.promo-icon-108:before {
  content: "\e973";
}
.promo-icon-109:before {
  content: "\e974";
}
.promo-icon-110:before {
  content: "\e975";
}
.promo-icon-1051:before {
  content: "\e976";
}
.promo-icon-1091:before {
  content: "\e977";
}
.promo-icon-1092:before {
  content: "\e978";
}
.promo-icon-1100:before {
  content: "\e979";
}
.promo-icon-1101:before {
  content: "\e97a";
}
.promo-icon-1102:before {
  content: "\e97b";
}
.promo-icon-1103:before {
  content: "\e97c";
}
.promo-icon-zoom-out:before {
  content: "\e97d";
}
.promo-icon-zoom-in:before {
  content: "\e97e";
}
.promo-icon-hidden:before {
  content: "\e97f";
}
.promo-icon-calendar-two:before {
  content: "\e980";
}
.promo-icon-clock-two:before {
  content: "\e981";
}
.promo-icon-unlock:before {
  content: "\e982";
}
.promo-icon-lock:before {
  content: "\e983";
}
.promo-icon-1105:before {
  content: "\e984";
}
.promo-icon-1106:before {
  content: "\e985";
}
.promo-icon-1107:before {
  content: "\e986";
}
.promo-icon-1108:before {
  content: "\e987";
}
.promo-icon-like-empty:before {
  content: "\e988";
}
.promo-icon-arrow-right3:before {
  content: "\e989";
}
.promo-icon-close:before {
  content: "\e98a";
}
.promo-icon-plus:before {
  content: "\e98b";
}
.promo-icon-minus:before {
  content: "\e98c";
}
.promo-icon-down:before {
  content: "\e98d";
}
.promo-icon-Group77:before {
  content: "\e98e";
}
.promo-icon-home:before {
  content: "\eedf";
}
.promo-icon-fullscreen:before {
  content: "\eee0";
}
.promo-icon-add-link:before {
  content: "\eee1";
}
.promo-icon-user-multi:before {
  content: "\1f317";
  opacity: 0.2;
}
.promo-icon-user-multi:after {
  content: "\1f318";
  margin-left: -1em;
}
.promo-icon-category-multi:before {
  content: "\e91e";
  opacity: 0.2;
}
.promo-icon-category-multi:after {
  content: "\e91f";
  margin-left: -1em;
}
.promo-icon-complete-multi:before {
  content: "\e920";
}
.promo-icon-complete-multi:after {
  content: "\e921";
  margin-left: -1em;
  opacity: 0.2;
}
.promo-icon-idea-multi:before {
  content: "\e922";
  opacity: 0.2;
}
.promo-icon-idea-multi:after {
  content: "\e923";
  margin-left: -1em;
}
