@import "../../../vars";

.ideas-list {
  $root: &;

  margin-top: 24px;
  position: relative;

  @include tablet() {
    margin-top: 16px;
  }

  @include phone() {
    margin-top: 20px;
  }

  &__all {
    font: normal normal normal 18px/22px PT Sans;
    letter-spacing: 0.17px;
    text-decoration: underline;
    position: absolute;
    right: 0;
    bottom: 16px;

    @include tablet {
      bottom: 23px;
      font: normal normal normal 16px/20px PT Sans;
      letter-spacing: 0.29px;
    }

    @include phone() {
      bottom: -30px;
      transform: translateX(50%, -50%);
      margin: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      font: normal normal normal 14px/19px PT Sans;
      letter-spacing: 0.1px;
    }
  }

  &__loading {
    opacity: 0.6;
    pointer-events: none;
  }

  &__wrapper {
    display: flex;

    @include phone() {
      flex-direction: column;
    }

    .infocard {
      @include phone() {
        border: 0;
      }

      &__wrapper {
        margin-left: 24px;

        @include phone() {
          order: 1;
          margin-left: 0;
        }
      }
    }

    .swiper-container {
      padding-right: 32px;
      padding: 10px;
      margin: -10px;
      width: 100%;

      @include phone() {
        width: auto;
        order: 2;
        margin-top: 16px !important;
      }
    }

    .idea-card {
    }
  }

  .infocard {
    width: 288px;

    @include tablet() {
      width: 224px;
    }

    @include phone() {
      width: 100%;
    }
  }

  .slider-arrows {
    width: calc(100% - 288px - 24px);
    padding-top: 24px;

    @include tablet() {
      width: calc(100% - 224px - 24px);
      padding-top: 18px;
    }

    @include phone() {
      width: 100%;
      padding-top: 22px;
    }
  }

  &_without-info-card {
    #{$root}__wrapper {
      .swiper-container {
        width: auto;
      }
    }

    .slider-arrows {
      width: 100%;
    }
  }
}
