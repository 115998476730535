@import "../../../vars";

.olm {
  &-wrapper {
    position: relative;

    .data-loader-wr {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-map {
    $root: &;
    width: 100%;
    height: 672px;

    &__controls {
      position: absolute;
      right: 24px;
      bottom: 34px;
      display: flex;
      flex-direction: column;

      &--centered {
        bottom: 50%;
        transform: translateY(50%);
        right: 32px;

        @include wide-tablet() {
          right: 16px;
        }

        @include phone() {
          right: 12px;
        }
      }

      &-wrap {
        box-shadow: 0px 2px 10px #0000001a;
        display: flex;
        flex-direction: column;
      }

      #{$root}__control {
        color: #282828;
        background: white !important;
        width: 32px;
        height: 32px;
        margin: 0;
        font-size: 20px;
        border-radius: 4px;
        border: none;

        &:last-child {
          margin-top: 1px;

          @include phone() {
            margin-top: 1px;
          }
        }
      }

      #{$root}__location {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 10px #0000001a;
        border-radius: 4px;
        border: unset;
        padding: 5px;
        width: 32px;
        height: 32px;
        margin-bottom: 8px;

        .promo-icon-navigation {
          font-size: 22px;
        }
      }
    }

    // Overrides

    .ol-control {
      display: none;
    }
  }
}
