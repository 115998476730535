@import "../../../../_vars";

.wrapper {
  * {
    font: var(--text-body4);
    letter-spacing: 0px;
    color: var(--base-text-secondary) !important;
  }
}

.copyright {
  margin-bottom: 4px;

  @include phone {
    margin-bottom: 8px;
  }
}

.links {
  display: flex;
  flex-wrap: wrap;
  gap: 2px 12px;
  max-width: 540px;

  @include wide-tablet {
    max-width: 340px;
  }

  @include phone {
    gap: 6px 12px
  }

  * {
    text-decoration: none !important;
    line-height: 20px;
    background-color: transparent;
    border: none;
    transition: color 0.1s ease-in-out;

    @include phone {
      line-height: 17px;
    }

    &:hover {
      color: var(--base-blue) !important;
    }

    &:not(:last-child)::after {
      content: "";
      width: 1px;
      background-color: var(--base-text-secondary);
      height: 20px;
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 0 12px;
    }
  }
}
