@import "../../../vars";

.grid-view {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  width: 100%;

  &__list {
    display: grid;
  }

  &__arrows {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-btn {
      cursor: pointer;
      text-align: center;
      font-size: 24px;
      width: 24px;
      height: 24px;
      margin: 0px 10px;

      @include phone() {
        margin: 0px 20px;
      }
    }

    &-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .grid-view__arrows-btn {
        width: auto;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    flex: 1;

    @include phone() {
      flex-direction: column;
    }
  }

  &__link {
    position: absolute;
    display: flex;
    justify-content: center;
    right: 0;

    > a {
      font: 16px/24px PT Sans;
      letter-spacing: 0.15px;
      text-decoration: underline;
    }

    @include phone() {
      position: relative;
      margin-top: 14px;
    }
  }
}

.arrow-disabled {
  color: #dedede;
  pointer-events: none;
}

// .arrow-right-center {
//   padding-left: 20px;
// }

// .arrow-left-center {
//   padding-right: 20px;
// }
