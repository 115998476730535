@import "../../../../../../vars";

.review-card {
  padding: 20px 16px 16px 16px;
  border-radius: 20px;
  background-color: var(--base-purple);

  color: #ffffff;
  font: normal normal normal 20px/24px Montserrat;
  letter-spacing: 0px;
  word-break: break-word;

  @include wide-tablet {
    padding: 16px;
  }

  @include phone {
    font-size: 16px;
    line-height: 19px;
  }

  &__scroll {
    height: 72px;

    @include wide-tablet {
      height: 96px;
    }

    @include phone {
      height: 76px;
    }
  }

  &__source {
    display: inline;
    font-weight: 700;
  }

  &__message {
    display: inline;
  }

  &__button {
    display: block;
    width: 100%;
    border: 1px solid #ffffff;
    margin: 20px auto 0 auto;
    color: #ffffff;
    background-color: var(--base-purple);
    padding: 10px 0;

    @include wide-tablet {
      margin-top: 16px;
    }

    &:hover,
    &:active {
      background-color: #ffffff14;
    }
  }
}
