@import "../../../vars";

.appeal {
  &__container {
    width: 100%;
    display: flex;
    margin-top: 24px;

    &-image {
      width: 49%;
      height: 496px;
      max-width: 496px;
      margin-right: 24px;
      background-position-x: center;
      background-position-y: top;
      background-image: url(../../../assets/speech.png);
      position: relative;
      background-size: cover;
    }

    &-content {
      width: 53%;
      display: flex;
      flex-direction: column;

      &-title,
      &-text,
      &-signature {
        font: normal normal normal 20px/28px PT Sans;
        letter-spacing: 0.16px;
        color: var(--base-text);
      }

      &-text {
        > p {
          margin-top: 24px;
        }
      }

      &-signature {
        margin-top: 24px;

        > img {
          max-width: 60px;
          vertical-align: middle;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .appeal {
    &__container {
      &-image {
        max-width: 286px;
        max-height: 286px;
        background-image: url(../../../assets/speech-768.png);
      }

      &-content {
        &-title,
        &-text,
        &-signature {
          font: normal normal normal 18px/24px PT Sans;
          letter-spacing: 0.15px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .appeal {
    &__container {
      flex-direction: column;

      &-image {
        margin-right: 0;
        width: calc(100% + 48px);
        max-width: none;
        max-height: 56vw;
        margin-left: -24px;
        background-image: url(../../../assets/speech-320.png);
      }

      &-content {
        width: 100%;
        margin-top: 24px;

        &-title,
        &-text,
        &-signature {
          font: normal normal normal 16px/24px PT Sans;
          letter-spacing: 0.13px;
        }
      }
    }
  }
}
