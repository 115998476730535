$font:
  16px/24px var(--font-family-primary),
  Arial,
  sans-serif;
$font-project-title-m:
  Bold 48px/57px Bebas Neue,
  sans-serif;
$font-project-sub-title:
  Bold 30px/36px Bebas Neue,
  sans-serif;
$font-project-sub-title-t:
  Bold 23px/28px Bebas Neue,
  sans-serif;
$font-project-sub-title-m:
  Bold 17px/20px Bebas Neue,
  sans-serif;
$font-stats-t-small:
  Bold 20px/24px Bebas Neue,
  sans-serif;
$font-stats-t-small-text:
  Bold 16px/14px Bebas Neue,
  sans-serif;
$font-stats-t:
  Bold 24px/29px Bebas Neue,
  sans-serif;
$font-button: Bold 14px/20px "Intro Bold";
$font-button-m: Bold 19px/29px "Intro Bold";
$font-title:
  Bold 96px/115px Bebas Neue,
  sans-serif;
$font-title-t:
  Bold 60px/72px Bebas Neue,
  sans-serif;
$font-title-m:
  Bold 50px/60px Bebas Neue,
  sans-serif;
$font-paragraph: 20px/26px Arial;

$black-text: #282828;
$font-color: #333;
$blue: #00aeef;
$light-blue: #31bcf0;
$dark-blue: #004e6f;
$gray: #808080;
$dark-grey: var(--base-text);
$red: #f20b06;
$error: #ff6666;
$pink: #ff5f76;
$border-color: #b3b3b3;

$containerMaxWidth: 1272px;
$containerXPadding: 24px;
$containerXPadding-phone: 20px;

$header-top-section-height: 44;

$header-desk-height: 77;
$header-tab-height: 58;
$header-mob-height: 61;

$header-desk-height-with-top-section: $header-top-section-height +
  $header-desk-height;
$header-tab-height-with-top-section: $header-top-section-height +
  $header-tab-height;
$header-mob-height-with-top-section: $header-top-section-height +
  $header-mob-height;

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin keyframe($animation_name) {
  @-webkit-keyframes #{$animation_name} {
    @content;
  }

  @-moz-keyframes #{$animation_name} {
    @content;
  }

  @-o-keyframes #{$animation_name} {
    @content;
  }

  @-ms-keyframes #{$animation_name} {
    @content;
  }

  @keyframes #{$animation_name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin adaptive($max-width) {
  @media screen and (max-width: #{$max-width}px) {
    @content;
  }
}

@mixin fhd {
  @media screen and (max-width: 1920px) {
    @content;
  }
}

@mixin hd {
  @media screen and (max-width: 1366px) {
    @content;
  }
}

@mixin short-desktop {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin desktop-only {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin wide-tablet {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin medium-tablet {
  @media screen and (max-width: 960px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 834px) {
    @content;
  }
}

@mixin small-tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 550px) {
    @content;
  }
}

@mixin phone-except {
  @media screen and (min-width: 551px) {
    @content;
  }
}

@mixin phoneLandscape {
  @media only screen and (max-height: 500px) and (orientation: landscape) {
    @content;
  }
}

@mixin afterBeforeBackground($color) {
  &::before {
    left: 0;
    margin-left: calc(-50vw + 1px);
  }
  &::after {
    left: calc(100% - 1px);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: $color;
    height: 100%;
    width: 50vw;
    top: 0;
    @content;
  }
}

%section-title {
  font-family: var(--font-family-primary);
  font-weight: bold;
  font-size: 48px;
  line-height: 57px;
  letter-spacing: -0.75px;
  color: $black-text;

  @include adaptive(1024) {
    font-family: var(--font-family-primary);
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: 0.21px;
  }

  @include phone() {
    font-family: var(--font-family-primary);
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.56px;
  }
}

%base-text-pages {
  font: normal normal normal 20px/28px var(--font-family-primary);
  letter-spacing: 0.16px;
  color: var(--base-text);

  @include tablet() {
    font: normal normal normal 18px/24px var(--font-family-primary);
    letter-spacing: 0.15px;
  }

  @include phone() {
    font: normal normal normal 16px/24px var(--font-family-primary);
    letter-spacing: 0.13px;
  }
}

@function stripUnit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin scrollbar(
  $width,
  $round: 0px,
  $trackColor: #dedede,
  $thumbColor: #8a8a8a
) {
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-track {
    background: $trackColor;
    border-radius: $round;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumbColor;
    border-radius: $round;
  }
}

@mixin hint-text {
  display: flex;
  margin-top: 5px;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #808080;
}

@mixin page-title {
  margin-top: 36px;
  width: 100%;
  text-align: left;
  font: normal normal bold 60px/72px var(--font-family-primary);
  letter-spacing: -0.5px;
  color: var(--base-text);
  word-break: break-word;
  @media only screen and (max-width: 768px) {
    margin-top: 24px;
    font: normal normal bold 50px/60px var(--font-family-primary);
    letter-spacing: -0.42px;
  }
  @media only screen and (max-width: 500px) {
    font: normal normal bold 40px/40px var(--font-family-primary);
    letter-spacing: -0.33px;
  }
}

@mixin page-description {
  margin-top: 24px;
  width: 100%;
  text-align: left;
  word-break: break-word;

  @extend %base-text-pages;

  @include tablet() {
    margin-top: 24px;
  }
}

@mixin hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }

  @media (hover: none), (hover: hover) and (pointer: coarse) {
    &:active {
      @content;
    }
  }
}

@mixin disableSelect {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

@mixin gradient-text-color($gradient) {
  background: $gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin gradient-border-color($baseBg, $gradient, $size) {
  background:
    linear-gradient($baseBg, $baseBg) padding-box,
    $gradient border-box;
  border: $size solid transparent;
}

@mixin gradient-border-color-with-hover($baseBg, $gradient, $size) {
  @include gradient-border-color($baseBg, $gradient, $size);

  &:hover {
    background:
      $gradient padding-box,
      $gradient border-box;
  }
}
