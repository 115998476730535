wr-ol {
  display: block;
  list-style-type: decimal;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 1em;
  margin-block-start: 2px;
  margin-block-end: 2px;
}

wr-ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 2px;
  margin-block-end: 2px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 1em;
  list-style-type: none;

  li {
    margin: 0;
    position: relative;

    &:after {
      content: "";
      background: #009dde;
      border-radius: 100%;
      width: 5px;
      height: 5px;
      display: block;
      position: absolute;
      top: 0.6em;
      left: -14px;
    }
  }
}
