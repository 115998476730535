@import "../../../../../vars";
@import "../../mixins.scss";

.discussion-landing-thematic-projects {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include wide-tablet {
    gap: 20px;
  }
  @include phone {
    gap: 16px;
  }

  .swiper-slide {
    height: auto;
    > div,
    .thematic-project-card {
      height: 100%;
    }
  }

  @include desktop-only {
    .common-slider__footer {
      min-height: unset;
      position: unset;
      @include sliderArrowsSide;
    }
  }
}
