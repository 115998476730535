@import "../../../vars";

.tablet-bounce-prevent {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.book-view {
  width: 100%;
  height: 100%;

  > div {
    height: 100%;
  }

  img {
    width: 100%;
  }

  &__prevent-scroll {
    overflow-y: hidden;
  }
  &__zero-padding {
    padding: 0 !important;
  }

  .no-book {
    background-color: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    img {
      max-width: 1240px;
      height: 100%;
      object-fit: contain;
    }
  }

  .navigation {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 48px;

    &__button {
      border: 1px solid #d2e4f1;
      border-radius: 50px;
      color: white;
      cursor: pointer;
      background: #d2e4f1;
      width: 50px;
      height: 50px;
      transform: rotate(90deg);

      &--disabled {
        background: #ECF3F9;
        pointer-events: none;
      }

      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
}

.full-page {
  width: 100%;
  height: 100%;
  transform: translate3d(0px, 0px, 0px);
  transition: all 900ms ease 0s;

  &__slide {
    width: 100%;
    //height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @include phone() {
      height: 100%;
    }

    > img {
      width: 100%;
      max-width: 1240px;
      height: 100%;
      object-fit: contain;

      @include phone() {
        object-fit: cover;
      }
    }
  }

  .slide-last {
    display: flex;
    flex-direction: column;

    &__title {
      text-align: center;
      text-transform: uppercase;
      color: rgb(119, 110, 95);
      font-family: "Helios";
      font-size: 78px;

      @include adaptive(945) {
        font-size: 50px;
      }

      @include phone() {
        font-size: 30px;
        margin-top: 50px;
      }
    }

    &__links {
      margin-top: 50px;
      display: flex;

      @include adaptive(945) {
        flex-direction: column;
      }
    }

    &__link {
      display: inline-block;
      font-family: "Intro Bold";
      text-transform: uppercase;
      font-size: 14px;
      padding: 18px 24px;
      margin-right: 5px;
      line-height: 12px;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      color: #fff;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;
      vertical-align: middle;
      background-image: none;
      background-color: #00aeef;
      outline: none;
      text-decoration: none !important;
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      transition: all 0.2s;
      width: 288px;
      font: Bold 20px/24px Bebas Neue;
      letter-spacing: 0.67px;
      padding: 11px;
      margin-right: 24px;
      margin-bottom: 48px;

      @include adaptive(945) {
        margin: 10px;
      }
    }
  }
}

.flipbook {
  display: flex;
  align-items: center;
  height: 100%;
  transition: transform 1s ease-in;

  &__page {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}
