@import "../../../../vars";
$directions-background: #65aae1;
$directions-background-m: #eefbff;

.banner-directions {
  .item {
    cursor: pointer;
    height: 176px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    position: relative;
    border-radius: var(--corners-s);
    overflow: hidden;
    background:
      var(--url) var(--right-pos, 100%) var(--top-pos, 50%) / auto
        var(--size, 101%) no-repeat,
      linear-gradient(var(--gradient)) right center/auto 101% no-repeat;

    @include wide-tablet {
      height: 136px;
      padding: 12px;
    }

    @include phone {
      height: 172px;
    }

    &_generation {
      --url: url("../../../../assets/banner-directions/propose.svg");
      --gradient: var(--base-blue), #5a97c7;
      padding-right: 25%;

      @include medium-tablet {
        --right-pos: calc(100% + 10px);
      }

      @include tablet {
        --right-pos: calc(100% + 30px);
        --size: 110%;
        --top-pos: calc(50% - 7px);
      }

      @include phone {
        --right-pos: 100%;
        --size: 101%;
        --top-pos: 50%;
        padding-right: 32%;
      }
    }
    &_simple_discussion {
      --url: url("../../../../assets/banner-directions/discuss.svg");
      --gradient: #ffc28d, #e68a39;
      padding-right: 38%;

      @include medium-tablet {
        --right-pos: calc(100% + 10px);
      }

      @include tablet {
        --right-pos: calc(100% + 34px);
      }
    }
    &_voting {
      --url: url("../../../../assets/banner-directions/vote.svg");
      --gradient: var(--base-dark-blue), #1266cc;
      padding-right: 44%;

      @include wide-tablet {
        background-image: url("../../../../assets/banner-directions/vote-mobile.svg"),
          linear-gradient(var(--base-dark-blue), #1266cc);
        padding-right: 30%;
      }

      @include medium-tablet {
        padding-right: 35%;
      }
    }

    &:hover {
      opacity: 0.9;
      text-decoration: none;
    }

    &_disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .title {
    font: normal normal bold 24px/26px var(--font-family-primary);
    color: white;

    @include tablet {
      font-size: 18px;
      line-height: 20px;
    }
  }

  .text {
    color: white;
    line-height: 24px;
  }
}
