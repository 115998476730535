@import "../../../../vars";

.project-flipcard {
  background-color: transparent;
  height: 511px;
  perspective: 1000px;
  cursor: pointer;
  box-sizing: content-box;

  @include tablet() {
    height: 428px;
  }

  @include phone() {
    width: calc(min(300px, 85vw));
    margin: 0 auto;
    height: 505px;
  }

  &:hover .project-flipcard-flippable {
    transform: rotateY(180deg);
  }

  &-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    box-shadow: 0px 2px 10px #0000001a;
    border-radius: var(--round-corners);

    &__front {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      z-index: 1;
      /* Safari */
      backface-visibility: hidden;
      background-color: white;
      color: black;
      /* makes backface-visibility work for children on Mac OS Chrome */

      &-logo {
        height: 100%;
        max-height: 360px;
        background-size: cover;
        background-position: center;

        @include tablet() {
          max-height: 280px;
        }

        @include phone() {
          max-height: 350px;
        }
        div,
        img {
          width: 100%;
          height: 100%;
        }
      }

      &-footer {
        display: flex;
        flex-direction: column;
        padding: 16px 0 0;
        flex-grow: 1;
        position: relative;

        @include tablet() {
          padding: 14px 0 0;
        }

        @include phone() {
          padding: 17px 0 0;
        }

        &-date {
          text-align: center;
          font: normal normal normal 14px/20px var(--font-family-primary);
          letter-spacing: 0.25px;
          color: #8a8a8a;
        }

        &-name {
          font: normal normal bold 16px/20px var(--font-family-primary);
          letter-spacing: 0.15px;
          color: var(--base-text);
          margin: 16px 0 24px;
          height: auto;
          max-height: 38px;
          display: flex;
          justify-content: center;
          text-align: center;
          flex-grow: 1;
          padding: 0 16px;

          @include tablet() {
            padding: 0 12px;
            font: normal normal bold 14px/20px var(--font-family-primary);
          }

          @include phone() {
            font: normal normal bold 18px/20px var(--font-family-primary);
            letter-spacing: 0.17px;
          }
        }

        &-stats {
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-top: auto;

          .ic {
            color: var(--base-blue);
            margin-right: 5px;
            margin-bottom: 2px;
            font-size: 23px;
          }

          &-users,
          &-bulb {
            display: flex;
            align-items: center;
            justify-content: center;
            font: Bold 24px/29px var(--font-family-primary);
            letter-spacing: 0.18px;
            color: var(--base-text);
          }
        }

        // FOR CURRENT PROJECTS
        &-current,
        &-announcement {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;

          .cr-icon-button {
            min-height: 48px;
            position: absolute;
            bottom: 16px;
            left: 16px;
            right: 16px;
            @include tablet {
              bottom: 12px;
              left: 12px;
              right: 12px;
            }

            &__text {
              font: normal normal bold 18px/21px var(--font-family-primary);
              letter-spacing: 0.6px;
            }
          }
        }
      }

      &-buttons {
        padding: 16px;
      }
    }

    &__back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      z-index: 2;
      /* Safari */
      backface-visibility: hidden;
      background-color: white;
      color: white;
      transform: rotateY(180deg);

      padding: 32px 16px 20px;
      height: 100%;

      @include tablet() {
        padding: 16px 12px 12px;
      }

      @include phone() {
        padding: 24px 12px 4px;
      }

      &-name {
        text-align: center;
        font: normal normal bold 16px/20px var(--font-family-primary);
        letter-spacing: 0.15px;
        color: var(--base-text);
        margin-bottom: 19px;
        max-height: 40px;

        @include tablet() {
          font: normal normal bold 14px/20px var(--font-family-primary);
          letter-spacing: 0.25px;
          margin-bottom: 12px;
        }

        @include phone() {
          font: normal normal bold 18px/20px var(--font-family-primary);
          letter-spacing: 0.17px;
        }
      }

      &-progress {
        color: var(--base-blue);
        display: flex;
        text-align: start;

        .ic {
          margin-left: -7px;
          margin-right: 7px;
        }

        &-title {
          font: normal normal normal 18px/20px var(--font-family-primary);
          letter-spacing: 0.32px;

          @include tablet() {
            font: normal normal normal 16px/20px var(--font-family-primary);
            letter-spacing: 0.29px;
          }

          @include phone() {
            font: normal normal normal 14px/19px var(--font-family-primary);
            letter-spacing: 0.25px;
          }

          span {
            font-weight: bold;
          }
        }
      }

      &-description {
        margin-top: 16px;
        font: normal normal normal 18px/20px var(--font-family-primary);
        letter-spacing: 0.32px;
        color: var(--base-text);
        // height: 45%;
        overflow-y: auto;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        line-height: 20px;

        @include scrollbar(5px);

        @include tablet() {
          margin-top: 12px;
          font: normal normal normal 16px/20px var(--font-family-primary);
          letter-spacing: 0.29px;
        }

        @include phone() {
          font: normal normal normal 14px/19px var(--font-family-primary);
          letter-spacing: 0.25px;
        }

        img {
          width: 100%;
        }
      }

      &-footer {
        margin-top: 17px;
        text-align: center;
        letter-spacing: 0.32px;
        color: var(--base-text);
        position: absolute;
        bottom: 10px;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        left: 0;
        right: 0;
        flex-direction: column;
        display: flex;
        justify-content: flex-end;

        @include tablet() {
          margin-top: 15px;
          padding-left: 12px;
          padding-right: 12px;
        }

        > .cr-icon-button {
          margin-bottom: 12px;

          @include tablet() {
            margin-bottom: 8px;
            min-height: 36px;
          }

          @include phone() {
            margin-bottom: 12px;
            min-height: 40px;
          }

          .cr-icon-button__icon {
            margin-right: 11px;

            @include tablet() {
              margin-right: 7px;
              width: 18px;
              height: 18px;
            }
            @include phone() {
              width: 24px;
              height: 24px;
            }

            .ic {
              @include tablet() {
                font-size: 18px;
                width: 18px;
                height: 18px;
              }
              @include phone() {
                font-size: 24px;
                width: 24px;
                height: 24px;
              }
            }
          }

          .cr-icon-button__text {
            font: normal normal bold 14px/19px var(--font-family-primary);
            letter-spacing: 0px;
            text-transform: uppercase;

            @include tablet() {
              font: normal normal bold 11px/14px var(--font-family-primary);
              letter-spacing: 0.15px;
            }

            @include phone() {
              font: normal normal bold 14px/19px var(--font-family-primary);
              letter-spacing: 0px;
            }
          }
        }
      }
    }

    &__front,
    &__back {
      border-radius: var(--round-corners);
      overflow: hidden;
    }
  }
}

.project-progress {
  color: white;
  position: relative;
  transform-style: preserve-3d;
  &__bar {
    background-color: var(--base-blue);
    height: 40px;
    font: normal normal normal 14px var(--font-family-primary);
    letter-spacing: 0.25px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__value {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: var(--base-dark-blue);
  }

  &__text {
    position: relative;
    z-index: 1;
    font: normal normal normal 14px/20px var(--font-family-primary);
    letter-spacing: 0.25px;
  }

  span {
    font: normal normal bold 14px/20px var(--font-family-primary);
    letter-spacing: 0.15px;
    color: #ffffff;
    text-transform: uppercase;
  }
}
