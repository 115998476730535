.crowd-birthday-10-pin {
  $root: &;

  position: relative;
  width: 82px;
  height: 143px;
  transform: translate(-50%, -100%);
  cursor: pointer;

  &__spot {
    width: 100%;
    height: 100%;
    background: url(../../../../../../assets/birthday10/map/pin/pin.svg) 0 0 no-repeat;
    transform-origin: center bottom;

    &:before {
      content: "";
      width: 86px;
      height: 30px;
      background: url(../../../../../../assets/birthday10/map/pin/shadow.svg) 0 0 no-repeat;
      position: absolute;
      transform: translate(41px, 140px);
      z-index: 1;
      pointer-events: none;
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 128px;
    height: 60px;
    left: -20px;
    top: 113px;
    border-radius: 50%;
    z-index: -1;
  }
  &::before {
    background-color: #526283;
  }
  &::after {
    background-color: #7e9ccb;
    transform: scale(0.66);
  }

  @supports not (-webkit-touch-callout: none) {
    &:hover {
      #{$root}__spot {
        animation: shaking 0.5s linear;
      }
      &::after {
        animation: scaling 1.2s linear infinite;
      }
    }
  }

  @keyframes shaking {
    0% {
      transform: rotate(10deg);
    }
    25% {
      transform: rotate(-8deg);
    }
    50% {
      transform: rotate(5deg);
    }
    75% {
      transform: rotate(2deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes scaling {
    0% {
      transform: scale(0.66);
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
    80% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
