@import "../../../../../../vars";

.dls-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font: normal normal bold 20px/24px Montserrat;
    letter-spacing: 0px;
    color: $black-text;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 48px;
    @include phone() {
      font: normal normal bold 18px/22px Montserrat;
      height: 44px;
    }
  }
  &__date {
    font: normal normal normal 14px/18px Montserrat;
    letter-spacing: 0px;
    color: #8a8a8a;
    margin-left: auto;
    margin-top: 8px;
  }

  &__expert {
    display: flex;
    align-items: center;
    background-color: #eefbff;
    border-radius: 20px;
    margin-top: 16px;
    padding: 16px;
    .user-avatar__container {
      flex-shrink: 0;
      margin-right: 16px;
    }

    &-info {
      flex-grow: 1;
    }

    &-name {
      font: normal normal bold 16px/19px Montserrat;
      letter-spacing: 0px;
      color: #282828;
    }

    &-position {
      font: normal normal normal 12px/14px Montserrat;
      letter-spacing: 0px;
      color: #282828;
    }
  }

  &__scroll-text {
    margin-top: 16px;

    height: 95px;
    @include wide-tablet {
      height: 114px;
    }

    @include phone {
      height: 133px;
    }
  }

  &__scroll-expert-position {
    height: 28px;

    @include wide-tablet {
      height: 42px;
    }

    @include phone {
      height: 56px;
    }
  }

  &__text {
    font: normal normal normal 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #282828;
  }

  .cr-button {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 28px 0;
    font: normal normal bold 20px/24px Montserrat;

    @include phone() {
      padding: 16px 0;
      font: normal normal bold 16px/19px Montserrat;
    }
  }

  .cr-button-disabled {
    color: #8a8a8a;
  }

  &__registered {
    .cr-button {
      pointer-events: none;
    }
  }
}
