@import "../../../vars";

.propose-idea {
  .spherecard__body-actions-link {
    text-decoration: underline;
  }

  &__btn-wrapper {
    margin-top: 24px;

    @include tablet() {
      margin-top: 16px;
    }

    .cr-button {
      @include tablet() {
        font-family: var(--font-family-primary);
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.15px;
      }

      @include phone() {
        font-family: var(--font-family-primary);
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 1.35px;
      }
    }
  }

  &__cards {
    margin-top: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .grid-view__arrows {
      display: none;
    }

    .spherecard {
      width: calc(50% - 12px);
      margin-top: 24px;
    }
  }
  &_end__modal {
    .submit-form__footer {
      display: none;
    }
  }

  .birthday10-banner {
    margin-top: 24px;
    @include tablet {
      margin-top: 16px;
    }
  }
}
