@import "../../../../../../../vars";

.sphere-card {
  padding: 32px 40px 24px 40px;

  @include medium-tablet {
    margin-top: 51px;
  }

  .ScrollbarsCustom-Scroller {
    margin-right: 0 !important;
    padding-right: 0 !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ScrollbarsCustom-Content {
    display: block !important;
  }

  &__scroll {
    max-height: calc(100dvh - 305px);

    @include wide-tablet {
      max-height: calc(100dvh - 205px);
    }

    @include medium-tablet {
      max-height: calc(100dvh - 175px);
    }
  }

  @include wide-tablet {
    padding: 16px 22px 15px;
  }

  &__consider-idea {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    border-radius: 24px;
    background-color: rgba(#65aae0, 0.3);
    @include wide-tablet {
      gap: 14px;
      padding: 14px;
      border-radius: 12px;
    }
  }
  &__consider-idea-order {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    @include wide-tablet {
      gap: 14px;
    }

    &__img {
      border-radius: 8px;
      overflow: hidden;
      flex-shrink: 0;
      height: 78px;
      width: 78px;

      @include wide-tablet {
        border-radius: 5px;
        height: 42px;
        width: 42px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__text {
      font: normal normal 400 20px/26px PT Sans;

      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;

      @include wide-tablet {
        font-size: 11px;
        line-height: 14px;
      }
    }
  }

  .crowd-birthday-10-button {
    display: block;
    margin: 20px auto 0 auto;

    width: 190px;

    @include wide-tablet {
      width: 107px;
      margin-top: 12px;
    }

    @include phone {
      margin-top: 8px;
    }
  }
}
