.leisure-landing-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../../../../../assets/leisure-landing/sky-bg.png) center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    width: 408px;
    padding-top: 188px;
    background: url(../../../../../assets/leisure-landing/logo-illustration.png) center 0/367px 188px no-repeat;
  }

  &__bar {
    width: 100%;
    height: 30px;
    border: 6px solid #ffffff55;
    border-radius: 20px;
    position: relative;
    overflow: hidden;

    > span {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      &:first-child {
        background-color: #705e9a;
      }
      &:last-child {
        right: auto;
        background: url(../../../../../assets/leisure-landing/progress.svg) 0 center/396px no-repeat;
        transition: width 0.5s linear;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 17px;
          background-color: #2a164633;
          clip-path: path(
          "M0.686704 0.384614C0.240531 1.8416 0 3.39199 0 5C0 13.4967 6.715 20.0003 14.9993 20.0003H380.999C389.284 \
              20.0003 396 13.4967 396 5C396 3.39199 395.759 1.8416 395.313 0.384617C393.402 6.62632 387.716 10.7695 \
              380.999 10.7695H14.9993C8.28281 10.7695 2.5981 6.62631 0.686704 0.384614Z"
          );
        }
      }
    }
  }

  &__text {
    margin-top: 24px;
    color: var(--loden-purple);
    text-align: center;
    font: normal normal 700 30px / normal Montserrat;
  }
}
