.garland {
  position: relative;

  &--type {
    &-1 {
      img {
        height: 209px;
      }
    }

    &-2 {
      img {
        height: 290px;
      }
    }
  }

  &__wire {
    width: 100%;
  }
}
