@import "../../../vars";

.card-grid-page {
  width: 100%;
  height: 100%;

  .page-description {
    @include tablet() {
      font: normal normal normal 18px/24px var(--font-family-primary);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;

    @include phone() {
      margin-top: 24px;
      flex-direction: column;
    }

    .page-title {
      margin-top: 0;
    }

    .cr-search {
      max-width: 392px;

      @include tablet() {
        max-width: 348px;
      }

      @include phone() {
        max-width: unset;
        margin-top: 24px;
      }

      &__list {
        color: var(--card-grid-page-color);

        @include tablet() {
          width: 348px;
          right: 0;
        }

        &-item {
          color: var(--card-grid-page-color) !important;
          border-color: var(--card-grid-page-color) !important;
        }
      }

      &__mutiplePerRow {
        .cr-search__list-item {
          &-selected {
            background-color: var(--card-grid-page-color) !important;
            color: white !important;
          }
        }
      }
    }
  }

  &__tags {
    margin-top: 16px;

    &-list {
      display: flex;
      flex-wrap: wrap;

      &-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px 10px 0;
        padding: 2px 10px;
        border: 1px solid var(--card-grid-page-color);
        font: normal normal bold 14px/18px var(--font-family-primary);
        letter-spacing: 1.35px;
        text-transform: uppercase;
        color: var(--card-grid-page-color);
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: var(--round-corners-sm);

        > span {
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 65vw;
        }

        .promo-icon-14 {
          font-size: 14px;
          width: 14px;
          height: 14px;
          margin-left: 10px;
        }
      }
    }
  }

  &__list {
    margin-top: 26px;
    width: 100%;
    height: 100%;
  }
}
