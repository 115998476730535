@import "vars";
@import "./fonts";
@import "./assets/icons/promo-icons/style.css";
@import "~@csstools/normalize.css";

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
  overflow-y: scroll;
}

a {
  text-decoration: none;
  outline: none;

  &:link,
  &:visited {
    color: var(--base-blue);
  }
  &:hover,
  &:focus {
    color: #22bbf4;
    text-decoration: underline;
  }
  &.link {
    color: var(--base-blue);

    &.active {
      color: #999 !important;
    }

    &_bordered {
      display: inline-block;
      padding: 10px 20px;
      border: 1px solid var(--base-blue);
      font: 14px "Intro Bold";
      text-transform: uppercase;
      text-align: center;
    }
  }
}

.undecorated-link {
  color: currentColor !important;

  &,
  &:hover,
  &:active {
    text-decoration: none;
  }
}

video:focus {
  outline: 0;
}

main {
  flex: 1 0 auto;
}

.btn {
  width: 100%;
  padding: 8px 23px;
  border: 1px solid $blue;
  font: 14px var(--font-family-primary);
  text-transform: uppercase;
  text-align: center;
  transition: all 0.2s;
  color: $blue;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: rgba(0, 174, 239, 0.1);
  }

  &:disabled {
    color: #90969c;
    background-color: transparent;
    border: 1px solid #90969c;
  }

  &_red {
    color: $red;
    border-color: $red;

    &:hover {
      background-color: #ffe8e8;
    }
  }

  &_gray {
    border-color: #cccccc;
    color: #666666;
  }

  &_filled {
    background-color: $blue;
    color: #fff;

    &:hover {
      background-color: $light-blue;
    }

    &:disabled {
      border-color: transparent;
      color: #b2b2b2;
      background-color: #ebebeb !important;
    }
  }
}

input,
input[type="text"],
input[type="email"],
input[type="password"],
select,
textarea {
  color: $font-color;
  font-size: 16px;
  line-height: 20px;
  font-family: var(--font-family-primary);
  padding: 9px 14px;
  border: 1px solid $border-color;
  border-radius: var(--round-corners);
  box-sizing: border-box;
  outline: none;
  width: 100%;
  transition: all 0.2s;

  &:focus {
    border: 1px solid $blue;
    box-shadow: 0 0 0 1px $blue;
  }

  &::placeholder {
    color: #999;
  }

  &.error {
    border: 1px solid $error;
    box-shadow: 0 0 0 1px $error;
  }
}

label {
  position: relative;
}

.error__tooltip {
  position: absolute;
  top: 100%;
  left: 1px;
  z-index: 5;
  padding: 6px 14px 8px;
  font-size: 14px;
  line-height: 24px;
  background-color: $error;
  color: white;

  &:after {
    content: "";
    position: absolute;
    top: -18px;
    left: 22px;
    border: 9px solid transparent;
    border-bottom: 9px solid #ff6666;
  }
}

#close-modal-icon {
  outline: none;
}

.checkbox-label {
  display: inline-block;
  position: relative;
  line-height: 20px;
  cursor: pointer;
  text-align: left;
  font-weight: normal;
  margin: 0;
  outline: none;

  &__input {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;

    + .checkbox-label__text:before {
      content: "";
      width: 18px;
      height: 18px;
      background-color: #ffffff;
      border: 1px solid $border-color;
      position: absolute;
      left: 0;
      top: 1px;
      display: inline-block;
      cursor: pointer;
      background-position: 3px 4px;
      background-repeat: no-repeat;
      box-sizing: border-box;
      z-index: 5;
    }

    &:checked + .checkbox-label__text:before {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAMAAAD3JJ6EAAAAKlBMVEUAru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru8Aru+eFSKnAAAADXRSTlMAAgMGS2Kcnp+vsLG2CF5vNAAAADFJREFUeAFjgAJGNlYoi4mdlw3OYmdCZrFwMsPEuHh5OHjZGUGSzDy8YBaYzc0GYQEAKy4BHJzULZ8AAAAASUVORK5CYII=);
    }

    &:indeterminate + .checkbox-label__text:before {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAACCAMAAABWv73iAAAAA1BMVEUAru+nS1NUAAAACklEQVR4AWPACgAAFgABjmr6vAAAAABJRU5ErkJggg==);
      background-position: center;
    }

    &:focus + .checkbox-label__text:before {
      border: 1px solid $blue;
      box-shadow: 0 0 0 1px $blue;
    }
  }

  &__text {
    display: block;
    padding-left: 30px;
    position: relative;

    &:hover:before {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAMAAAD3JJ6EAAAAMFBMVEX///+zs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7MWh8NdAAAAD3RSTlMAAgMGB0pinJ2en6+wsbbLiOpMAAAALklEQVR4AWOAAkZ2NiiLiZOfHc7iZEJmsXIzw8R4+Pm4oLIsfPxgFpjNywFhAQAzYQFVsq6QFwAAAABJRU5ErkJggg==);
    }
  }
}

.flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.title {
  &__main {
    margin-bottom: 30px;
    font: bold 48px "Intro Black";
    text-transform: uppercase;
    text-align: center;
    color: #454545;
  }

  &__small {
    font: $font;
    font-weight: bold;
  }
}

.break-word {
  word-break: break-word;
}

.content {
  margin-top: 70px;
  margin-bottom: 70px;
}

.centralize-block {
  text-align: center;
}

.boldBlue {
  font-weight: bold;
  color: var(--base-blue);
}

.cr-hint {
  font: 14px/20px PT Sans;
  letter-spacing: 0.25px;
  color: #808080;
}

.active-link {
  color: var(--base-blue) !important;
}

.cr-red {
  color: #e9221d;
}

.cr-error {
  color: var(--base-red);
}

.small-arrow {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 16px;

  @include phone() {
    width: 13px;
  }

  &::after {
    content: "";
    width: 100%;
    height: 2px;
  }

  &::before {
    content: "";
    position: absolute;
    right: 0;
    width: 12px;
    height: 12px;
    border-top: 3px solid transparent;
    border-right: 3px solid transparent;
    transform: rotate(45deg);

    @include phone() {
      width: 10px;
      height: 10px;
      border-width: 2px;
    }
  }

  &_white {
    &::after {
      background: white;
    }
    &::before {
      border-color: white;
    }
  }

  &_black {
    &::after {
      background: $black-text;
    }
    &::before {
      border-color: $black-text;
    }
  }

  &_blue {
    &::after {
      background: $black-text;
    }
    &::before {
      border-color: $black-text;
    }
  }

  .small-arrow_blue::after {
    background: #65aae1;
  }

  .small-arrow_blue::before {
    border-color: #65aae1;
  }
}

@media screen and (max-width: 768px) {
  .title {
    &__main {
      font-size: 36px;
      line-height: 1;
    }
  }
}

@media screen and (max-width: 500px) {
  .title {
    &__main {
      font-size: 24px;
      line-height: 1.2;
    }
  }

  iframe[title="recaptcha challenge"],
  iframe[title="проверка recaptcha"] {
    position: fixed;
    top: 0;
    left: 0;
  }
}

b,
strong {
  font-weight: bold;
}

i,
em {
  font-style: italic;
}

.fill-current {
  fill: currentColor;
}
.stroke-current {
  stroke: currentColor;
}

.user-name-hidden-icon {
  margin-left: 12px;
  @include tablet() {
    margin-left: 10px;
  }
  @include phone() {
    margin-left: 8px;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.pointer-none {
  pointer-events: none !important;
}

.pointer-all {
  pointer-events: all !important;
}

.pointer {
  cursor: pointer;
}
.box-shadow-padding {
  padding: 16px !important;
  margin: -16px !important;
}

.item-colorized {
  &:nth-child(9n + 1) {
    background: hsla(0, 100%, 50%, 0.8);
  }

  &:nth-child(9n + 2) {
    background: hsla(40, 100%, 50%, 0.8);
  }

  &:nth-child(9n + 3) {
    background: hsla(80, 100%, 50%, 0.8);
  }

  &:nth-child(9n + 4) {
    background: hsla(120, 100%, 50%, 0.8);
  }

  &:nth-child(9n + 5) {
    background: hsla(160, 100%, 50%, 0.8);
  }

  &:nth-child(9n + 6) {
    background: hsla(200, 100%, 50%, 0.8);
  }

  &:nth-child(9n + 7) {
    background: hsla(240, 100%, 50%, 0.8);
  }

  &:nth-child(9n + 8) {
    background: hsla(280, 100%, 50%, 0.8);
  }

  &:nth-child(9n + 0) {
    background: hsla(320, 100%, 50%, 0.8);
  }
}
