.leisure-landing-train {
  transform: translateX(5000px);
  transition: all var(--transition-duration-train) ease-in;
  &_arrived {
    transform: translateX(0px);
    transition-timing-function: ease-out;
  }
  &_left {
    transform: translateX(-5000px);
  }
}
