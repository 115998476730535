.flippable-card {
  perspective: 1000px;

  &:hover .flippable-card__content {
    transform: rotateY(180deg);
  }

  &__content {
    height: 100%;
    position: relative;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  &__face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }

  &__front {
    background: lightblue;
  }

  &__back {
    background: lightcoral;
    transform: rotateY(180deg);
  }
}
