@import "../../../../../vars";

.discussion-landing-discussion {
  &__swiper {
    margin-top: 24px;
  }

  &__date {
    font: normal normal 500 20px/24px Montserrat;
    letter-spacing: 0px;
    margin-top: 16px;
    color: #8a8a8a;

    @include wide-tablet() {
      font: normal normal 500 16px/19px Montserrat;
      letter-spacing: 0px;
      margin-top: 8px;
    }
  }

  &__text {
    font: normal normal 500 24px/36px Montserrat;
    letter-spacing: 0px;
    color: #282828;
    margin-top: 24px;

    @include wide-tablet() {
      margin-top: 16px;
      font: normal normal 500 20px/24px Montserrat;
    }

    @include phone {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__write-btn {
    padding: 17px 35px;
    margin-top: 32px;
    border-radius: 20px;
    font: normal normal bold 20px/24px Montserrat;
    letter-spacing: 0px;

    @include wide-tablet() {
      padding: 15px 31px;
    }

    @include phone() {
      margin-top: 24px;
      padding: 14px 15px;
      font: normal normal bold 14px/18px Montserrat;
    }
  }

  &__subtitle {
    margin-top: 24px;
  }

  .common-slider--no-controls {
    .common-slider__footer {
      display: none;
    }
  }

  &__comments {
    .all-comments {
      margin-top: 24px;
      @include wide-tablet() {
        margin-top: 20px;
      }

      @include phone() {
        margin-top: 16px;
      }
    }
  }

  &__top,
  &__comments {
    .comment-card__body {
      margin-left: 80px;
      @include phone() {
        margin-left: 0px;
      }
    }
    .comment-card__user-info {
      @include phone() {
        margin-left: 12px;
      }
    }
    .comment-card__footer {
      margin-left: 80px;
      @include phone() {
        margin-left: 0px;
      }
    }
  }
}
