@import "../../../../../vars";

.birthday-gallery {
  margin-top: 120px;
  @include phone {
    margin-top: 100px;
  }

  &__inner {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  &__subtitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: var(--birthday9-black);
    margin: 10px 0 40px;
    a {
      font-weight: 700;
      color: var(--birthday9-black);
    }
  }

  .cr-button {
    padding: 21px 40px 22px;
    height: 60px;
    border-radius: 18px;
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin: 0 auto 60px;
    @include wide-tablet {
      margin-bottom: 40px;
    }
  }

  .swiper-container {
    width: 100vw;
    padding: 55px 0;
    .swiper-wrapper {
      transition-timing-function: linear;
      .swiper-slide {
        width: auto;
        img {
          width: 345px;
          height: 345px;
          object-fit: cover;
          animation: swaying 20s ease-in-out 0s infinite;
          @include phone {
            width: 210px;
            height: 210px;
          }
        }
      }
    }
  }
}

@include keyframe(swaying) {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
