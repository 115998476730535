@font-face {
    font-family: 'PT Sans';
	src: url('PTS55F_W.eot');
	src: local('PT Sans'),
        url('PTS55F_W.woff') format('woff'),
        url('PTS55F_W.ttf') format('truetype'),
        url('PTS55F_W.svg#PTSans-Regular') format('svg');
}

@font-face {
    font-family: 'PT Sans';
	font-style: italic;
	src: url('PTS56F_W.eot');
	src: local('PT Sans Italic'),
        url('PTS56F_W.woff') format('woff'),
        url('PTS56F_W.ttf') format('truetype'),
        url('PTS56F_W.svg#PTSans-Italic') format('svg');
}

@font-face {
    font-family: 'PT Sans';
	src: url('PTS75F_W.eot');
	font-style: normal;
	font-weight: bold; 
	src: local('PT Sans Bold'),
        url('PTS75F_W.woff') format('woff'),
        url('PTS75F_W.ttf') format('truetype'),
        url('PTS75F_W.svg#PTSans-Bold') format('svg');
}

@font-face {
    font-family: 'PT Sans';
	font-style: italic;
	font-weight: bold; 
	src: url('PTS76F_W.eot');
	src: local('PT Sans Bold Italic'),
        url('PTS76F_W.woff') format('woff'),
        url('PTS76F_W.ttf') format('truetype'),
        url('PTS76F_W.svg#PTSans-BoldItalic') format('svg');
}

@font-face {
    font-family: 'PT Sans Caption';
	src: url('PTC55F_W.eot');
	src: local('PT Sans Caption'),
        url('PTC55F_W.woff') format('woff'),
        url('PTC55F_W.ttf') format('truetype'),
        url('PTC55F_W.svg#PTSans-Caption') format('svg');
}

@font-face {
    font-family: 'PT Sans Caption';
	font-weight: bold; 
	src: url('PTC75F_W.eot');
	src: local('PT Sans Caption Bold'),
        url('PTC75F_W.woff') format('woff'),
        url('PTC75F_W.ttf') format('truetype'),
        url('PTC75F_W.svg#PTSans-CaptionBold') format('svg');
}

@font-face {
    font-family: 'PT Sans Narrow';
	src: url('PTN57F_W.eot');
	src: local('PT Sans Narrow'),
        url('PTN57F_W.woff') format('woff'),
        url('PTN57F_W.ttf') format('truetype'),
        url('PTN57F_W.svg#PTSans-Narrow') format('svg');
}

@font-face {
    font-family: 'PT Sans Narrow';
	font-weight: bold; 
	src: url('PTN77F_W.eot');
	src: local('PT Sans Narrow Bold'),
        url('PTN77F_W.woff') format('woff'),
        url('PTN77F_W.ttf') format('truetype'),
        url('PTN77F_W.svg#PTSans-NarrowBold') format('svg');
}