@import "../../../../../vars";
.ny-modal-fact {
  width: 460px;

  @include medium-tablet {
    width: 100%;
  }

  &__logo {
    display: block;
    margin: 0 auto 20px;
  }

  & a {
    font-weight: inherit;
  }
}
