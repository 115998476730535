@import "../../../../vars";
@import "../../../pages/WorkResult/WorkResult.helper.scss";

.wrv-card {
  padding: 24px;
  &__head {
    font: normal normal normal 14px/24px var(--font-family-primary);
    letter-spacing: 0px;
    display: flex;
    flex-wrap: wrap;
    min-height: 24px;

    &-org {
      margin-right: 24px;
    }

    &-status {
      display: flex;
      align-items: center;
      margin-right: 24px;

      span {
        margin-right: 8px;
      }

      @include tablet() {
        margin: 0;
      }
    }
  }

  &__body {
    display: flex;
    align-items: stretch;
    margin-top: 26px;

    @include tablet() {
      margin-top: 19px;
    }

    &-user {
      border: 1px solid #dedee2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 50px;
      margin-right: 24px;
      max-width: 184px;
      max-height: 227px;
      height: 100%;
      overflow: hidden;
      border-radius: var(--round-corners);

      @include tablet() {
        margin-right: 16px;
        max-height: 195px;
      }

      img {
        width: 80px;
        border-radius: 100%;
      }

      span {
        text-align: center;
        font: normal normal bold 14px/19px var(--font-family-primary);
        letter-spacing: 0.13px;
        color: #282828;
        margin-top: 12px;

        @include tablet() {
          font: normal normal bold 12px/15px var(--font-family-primary);
          letter-spacing: 0px;
        }
      }
    }

    &-project {
      border: 1px solid #dedee2;
      font: normal normal bold 24px/32px var(--font-family-primary);
      letter-spacing: 0px;
      color: #282828;
      padding: 13px 32px;
      border-radius: var(--round-corners);

      @include tablet() {
        font: normal normal bold 14px/20px var(--font-family-primary);
        letter-spacing: 0.25px;
        padding: 10px 12px;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-list {
      border: 1px solid #dedee2;
      padding: 16px 32px;
      margin-top: 16px;
      height: 100%;
      font: normal normal normal 16px/20px var(--font-family-primary);
      letter-spacing: 0.13px;
      max-height: 150px;
      overflow: auto;
      border-radius: var(--round-corners);

      ol {
        @extend wr-ol;
      }

      ul {
        @extend wr-ul;
      }

      @include scrollbar(5px);

      @include tablet() {
        font: normal normal normal 14px/19px var(--font-family-primary);
        letter-spacing: 0px;
        margin-top: 12px;
        max-height: 142px;
        padding: 12px;
      }
    }
  }

  &__footer {
    width: 100%;
    margin-top: 24px;

    .share-icon {
      margin-left: auto;
      &:not(:first-child) {
        margin-top: 24px;
        @include tablet {
          margin-top: 16px;
        }
      }
    }
  }

  &__photos {
    display: flex;
    justify-content: flex-start;

    img {
      width: 24%;
      height: 242px;
      max-width: 242px;
      object-fit: cover;
      margin-right: 1.7%;
      border-radius: var(--round-corners);

      &:last-child {
        margin-right: 0;
      }

      @include tablet() {
        width: 33%;
        height: 172px;
      }
      @include phone() {
        width: 100%;
      }
    }
  }
}

.wrm-card {
  &__photos {
    position: relative;
    margin-top: 16px;

    img {
      height: 280px;
      object-fit: cover;
      width: 100%;
      border-radius: var(--round-corners);
    }
  }

  &__slider {
    .slider-arrows {
      display: none;
    }
  }

  &__title {
    font: normal normal bold 24px/32px var(--font-family-primary);
    letter-spacing: 0px;
    color: #282828;
    padding: 0 20px;
  }

  &__org {
    font: normal normal normal 14px/19px var(--font-family-primary);
    letter-spacing: 0px;
    margin-top: 20px;
    padding: 0 20px;
  }

  &__quote {
    padding: 0 20px;
    &::before {
      content: "\e978";
      font-family: "promo-icons";
      color: #dedede;
      float: left;
      margin-right: 10px;
    }

    &-text {
      font: normal normal normal 16px/20px var(--font-family-primary);
      letter-spacing: 0px;
      margin-top: 16px;
      padding-left: 28px;

      ol {
        @extend wr-ol;
      }

      ul {
        @extend wr-ul;
      }
    }

    &-pic {
      width: 60px;
      height: 60px;
      border-radius: 100%;
      margin: 16px 0 0 28px;
    }

    &-user {
      font: normal normal normal 14px/23px var(--font-family-primary);
      letter-spacing: 0px;
      color: #8a8a8a;
      margin-top: 8px;
      padding-left: 28px;
    }
  }

  &__status {
    font: normal normal normal 14px/19px var(--font-family-primary);
    letter-spacing: 0px;
    display: flex;
    align-items: center;
    display: flex;
    margin-top: 16px;
    padding: 0 20px;

    span {
      margin-right: 8px;
    }
  }

  .share-icon {
    margin-top: 20px;
    margin-left: auto;
    margin-right: 20px;
  }
}
