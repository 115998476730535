@import "../../../../../vars";

.stats {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: rgba(64, 64, 64, 1);

  &__count {
    font: 700 27px/35px 'PT Sans', sans-serif;
    text-align: left;

    @include medium-tablet() {
      font-size: 18px;
      line-height: 18px;
    }
  }

  &__name {
    position: relative;
    font: 400 12px/13px 'Pt Sans', sans-serif;

    @include medium-tablet() {
      font-size: 8px;
      line-height: 8px;
    }
  }
}