@import "../DiscussionLanding/mixins.scss";
@import "../../../vars";

.special-projects {
  width: 100%;
  &__main {
    position: relative;
    aspect-ratio: 1224 / 528;
    padding: 48px 104px;
    background-repeat: no-repeat;
    display: flex;
    align-items: end;
    @include tablet() {
      margin: 0 -24px;
      padding: 32px 74px;
      aspect-ratio: 768 / 576;
    }
    @include phone() {
      padding: 16px 20px;
      aspect-ratio: 320 / 138;
    }
    &__image {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 0;
      transition: opacity 0.2s;

      &--active {
        opacity: 1;
      }
    }
    &__backdrop {
      background-color: rgba($color: #000000, $alpha: 0.3);
      position: absolute;
      inset: 0;
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 50vw;
      top: 0;
      bottom: 0;
      background-color: #f6f3f1;
      @include tablet {
        display: none;
      }
    }
    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }
    &__title {
      color: #ffffff;
      margin: 0;
      font: normal normal bold 80px/80px var(--font-family-primary);
      isolation: isolate;
      @include tablet {
        font: normal normal bold 64px/77px var(--font-family-primary);
      }
      @include phone {
        font: normal normal bold 32px/38px var(--font-family-primary);
      }
    }
  }
  &__slider {
    @include tablet() {
      max-width: 614px;
      margin: 0 auto;
    }
    @include phone() {
      display: none;
    }
    .swiper-slide {
      height: auto;
      & > div {
        height: 100%;
      }
    }
    .common-slider__footer {
      min-height: unset;
      position: unset;
      .slider-arrows {
        width: calc(100% + 120px);
        max-width: calc(100vw - 16px);
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        display: flex;
        justify-content: space-between;
        pointer-events: none;
        &-left,
        &-right {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #ffffff;
          box-shadow: 0px 2px 10px #0000001a;
          pointer-events: all;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &--disabled {
          pointer-events: none;
        }
      }
    }
  }
  &__list-of-cards {
    display: flex;
    flex-direction: column;
    margin-left: -4px;
    margin-top: 8px;
    padding-right: 20px;
  }
  &__arrows-container {
    padding-bottom: 0;
  }
}
