.basketball {
  $root: &;

  &__game-title {
    color: var(--loden-purple);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 10px;
  }
  &__game-subtitle {
    color: var(--loden-purple);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 10px;
  }

  &__game {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 440px;
    height: 650px;
  }

  &__win {
    width: 420px;
    height: 272px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 30px 0;
  }

  &__lose {
    width: 420px;
    height: 304px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 30px 0;

    .leisure-landing-button {
      margin-top: 30px;
    }
  }

  canvas {
    margin: 0 auto;
    border-radius: 16px;
  }

  // Overrides

  .leisure-landing-modal {
    padding: 0;
    margin-top: 45px;
    border-radius: 24px;

    &__close-btn {
      top: 20px;
      right: 20px;
    }
  }
}
