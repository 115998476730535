@import "../../../../vars";

.logo {
  display: block;
  flex-shrink: 0;
  height: 57px;
  width: 45px;
  background: url("../../../../assets/Logo-b.svg") 0 0 / contain no-repeat;
  background-position: center;
  z-index: 100;

  &_hidden {
    visibility: hidden;
  }
}

@include wide-tablet {
  .logo {
    height: 42px;
    width: 33px;
  }
}

@include phone {
  .logo {
    height: 45px;
    width: 35px;
  }
}
