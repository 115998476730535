@import "../../../../../vars";

.new-year-2023-tooltip {
  .__react_component_tooltip,
  &__outer.__react_component_tooltip {
    background-color: var(--nato-blue);
    color: var(--enokitake-mushroom);
    font: normal normal 500 14px / normal Montserrat;
    @include medium-tablet() {
      font-size: 10px;
    }
    padding: 16px 20px;
    border-radius: 16px;
    opacity: 0.9;
    max-width: 227px;
    width: max-content;
    z-index: 8;
    &:before,
    &:after {
      display: none;
    }
  }

  &#{&}__border .__react_component_tooltip,
  &__outer#{&}__border.__react_component_tooltip {
    border-radius: 0 16px 16px 16px;
  }
}
