.dls-card {
  box-shadow: 0px 2px 10px #0000001a;
  border-radius: 20px;
}

.dls-cards {
  margin-top: 24px;
  .swiper-slide {
    height: auto;
    > div,
    .dls-card {
      height: 100%;
    }
  }
}
