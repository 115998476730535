@import "../../../../../../vars";

.crowd-birthday-10-modal {
  &.expert-game-win-modal {
    padding-bottom: 32px;
    @include wide-tablet {
      padding-bottom: 16px;
    }
  }
}
