@mixin sliderArrowsSide {
  .slider-arrows {
    position: absolute;
    padding: 0 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: flex;
    justify-content: space-between;
    width: calc(100% + 112px);
    max-width: calc(100vw - 16px);
    pointer-events: none;

    &-left,
    &-right {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 2px 10px #0000001a;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: all;
    }
    &--disabled {
      pointer-events: none;
    }
  }
}
