@import "../../../../../../vars";
.game {
  padding: 0 42px;
  &__grid {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, 71px);
    grid-template-rows: repeat(4, 71px);
    justify-content: center;
    padding: 12px;
    background-color: #d9ebf9;
    border-radius: 15px;
    @include medium-tablet() {
      grid-template-columns: repeat(4, 60px);
      grid-template-rows: repeat(4, 60px);
      gap: 7px;
    }
  }

  &__timer {
    @include medium-tablet() {
      position: absolute;
      left: 20px;
      top: 0;
    }
  }

  &__card {
    border-radius: 15px;
    border: none;
    outline: none;
    overflow: hidden;
    perspective: 500px;
    background-color: transparent;
    &--active {
      .game__inner {
        transform: rotateY(180deg);
      }
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
    transform-style: preserve-3d;
  }

  &__front,
  &__back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  &__front {
    background: #8bc5f7 url("../../../../../../assets/newyear2023/games/memory/Snowflake.svg") center no-repeat;
  }

  &__back {
    transform: rotateY(180deg);
    background-color: #fff;
  }

  &__img {
    width: 100%;
    height: 100%;
    display: block;
  }
}
