.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 10px 10px;
  box-sizing: border-box;
  color: #333;

  .btn {
    padding: 15px 20px;
    border-radius: var(--round-corners);
  }

  .auth-form {
    display: flex;
    flex-direction: column;

    &__label {
      margin-bottom: 25px;

      &__heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 7px;

        .remind-password {
          cursor: pointer;
        }
      }
    }

    &__enter-block {
      margin-bottom: 25px;
      align-items: center;

      .submit-button button {
        min-width: 150px;
      }

      .remind-label {
        .checkbox-label__text {
          font-size: 16px;
        }
      }
    }
  }

  .delimiter {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ccc;
    height: 1px;
    margin: 30px 0 25px 0;

    &:after {
      content: "или";
      color: #7e858c;
      background: #fff;
      padding: 0 20px;
      margin-top: -2px;
    }
  }

  .mos-ru {
    &__auth {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;
    }

    &__icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RENENTM3Rjk1NDYyMTFFOUFBMzJGMUNBQkRDQUFCREUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RENENTM3RkE1NDYyMTFFOUFBMzJGMUNBQkRDQUFCREUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEQ0Q1MzdGNzU0NjIxMUU5QUEzMkYxQ0FCRENBQUJERSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEQ0Q1MzdGODU0NjIxMUU5QUEzMkYxQ0FCRENBQUJERSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqASqC4AAAB1SURBVHjaYvz//z/DW3kuBiD4z0AZYAQRTAxUBiy4bCIBoPiM6i4cNXDUwFEDB9zA50iZHRs+CsRfsIiDwAtsBqYgS2ABVkDMjUX8KRAnw4sqpAIWX/F0E4grgHgfEH8itTxEByDDLIH4PbUipZJYw0AAIMAAaxgiEfFml/AAAAAASUVORK5CYII=");
    }
  }
}

.mosru-auth-form {
  max-width: 410px !important;
  .modal__close-btn {
    top: 14px;
    right: 14px;
  }
}

@media only screen and (max-width: 500px) {
  .form-container {
    width: 100%;

    .auth-form {
      &__enter-block {
        flex-direction: column-reverse;
        align-items: unset;

        .remind-label {
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }

  .mosru-auth-form {
    max-width: none !important;
    padding-top: 0 !important;
    .modal__close-btn {
      top: 18px;
      right: 20px;
    }
  }
}
